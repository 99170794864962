<template>
  <div class="md-layout text-left">
    <div
      class="md-layout-item md-size-100 md-medium-size-100 md-small-size-100 md-xsmall-size-100"
    >
      <div style="text-align: center">
        <img
          :src="require('@/assets/img/logo.png')"
          alt="Logo"
          class="logo"
          width="160px"
          style=""
        />
      </div>
      <md-card>
        <div
          class="w-full sm:max-w-2xl mt-6 p-6 bg-white shadow-md overflow-hidden sm:rounded-lg prose"
          style="padding: 24px"
        >
          <h1>Allgemeine Geschäftsbedingungen des «Academy Space»</h1>
          <h2>Allgemeines</h2>
          <p>
            Academy Space (kurz «AS») ist eine Online Plattform, welche
            Nutzer<em
              >innen diverse Dienstleistungen und Angebote für den Aufbau,
              Erhalt und die Förderung der physischen und psychischen Gesundheit
              und Fitness bietet. Der AS ermöglicht den Nutzer</em
            >innen unabhängig von ihrem Standort auf diesen Content zuzugreifen.
            Die P2B – Tobias Steiner EIU (Einzelunternehmung), UID:
            CHE-336-807.881, nachfolgend P2B genannt, ist die Betreiberin und
            Anbieterin dieser Plattform.
          </p>
          <p>
            Diese allgemeinen Geschäftsbedingungen, nachfolgend AGB genannt,
            gelten für die Nutzung des AS und bilden einen Vertragsbestandteil.
            Durch anklicken der Schaltfläche «Ich akzeptiere» bestätigen die
            Nutzer*innen, dass sie die AGB gelesen und verstanden haben und dass
            sie für die Einhaltung jeder einzelnen darin enthaltenen
            Geschäftsbedingung rechtlich verantwortlich sind. Für einzelne oder
            zusätzliche Funktionen und Leistungen können ergänzende oder weitere
            Bedingungen oder sonstige rechtliche Dokumente bestehen und gelten.
          </p>
          <h2>Konto</h2>
          <p>
            Der Zugang zum AS wird mittels eines individuellen Kontos gewährt.
            Bei der Einrichtung und Führung des Kontos sind Nutzer<em
              >innen verpflichtet, korrekte und vollständige Daten,
              einschliesslich einer gültigen E-Mail, anzugeben und diese Daten
              auf dem aktuellsten Stand zu halten. Die Nutzung des AS setzt
              grundsätzlich die Handlungsfähigkeit voraus. Ausnahmen sind
              möglich. Nutzer</em
            >innen sollten ihr Passwort an einem sicher Ort aufbewahren, weil
            sie für alle Aktivitäten in Verbindung mit ihrem Konto
            verantwortlich sind. Nutzer<em
              >innen dürfen Ihr Konto nicht ohne Zustimmung von P2B an Dritte
              übertragen oder ein Drittkonto nutzen. Wenn Nutzer</em
            >innen den Verdacht haben, dass Dritte ihr Konto nutzen, müssen sie
            umgehend P2B informieren. Für Schäden aus nicht rechtmässiger und
            zweckfremder Nutzung des Nutzer<em>innenkontos, sind Nutzer</em
            >innen alleine verantwortlich.
          </p>
          <h2>Kostenlose Testversion und Verlängerungen</h2>
          <p>
            P2B kann Nutzer*innen eine kostenlose Testversion zur Verfügung
            stellen. Die Dauer dieser kostenlosen Testversion ist nicht
            vordefiniert. P2B behält sich vor, jederzeit diese kostenlose
            Testversion zu beenden und zu kündigen.
          </p>
          <h2>Nutzung und Funktion von AS</h2>
          <p>
            P2B stellt jeweils die aktuellste Version des AS zur Verfügung und
            ermöglicht den Nutzer<em
              >innen Zugang zu Content. Content kann sein, Podcasts, Lernkurse,
              Videos, Webinare, (keine abschliessende Aufzählung). Weiter
              betreibt P2B die Infrastruktur, speichert nur Daten im
              Zusammenhang mit der Nutzung des AS und leistet den Nutzer</em
            >innen angemessene Unterstützung bei der Bedienung des AS. P2B ist
            berechtig, alle oder einzelne Leistungen in Zusammenhang mit der
            Plattform durch qualifizierte Dritte ausführen zu lassen. P2B ist
            berechtigt, Teil- oder Totalrevisionen des AS zu machen und
            weiterzuentwickeln. P2B muss bei grösseren Änderungen die Nutzer<em
              >innen orientieren. P2B ist berechtigt den Nutzer</em
            >innen Informationen über den AS zu übermitteln. Dabei darf P2B
            diverse Kommunikationskanäle nutzen. Die Nutzung des AS erfolgt auf
            eigene Gefahr der Nutzer<em
              >innen. P2B behandelt sämtliche Daten über Nutzer</em
            >innen vertraulich und unterlässt die Weitergabe an unberechtigte
            Dritte. Diese Pflicht bleibt auch über die Vertragsdauer hinaus
            bestehen. P2B hat in diesem Zusammenhang für angemessene Massnahmen
            gegen Datenverluste und für die Sicherheit der Daten zu sorgen. Auf
            Anfrage der Nutzer<em
              >innen muss P2B ihre Daten nach Vertragsende unwiderruflich
              löschen. Nutzer</em
            >innen unterlassen zwingend jegliche Art von Manipulation des AS,
            unterdrücken von sicherheitsrelevanten Funktionen, kopieren und
            ändern von Quellcodes sowie Netzwerkstörungen.
          </p>
          <h2>Urheberrechte</h2>
          <p>
            Sämtliche jetzige und zukünftige Rechte, Anwendungen, Logos,
            Content, Dienste des AS, sind und bleiben das alleinige Eigentum von
            P2B. P2B ermöglicht den Nutzer<em
              >innen eine eingeschränkte und nicht übertragbare Lizenz zum
              Aufrufen und Anzeigen des Contents, für den sie alle
              erforderlichen Gebühren entrichtet haben. Jede andere Nutzung ist
              ausdrücklich untersagt. Es ist den Nutzer</em
            >innen verboten sich den Content zu übertragen, übermitteln,
            abtreten, vermieten, ändern, bearbeiten, weiterzuverkaufen, (keine
            abschliessende Aufzählung) und berechtigt P2B zur ausserordentlichen
            und begründungslosen Kündigung. Weiter rechtliche Schritte sind
            vorbehalten. Unter keinen Umständen haben Nutzer*innen das Recht,
            unberechtigt den Namen von P2B bzw. vom AS oder Marken, Logos,
            Domänen etc. zu verwenden.
          </p>
          <h2>Datenschutz</h2>
          <p>
            Nutzer<em
              >innen erklären sich damit einverstanden, dass ihre
              personenbezogenen Datenerfasst und gespeichert werden. In keinem
              Fall übermittelt P2B diese Daten an unberechtigte Dritte. P2B
              versichert Nutzer</em
            >innen ihre Daten ausschliesslich für die Zwecke des AS zu erfassen
            und einzusetzen. Nutzer*innen haben das Recht auf Auskunft über ihre
            Daten. Solche Anfragen sind direkt an die Kontaktadresse
            <a href="mailto:info@perfom2balance.ch">info@perfom2balance.ch</a>
            zu richten. Es ist mit einer angemessenen Bearbeitungsfrist zu
            rechnen.
          </p>
          <h2>Kosten und Zahlungsbedingungen</h2>
          <p>
            Die Kosten richten sich nach den vertraglichen Vereinbarungen
            zwischen P2B und den einzelnen Nutzer<em
              >innen. P2B kann die Kosten über weitere Kanäle (Social-Media,
              Webseite, etc.) veröffentlichen. Nutzer</em
            >innen erklären sich zur rechtmässigen, fristgerechten,
            vollumfängliche und gültigen Zahlung des Zugriffs bzw. Contents
            bereit. Weiter berechtigten Nutzer<em
              >innen P2B sie mit den entsprechenden Gebühren zu belasten oder
              eine Zahlungsart (stripe.com) für die Zahlungsabwicklung zu
              nutzen. P2B ist nicht verpflichtet, geleistet Zahlungen
              zurückzuerstatten. Sämtliche Kosten sind per sofort geschuldet,
              sofern P2B keine andere Zahlungsfrist nennt. Weiter kann P2B für
              die Nutzung des AS Voraus- oder Akontozahlungen verlangen. Sollten
              Nutzer</em
            >innen keine firstgereiche Bezahlung leisten, fallen sie automatisch
            und ohne Zahlungserinnerung in Verzug. Bei solchen Versäumnissen ist
            P2B berechtigt, den Zugang zum AS zu sperren.
          </p>
          <h2>Preisänderungen und Preisgarantie</h2>
          <p>
            Der Preis des gelösten Abos bleibt grundsätzlich unabhängig von
            Preisveränderungen erhalten (Treuebonus).
          </p>
          <p>
            P2B entwickelt und optimiert das Angebot des AS laufend aus. So kann
            es bei signifikantem Infrastrukturausbau oder bei der Einführung von
            zusätzlichen Features zu Preiskorrektur kommen. Dies wird den
            Nutzer*innen frühzeitig mitgeteilt.
          </p>
          <h2>Gewährleistung und Haftung</h2>
          <p>
            P2B erbringt ihre Leistungen fachgerecht und sorgfältig. Weiter
            bemüht sich P2B, dass der vertraglich vereinbarte Funktions- und
            Leistungsumfang des AS während der jeweiligen Vertragslaufzeit
            genutzt werden kann. Eine bestimmte Mindestmenge an Content ist
            nicht garantiert. Eine fehlerfreie und dauerhafte Nutzung des AS
            wird von P2B nicht gewährleistet. übernimmt P2B keine Gewährleistung
            für Content von Dritten. Es kann vorkommen, dass der AS aufgrund
            Störungen ausser Betrieb ist. Ebenso Nutzer<em
              >innen sollen mögliche Mängel umgehend P2B melden. P2B bemüht sich
              solche Mängel innert angemessener Frist und mit rechtmässigen
              Massnahmen zu beheben. P2B ist berechtigt, den Zugang zum AS für
              Wartungsarbeiten zu unterbrechen. P2B hat Nutzer</em
            >innen über solche Wartungsabreiten zu orientieren und diese
            geplanten Wartungsfenster möglichst an Randzeiten zu vollziehen.
            Jegliche Haftung von P2B oder ihrer Hilfspersonen wird, soweit
            gesetzlich zugelassen, ausdrücklich und vollumfänglich weggebunden.
          </p>
          <h2>Vertragsdauer, Kündigungen</h2>
          <p>
            Vertragsbeginn richtet sich nach den entsprechenden vertraglichen
            Vereinbarungen zwischen P2B und den Nutzer<em
              >innen. Für die Kündigung ist das persönliche Datum beim Abostart
              der Nutzer</em
            >innen relevant und diese muss schriftlich und 10 Tage bevor sich
            das Abo automatisch um einen Monat verlängert, erfolgen. Mit dem
            Vertragsende wird den Nutzer<em
              >innen der Zugang zum AS gesperrt. Es besteht kein Anspruch auf
              Rückerstattung von bezahlten Beträgen. Rücktritt während des Abos
              und eine entsprechende Preisreduktion/erlass können nur in
              Härtefällen wie länger andauernder Krankheit oder Unfall
              berücksichtigt werden. Diese erfolgen «pro rata temporis»
              abzüglich CHF 20.00 für den administrativ Aufwand. Das Abo muss
              zusammen mit einem schriftlichen Antrag und notwendgien
              Bestätigungen wie Arzt</em
            >innenzeugnis, Arbeitgeber<em
              >innenbestätigung, etc. eingereicht werden. Nutzer</em
            >innen dürfen ihre Bedingung nicht abtreten oder übertragen und
            erklären sich damit einverstanden, dass ihr Konto nicht übertragbar
            ist und dass alle Rechte an ihrem Konto und sonstige Rechte gemäss
            diesen Bedingungen nach ihrem Tod erlöschen.
          </p>
          <h2>Zuwiderhandlungen</h2>
          <p>
            Nutzer<em
              >innen sind jegliche rechtswidrige und zweckfremde Handlungen
              mittels AS untersagt. Für daraus resultierende Schäden stehen
              Nutzer</em
            >innen explizit alleine ein. P2B trägt keine Verantwortung für
            solche Haftungsfälle und ist vollumfänglich befreit. Grobe,
            missbräuchliche oder wiederholte Verstösse gegen die AGB können zur
            fristlosen Kündigung und Sperrung von Nutzer*innenkontos führen. Ein
            Verdachtsfall reicht aus. Es besteht kein Anspruch auf
            Rückerstattung von bezahlten Beträgen. Bei Zuwiderhandlungen bleibt
            die Strafanzeige ausdrücklich vorbehalten.
          </p>
          <h2>Änderung AGB</h2>
          <p>
            Nutzer<em
              >innen nehmen ausdrücklich zur Kenntnis, dass Änderungen der ABG
              vorbehalten bleiben und dass ihnen diese in geeigneter Form zur
              Kenntnis gebracht werden. Durch die fortgesetzte Nutzung des AS,
              nach dem Inkrafttreten von Änderungen, stimmen Nutzer</em
            >innen diesen zu. Aus einer Änderung der AGB können Nutzer*innen
            keine Rechte ableiten. Alle geänderten Bedingungen treten an die
            Stelle aller früheren Bedingungen.
          </p>
          <h2>Sonderangebote</h2>
          <p>
            Sonderangebote können im AS oder auf weiteren Kommunikationskanälen
            werden und gelten nur während bestimmten Zeiträumen.
          </p>
          <h2>Anwendbares Recht, Gerichtsstand und Schiedsgericht</h2>
          <p>
            Sämtliche Rechtsbeziehungen aus dieser AGB unterstehen dem Schweizer
            Recht. Gerichtsstand ist in Winterthur ZH. Alle Streitigkeiten,
            Meinungsverschiedenheiten oder Ansprüche aus oder im Zusammenhang
            mit diesem Vertrag, einschliesslich dessen Gültigkeit, Ungültigkeit,
            Verletzung oder Auflösung, sind durch ein Schiedsverfahren gemäss
            der Internationalen schweizerischen Schiedsordnung der Swiss
            Chambers‘ Arbitration Institution zu entscheiden. Es gilt die zur
            Zeit der Zustellung der Einleitungsanzeige in Kraft stehende Fassung
            der Schiedsordnung. Das Schiedsgericht soll aus mindestens 2
            Mitgliedern bestehen. Der Sitz des Schiedsverfahrens muss in der
            Schweiz sein. Die Sprache des Schiedsverfahrens ist Deutsch.
          </p>
          <h2>Salvatorische Klausel</h2>
          <p>
            Sollte eine Bestimmung dieser AGB ungültig sein oder werden, so wird
            dadurch die Wirksamkeit der AGB im Übrigen nicht berührt. Die
            unwirksame Bestimmung wird durch eine wirksame Bestimmung ersetzen,
            die dem gewollten wirtschaftlichen Zweck der ungültigen Bestimmung
            sowie dem ursprünglich vereinbarten Vertragsgleichgewicht möglichst
            nahekommt. Dasselbe gilt auch für allfällige Vertragslücken.
          </p>
          <p>
            Ausgabe September 2021 P2B-Tobias Steiner Elsauerstrasse 20 8352
            Elsau
          </p>
          <p>
            Der automatisierte Zugriff auf die Plattform, beispielsweise mit
            Bots, Skripten oder vergleichbaren Mitteln, ist untersagt. Die
            Anbieterin kann Ausnahmen wie beispielsweise die Nutzung von
            Programmierschnittstellen vorsehen.
          </p>
        </div>
      </md-card>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  setup() {
    const store = userStore();
    return {
      store,
    };
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped>
body {
  background: #f3f4f6;
}
.header-filter::before {
  background: #f3f4f6;
}
</style>

import { defineStore } from 'pinia'
import request from '@/utils/request'

// useStore could be anything like useUser, useCart
// the first argument is a unique id of the store across your application
export const useCategoryStore = defineStore('categoryStore', {
    state: () => ({
        categories: []
    }),
    actions: {
        async getCategories() {
            await request.get("/api/directory").then(response => {
                this.categories = response.data
            });
        },
        async createCategory(category) {
            request.post("/api/directory", category, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
        },
        async deleteCategory(categoryID) {
            await request.delete("/api/directory/" + categoryID);
        },
        async updateCategory(formData) {
            await request.post("/api/directory/patch", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
        },
        searchCategories(string) {
            if(string.length >= 1) {
                return this.categories.filter(category => category.name.toLowerCase().includes(string.toLowerCase()));
            } else {
                return []
            }
        }
    }
})
<template>
  <div
    class="wrapper"
    :class="[
      { 'nav-open': $sidebar.showSidebar },
      { rtl: $route.meta.rtlActive },
    ]"
  >
    <notifications></notifications>
    <side-bar
      :active-color="sidebarBackground"
      :background-image="sidebarBackgroundImage"
      :data-background-color="sidebarBackgroundColor"
    >
      <user-menu></user-menu>
      <template slot="links">
        <sidebar-item
          v-if="store.type >= 1"
          :link="{
            name: 'Coach',
            icon: 'admin_panel_settings',
            path: '/coach',
          }"
        >
        </sidebar-item>
        <sidebar-item
          v-if="store.type >= 2"
          :link="{
            name: 'Administration',
            icon: 'admin_panel_settings',
            path: '/admin',
          }"
        >
        </sidebar-item>

        <sidebar-item
          @click="toggleMenu"
          :link="{
            name: 'Dashboard',
            icon: 'dashboard',
            path: '/Dashboard',
          }"
        ></sidebar-item>
        <sidebar-item
          @click="toggleMenu"
          :link="{
            name: 'Newsfeed',
            icon: 'feed',
            path: '/newsfeed',
          }"
        ></sidebar-item>
        <sidebar-item
          @click="toggleSidebar"
          :link="{ name: 'Kurse', icon: 'route', path: '/courses' }"
        ></sidebar-item>

        <sidebar-item
          :link="{
            name: 'Favoriten',
            icon: 'star',
            path: '/favourites',
          }"
        ></sidebar-item>

        <sidebar-item
          :link="{
            name: 'Übungen',
            icon: 'run_circle',
            path: '/exercises',
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: 'Coaches',
            icon: 'groups',
            path: '/coaches',
          }"
        ></sidebar-item>

        <sidebar-item
          :link="{
            name: 'Abonnement',
            icon: 'payment',
            path: '/abonnement',
          }"
        ></sidebar-item>

        <!-- <sidebar-item :link="{ name: 'Pages', icon: 'image' }">
          <sidebar-item
            :link="{ name: 'Pricing', path: '/pricing' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: 'Register', path: '/register' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: 'Lock Screen', path: '/lock' }"
          ></sidebar-item>
        </sidebar-item> -->

        <!-- <sidebar-item  :link="{ name: 'Components', icon: 'apps' }" > 
          <sidebar-item
            :link="{ name: 'Buttons', path: '/components/buttons' }"
          ></sidebar-item>
           <sidebar-item
            :link="{ name: 'Grid System', path: '/components/grid-system' }"
          ></sidebar-item> 
          <sidebar-item
            :link="{ name: 'Panels', path: '/components/panels' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: 'Sweet Alert', path: '/components/sweet-alert' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: 'Notifications', path: '/components/notifications' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: 'Icons', path: '/components/icons' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: 'Typography', path: '/components/typography' }"
          ></sidebar-item>

        </sidebar-item> -->

        <!--<sidebar-item :link="{ name: 'Forms', icon: 'content_paste' }">
          <sidebar-item
            :link="{ name: 'Regular Forms', path: '/forms/regular' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: 'Extended Forms', path: '/forms/extended' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: 'Validation Forms', path: '/forms/validation' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: 'Wizard', path: '/forms/wizard' }"
          ></sidebar-item>
        </sidebar-item>-->

        <!--<sidebar-item  :link="{ name: 'Tables', icon: 'grid_on' }">
          <sidebar-item
            :link="{ name: 'Regular Tables', path: '/table-list/regular' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: 'Extended Tables', path: '/table-list/extended' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: 'Paginated Tables', path: '/table-list/paginated' }"
          ></sidebar-item>


        </sidebar-item>-->
      </template>
    </side-bar>
    <div class="main-panel">
      <top-navbar></top-navbar>

      <div
        :class="{ content: !$route.meta.hideContent }"
        @click="toggleSidebar"
      >
        <zoom-center-transition :duration="200" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </zoom-center-transition>
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
    document.getElementsByClassName(className)[0].scrollTop = 0;
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

function reinitScrollbar() {
  let docClasses = document.body.classList;
  let isWindows = navigator.platform.startsWith("Win");
  if (isWindows) {
    // if we are on windows OS we activate the perfectScrollbar function
    // initScrollbar("sidebar");
    // initScrollbar("sidebar-wrapper");
    // initScrollbar("main-panel");

    docClasses.add("perfect-scrollbar-on");
  } else {
    docClasses.add("perfect-scrollbar-off");
  }
}

import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import UserMenu from "./Extra/UserMenu.vue";
import { ZoomCenterTransition } from "vue2-transitions";
import { userStore } from "../../../stores/UserStore";

export default {
  components: {
    TopNavbar,
    ContentFooter,
    UserMenu,
    ZoomCenterTransition,
  },
  setup() {
    const store = userStore();
    store.loadUser();
    return {
      store,
    };
  },

  data() {
    return {
      sidebarBackgroundColor: "black",
      sidebarBackground: "green",
      sidebarBackgroundImage: require("@/assets/img/sidebar-2.png"),
      sidebarMini: true,
      sidebarImg: true,
    };
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    minimizeSidebar() {
      if (this.$sidebar) {
        this.$sidebar.toggleMinimize();
      }
    },
    toggleMenu() {},
  },
  updated() {
    reinitScrollbar();
  },
  mounted() {
    reinitScrollbar();
  },
  watch: {
    sidebarMini() {
      this.minimizeSidebar();
    },
  },
};
</script>
<style>
.wrapper {
  height: initial !important;
}
</style>
<style lang="scss">
$scaleSize: 0.95;
@keyframes zoomIn95 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  to {
    opacity: 1;
  }
}
.main-panel .zoomIn {
  animation-name: zoomIn95;
}
@keyframes zoomOut95 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}
.main-panel .zoomOut {
  animation-name: zoomOut95;
}
</style>

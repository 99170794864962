<template>
  <div>
    <md-dialog :md-active.sync="showDialog">

    <md-dialog-title>{{ item.name }}</md-dialog-title>


      <div 
        class="
          md-layout-item
          md-size-100
          md-medium-size-100
          md-small-size-100
          md-xsmall-size-100
        "
      >

        <md-field class="md-form-group">
          <label>Ertrag im ersten Jahr</label>
          <md-input typeID="content" v-model="group.first"></md-input>
        </md-field>
        <md-field class="md-form-group">
          <label>Nach dem ersten Jahr</label>
          <md-input typeID="content" v-model="group.after"></md-input>
        </md-field>

      </div>

      <md-dialog-actions>
        <md-button class="md-success" @click="closeDialog">Close</md-button>
        <md-button class="md-success" @click="updateGroup">Speichern</md-button>
      </md-dialog-actions>
    </md-dialog>

  </div>
</template>

<script>

import { useGroupStore } from '@/stores/GroupStore';

export default {
    components: {
    },
    props: [
        'item',
        'showDialog'
    ],
    setup() {
        const groupStore = useGroupStore();
        return {
            groupStore,
        };
    },
    data() {
        return {
            group: this.item
        }
    },
    beforeMount() {
        this.group = Object.assign({}, this.item);
    },
    methods: {
        closeDialog() {
            this.$emit("closeDialog", false)
        },
        updateGroup() {
            this.groupStore.updateGroup(this.group);
            this.$emit("closeDialog", true)
        }
    },
}
</script>
<style scoped>
  .md-field.md-form-group:after{
    width:100% !important;
  }
</style>
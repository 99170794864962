<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <h2 style="display: inline-block">{{ mediaObject.name }}</h2>
    </div>
    <ActionBarComponent
      @favorite="updateFavorite"
      :mediaObject="mediaObject"
      class="md-layout-item md-size-100"
    ></ActionBarComponent>
    <ExerciseContent :exercise="mediaObject" class="md-layout-item md-size-100">
    </ExerciseContent>
    <AudioPlayer
      class="md-layout-item md-size-100"
      v-for="file in mediaObject.audio"
      :key="file.id"
      :file="file"
    >
    </AudioPlayer>
    <FileGallery class="md-layout-item md-size-100" :files="mediaObject.files">
    </FileGallery>
  </div>
</template>

<script>

import ActionBarComponent from "./ActionBarComponent.vue";
import ExerciseContent from "@/pages/Dashboard/Components/ExerciseContent.vue";
import AudioPlayer from "@/pages/Dashboard/Components/AudioPlayer.vue";
import FileGallery from "@/pages/Dashboard/Components/FileGallery.vue";

export default {
  components: {
    ActionBarComponent,
    AudioPlayer,
    FileGallery,
    ExerciseContent,
  },
  emits: ["updateFavorite", "progressionStatus"],
  props: {
    mediaObject: Object,
  },
  data() {
    return {
      html: "",
      quiz: [],
      validationComplete: false,
    };
  },
  mounted() {},
  methods: {
    updateFavorite() {
      this.$emit("updateFavorite");
    },
    progressionStatus() {
      this.$emit("progressionStatus");
    },
  },
};
</script>

<style scoped>
.wrong {
  color: darkred;
}

.right {
  color: darkgreen;
}

.padding {
  padding: 40px 120px 0px 120px;
}

/* .wrapper
    {
        background-color: rgb(230, 230, 230) !important;
        margin: 10px 0px 10px 0px;
        padding: 10px 0px 10px 15px;
        border-radius: 8px;
    } */

.link,
.link:hover,
.link:focus,
.link:active {
  text-decoration: none;
  color: inherit;
}

.file-container {
  cursor: pointer;
  margin-right: 3rem;

  display: flex;
  /*align-items: center; */
}

.ql-image {
  margin: 0 auto;
}
</style>

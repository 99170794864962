<template>
  <div>
    <md-dialog :md-active.sync="showDialog" style="z-index: 9999 !important">
      <md-dialog-title>{{ item.name }}</md-dialog-title>

      <div class="md-layout">
        <div class="md-layout-item md-size-100">
          <md-field class="md-form-group">
            <label>Name</label>
            <md-input typeID="content" v-model="tier.name"></md-input>
          </md-field>
        </div>
        <div class="md-layout-item md-size-100">
          <md-field class="md-form-group">
            <label>Verknüpftes Produkt</label>
            <md-input
              typeID="content"
              v-model="tier.product_id"
              disabled
            ></md-input>
          </md-field>
        </div>
        <div
          class="md-layout-item md-size-100 md-medium-size-100 md-small-size-100 md-xsmall-size-100"
        >
          <md-field class="md-form-group">
            <label>Zugewiesene Stufe</label>
            <md-input typeID="content" v-model="tier.level"></md-input>
          </md-field>
        </div>
        <div class="md-layout-item md-size-100" style="height: 100%">
          <VueEditor
            v-model="tier.description"
            :editor-config="editorConfig"
            :editor-toolbar="editorConfig.toolbar"
          ></VueEditor>
        </div>
      </div>

      <md-dialog-actions class="mx-auto md-layout text-center">
        <md-button class="md-success md-layout-item" @click="closeDialog"
          >Schliessen</md-button
        >
        <md-button class="md-success md-layout-item" @click="updateTier"
          >Speichern</md-button
        >
      </md-dialog-actions>
    </md-dialog>
  </div>
</template>

<script>
import { useTierStore } from "@/stores/TierStore";
import { VueEditor } from "vue2-editor";

export default {
  components: {
    VueEditor,
  },
  props: ["item", "showDialog"],
  setup() {
    const tierStore = useTierStore();
    return {
      tierStore,
    };
  },
  data() {
    return {
      tier: null,
      editorConfig: {
        toolbar: [
          ["bold", "italic", "underline", "strike"],
          ["blockquote", "code-block"],
          [{ header: 1 }, { header: 2 }],
          [{ list: "ordered" }, { list: "bullet" }],
          [{ script: "sub" }, { script: "super" }],
          [{ indent: "-1" }, { indent: "+1" }],
          [{ direction: "rtl" }],
          [{ size: ["small", false, "large", "huge"] }],
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          [{ color: [] }, { background: [] }],
          [{ font: [] }],
          [{ align: [] }],
          ["clean"],
        ],
      },
    };
  },
  beforeMount() {
    this.tier = Object.assign({}, this.item);
  },
  mounted() {},
  methods: {
    closeDialog() {
      this.$emit("closeDialog", false);
    },
    updateTier() {
      this.tierStore.updateTier(this.tier);
      this.$emit("closeDialog", true);
    },
  },
};
</script>
<style>
.md-dialog-container {
  min-width: 400px;
  min-height: 50%;
  overflow: auto !important;

}
.md-dialog{
}
</style>

import { defineStore } from 'pinia'
import request from '@/utils/request'

// useStore could be anything like useUser, useCart
// the first argument is a unique id of the store across your application
export const useGroupStore = defineStore('groupStore', {
    state: () => ({
        groups: []
    }),
    actions: {
        async getGroups() {
            await request.get('/api/affiliate/group').then(response => {
                this.groups = response.data
            })
        },
        async updateGroup(group) {
            await request.put('/api/affiliate/group/' + group.id, group)
        },
    }
  // other options...
})
import { defineStore } from 'pinia'
import request from '@/utils/request'

// useStore could be anything like useUser, useCart
// the first argument is a unique id of the store across your application
export const useAffiliateGroupStore = defineStore('affiliateGroupStore', {
    state: () => ({
        groups: []
    }),
    actions: {
        async getGroups() {
            await request.get('/api/affiliate/group').then(response => {
                this.groups = response.data
            })
        }
    }
})
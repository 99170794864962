<template>
  <div class="md-layout text-center">
    <h2 class="md-layout-item md-size-100">
      {{ mediaObject.name }}
    </h2>
    <ActionBarComponent
      v-if="dataLoaded"
      :mediaObject="mediaObject"
      @favorite="favorite"
      class="md-layout-item md-size-80 mx-auto"
    ></ActionBarComponent>
    <ExerciseContent
      v-if="dataLoaded"
      :exercise="mediaObject"
      class="md-layout-item md-size-80 mx-auto"
    >
    </ExerciseContent>
    <AudioPlayer
      class="md-layout-item md-size-80 mx-auto"
      v-for="file in mediaObject.audio"
      :key="file.id"
      :file="file"
    >
    </AudioPlayer>
    <FileGallery
      class="md-layout-item md-size-80 mx-auto"
      :files="mediaObject.files"
    >
    </FileGallery>
    <div
      class="md-layout-item md-size-80 md-layout mx-auto article-content"
      v-html="mediaObject.html"
    ></div>
  </div>
</template>
<script>
import request from "@/utils/request";
import ActionBarComponent from "@/pages/Dashboard/Courses/ActionBarComponent";
import ExerciseContent from "@/pages/Dashboard/Components/ExerciseContent.vue";
import AudioPlayer from "@/pages/Dashboard/Components/AudioPlayer.vue";
import FileGallery from "@/pages/Dashboard/Components/FileGallery.vue";

export default {
  components: {
    ActionBarComponent,
    ExerciseContent,
    AudioPlayer,
    FileGallery,
  },
  props: {
    directory: Number,
    article: String,
  },
  data() {
    return {
      mediaObject: {},
      mediaID: null,
      dataLoaded: false,
    };
  },
  mounted() {
    this.mediaID = this.$route.params.exerciseID;
    request.get("/api/media/" + this.mediaID).then((response) => {
      this.mediaObject = response.data;
      this.dataLoaded = true;
    });
  },
  methods: {
    favorite(isFavorite) {
      if (isFavorite) {
        this.mediaObject.favorite = true;
      } else {
        this.mediaObject.favorite = false;
      }
    },
  },
};
</script>

<style scoped>
audio {
  min-height: 1rem;
}
.padding {
  padding: 40px 120px 0px 120px;
}
.wrapper {
  background-color: rgb(230, 230, 230) !important;
  margin: 10px 0px 10px 0px;
  padding: 10px 0px 10px 15px;
  border-radius: 8px;
}
.link,
.link:hover,
.link:focus,
.link:active {
  text-decoration: none;
  color: inherit;
}
.article-content ul li {
  list-style: disc outside none;
  display: list-item;
  margin-left: 1em;
}
.article-content ol li {
  list-style: decimal outside none;
  display: list-item;
  margin-left: 1em;
}
</style>

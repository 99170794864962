<template>
  <div class="md-layout text-center md-alignment-top-left mx-auto">
    <CoachesCardComponent
      class="md-layout-item md-xlarge-size-20 md-large-size-20 md-medium-size-33 md-small-size-50 md-xsmall-size-100"
      v-for="coach in coachStore.coaches"
      :key="coach.id"
      :coach="coach"
    ></CoachesCardComponent>
  </div>
</template>
<script>
import { useCoachStore } from "@/stores/CoachStore";
import CoachesCardComponent from "@/pages/Dashboard/Pages/Components/CoachesCardComponent.vue";

export default {
  components: {
    CoachesCardComponent,
  },
  setup() {
    const coachStore = useCoachStore();
    return { coachStore };
  },
  data() {
    return {};
  },
  beforeMount() {
    this.coachStore.getCoaches().then((response) => {});
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
body {
  margin-top: 20px;
}
.icon-box.medium {
  font-size: 20px;
  width: 50px;
  height: 50px;
  line-height: 50px;
}
.icon-box {
  font-size: 30px;
  margin-bottom: 33px;
  display: inline-block;
  color: #ffffff;
  height: 65px;
  width: 65px;
  line-height: 65px;
  background-color: #59b73f;
  text-align: center;
  border-radius: 0.3rem;
}
.social-icon-style2 li a {
  display: inline-block;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
  background: #59b73f;
  height: 41px;
  line-height: 41px;
  width: 41px;
}
.rounded-3 {
  border-radius: 0.3rem !important;
}

.social-icon-style2 {
  margin-bottom: 0;
  display: inline-block;
  padding-left: 10px;
  list-style: none;
}

.social-icon-style2 li {
  vertical-align: middle;
  display: inline-block;
  margin-right: 5px;
}

a,
a:active,
a:focus {
  color: #616161;
  text-decoration: none;
  transition-timing-function: ease-in-out;
  -ms-transition-timing-function: ease-in-out;
  -moz-transition-timing-function: ease-in-out;
  -webkit-transition-timing-function: ease-in-out;
  -o-transition-timing-function: ease-in-out;
  transition-duration: 0.2s;
  -ms-transition-duration: 0.2s;
  -moz-transition-duration: 0.2s;
  -webkit-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
}

.text-secondary,
.text-secondary-hover:hover {
  color: #59b73f !important;
}
.display-25 {
  font-size: 1.4rem;
}

.text-primary,
.text-primary-hover:hover {
  color: #000 !important;
}

p {
  margin: 0 0 20px;
}

.mb-1-6,
.my-1-6 {
  margin-bottom: 1.6rem;
}

v-cloak {
  display: none;
}
.line {
  border-top: 2px solid gray;
  padding-bottom: 20px;
}
.card-block {
  padding: 50px;
  background-color: rgb(230, 230, 230);
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.04), 0 41.8px 33.4px rgba(0, 0, 0, 0.05),
    0 100px 80px rgba(0, 0, 0, 0.02);
}
.image {
  padding-top: 20px;
  border-radius: 5px;
  float: right;
  height: 150px;
  width: 150px;
}
.title {
  padding-top: 20px;
  font-weight: 800;
  font-size: 20px;
}

.card-block {
  padding: 50px;
  background-color: rgb(230, 230, 230);
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.04), 0 41.8px 33.4px rgba(0, 0, 0, 0.05),
    0 100px 80px rgba(0, 0, 0, 0.02);
}
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 80%;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  display: block;
  margin-top: 1rem;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */
.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  transform: scale(1.1);
}
.ck-content {
  height: 20rem;
  max-height: 20rem;
}

.ck-content ul li {
  list-style: disc outside none;
  display: list-item;
  margin-left: 1em;
}
.ck-content ol li {
  list-style: decimal outside none;
  display: list-item;
  margin-left: 1em;
}

.ql-editor {
  max-height: 200px;
  overflow: scroll;
}
</style>

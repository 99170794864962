import { defineStore } from "pinia";
import request from "@/utils/request";

// useStore could be anything like useUser, useCart
// the first argument is a unique id of the store across your application
export const useExerciseShowcaseStore = defineStore("exerciseShowcaseStore", {
  state: () => ({
    exerciseShowcases: [],
  }),
  actions: {
    async createExerciseShowcase(showcase) {
      await request
        .post("/api/exercise/showcase", showcase)
        .then((response) => {});
    },
    async updateExerciseShowcase(showcase) {
      await request.patch("/api/exercise/showcase/" + showcase.id, showcase);
    },
    async deleteExerciseShowcase(id) {
      await request.delete("/api/exercise/showcase/" + id);
    },
  },
});

<template>
  <div class="user">
    <div class="photo">
      <img v-if="loadingFinished" :src="avatar" alt="avatar" />
    </div>
    <div class="user-info">
      <a
        data-toggle="collapse"
        :aria-expanded="!isClosed"
        @click.stop="toggleMenu"
        @click.capture="clicked"
      >
        <span v-if="$route.meta.rtlActive">
          {{ rtlTitle }}
          <b class="caret"></b>
        </span>
        <span v-else>
          {{ store.name }}
          <b class="caret"></b>
        </span>
      </a>

      <collapse-transition>
        <div v-show="!isClosed">
          <ul class="nav">
            <slot>
              <li>
                <router-link :to="'/pages/user'">
                  <a href="pages/user">
                    <span class="sidebar-mini">MP</span>
                    <span class="sidebar-normal">Mein Profil</span>
                  </a>
                </router-link>
              </li>
              <li>
                <router-link :to="'/logout'">
                  <a href="/logout">
                    <span class="sidebar-mini"
                      ><font-awesome-icon size="lg" icon="fa-solid fa-sign-out"
                    /></span>
                    <span class="sidebar-normal">Logout</span>
                  </a>
                </router-link>
              </li>
            </slot>
          </ul>
        </div>
      </collapse-transition>
    </div>
  </div>
</template>
<script>
import { CollapseTransition } from "vue2-transitions";
import { userStore } from "../../../../stores/UserStore";
import request from "@/utils/request";

export default {
  components: {
    CollapseTransition,
  },
  props: {
    title: {
      type: String,
      default: "Tobias Steiner",
    },
    rtlTitle: {
      type: String,
      default: "تانيا أندرو",
    },
  },
  setup() {
    const store = userStore();
    return {
      store,
    };
  },
  data() {
    return {
      isClosed: true,
      loadingFinished: false,
      avatar: require("@/assets/img/faces/avatar.png"),
    };
  },
  mounted() {
    this.loadingFinished = false;
    this.store.loadUser().then((response) => {
      if (this.store.image != null) {
        this.avatar = this.store.image;
      }
      this.loadingFinished = true;
    });
  },
  methods: {
    clicked: function (e) {
      e.preventDefault();
    },
    toggleMenu: function () {
      this.isClosed = !this.isClosed;
    },
    signOut() {
      request.get("/logout").then((response) => {
        this.$router.push("/login");
      });
    },
  },
};
</script>
<style>
.collapsed {
  transition: opacity 1s;
}
</style>

<template>
    <div class="md-layout">
        <md-card class="md-layout-item text-center">
            <md-card-content>
                <h4>Die Zahlungsmethode wurde hinterlegt!</h4>
                <md-button class="md-success" @click="$router.push('/pages/user/profile/subscription')">Zurück zu den Einstellungen</md-button>
            </md-card-content>
        </md-card>
    </div>
</template>

<script>

import { useSubscriptionStore } from '@/stores/SubscriptionStore'

export default {
  components: {
  },
  props: ['id'],
  setup() {
    const subscriptionStore = useSubscriptionStore();
    return {
     subscriptionStore
    };
  },
  data() {
    return {
    };
  },
  beforeMount() {
  },
  mounted() {
  },
}
</script>

<style>

</style>
<template>
  <md-dialog @md-clicked-outside="closeDialog" :md-active.sync="showDialog">
    <md-dialog-title class="mx-auto">Showcase Bearbeiten</md-dialog-title>
    <md-dialog-content class="text-center md-layout" style="padding: 1rem">
      <p v-if="objectType == 'course'" class="md-layout-item md-size-100">
        Kurs
      </p>
      <p v-if="objectType == 'exercise'" class="md-layout-item md-size-100">
        Übung
      </p>
      <md-field class="md-form-group md-layout-item md-size-50">
        <label>Titel</label>
        <md-input typeID="content" v-model="showcaseTitle"></md-input>
      </md-field>
      <vue-editor
        class="md-layout-item md-size-100"
        v-model="showcaseDescription"
        :editor-config="editorConfig"
        :editor-toolbar="editorConfig.toolbar"
      ></vue-editor>
    </md-dialog-content>
    <md-dialog-actions class="mx-auto" style="padding-top: 25px">
      <md-button class="md-success" @click="closeDialog">Close</md-button>
      <md-button class="md-success" @click="editShowcase"
        >Showcase speichern</md-button
      >
    </md-dialog-actions>
  </md-dialog>
</template>

<script>
import { useCourseShowcaseStore } from "@/stores/CourseShowcaseStore";
import { useExerciseShowcaseStore } from "@/stores/ExerciseShowcaseStore";
import { VueEditor } from "vue2-editor";

export default {
  components: {
    VueEditor,
  },
  props: ["showcase", "objectType", "showDialog"],
  setup() {
    const courseShowcaseStore = useCourseShowcaseStore();
    const exerciseShowcaseStore = useExerciseShowcaseStore();
    return {
      courseShowcaseStore,
      exerciseShowcaseStore,
    };
  },
  data() {
    return {
      showcaseTitle: this.showcase.title,
      showcaseDescription: this.showcase.description,
      editorConfig: {
        toolbar: [
          ["bold", "italic", "underline", "strike"],
          ["blockquote", "code-block"],
          [{ header: 1 }, { header: 2 }],
          [{ list: "ordered" }, { list: "bullet" }],
          [{ script: "sub" }, { script: "super" }],
          [{ indent: "-1" }, { indent: "+1" }],
          [{ direction: "rtl" }],
          [{ size: ["small", false, "large", "huge"] }],
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          [{ color: [] }, { background: [] }],
          [{ font: [] }],
          [{ align: [] }],
          ["clean"],
        ],
      },
    };
  },
  methods: {
    editShowcase() {
      if (this.objectType == "course") {
        this.courseShowcaseStore
          .updateCourseShowcase({
            id: this.showcase.id,
            title: this.showcaseTitle,
            description: this.showcaseDescription,
          })
          .then((response) => {
            this.closeDialog(true);
          });
      } else if (this.objectType == "exercise") {
        this.exerciseShowcaseStore
          .updateExerciseShowcase({
            id: this.showcase.id,
            title: this.showcaseTitle,
            description: this.showcaseDescription,
          })
          .then((response) => {
            this.closeDialog(true);
          });
      }
    },
    closeDialog(hasChanges = false) {
      this.$emit("closeDialog", hasChanges);
    },
  },
  watch: {
    showDialog: function (val) {
      if (val) {
      }
    },
  },
};
</script>

<style>
.md-dialog-container {
  height: auto;
  min-width: 40vw;
  min-height: 40vh;
}
</style>

import { defineStore } from "pinia";
import request from "@/utils/request";

// useStore could be anything like useUser, useCart
// the first argument is a unique id of the store across your application
export const useCourseShowcaseStore = defineStore("courseShowcaseStore", {
  state: () => ({
    courseShowcases: [],
  }),
  actions: {
    async createCourseShowcase(showcase) {
      await request
        .post("/api/course/showcase", showcase)
        .then((response) => {});
    },
    async updateCourseShowcase(showcase) {
      await request.patch("/api/course/showcase/" + showcase.id, showcase);
    },
    async deleteCourseShowcase(id) {
      await request.delete("/api/course/showcase/" + id);
    },
  },
});

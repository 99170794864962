import { defineStore } from 'pinia'
import request from '@/utils/request'

// useStore could be anything like useUser, useCart
// the first argument is a unique id of the store across your application
export const useDifficultyStore = defineStore('difficultyStore', {
    state: () => ({
        difficulties: []
    }),
    actions: {
        async getDifficulties() {
            await request.get("/api/media/difficulty").then(response => {
                this.difficulties = response.data
            });
        },
        async createDifficulty(difficulty) {
            await request.post("/api/media/difficulty", difficulty);
        },
        async deleteDifficulty(difficultyID) {
            await request.delete("/api/media/difficulty/" + difficultyID);
        },
        async updateDifficulty(difficulty) {
            await request.put("/api/media/difficulty/" + difficulty.id, difficulty);
        }
    }
})
<template>
  <div class="md-layout text-left">
    <div class="md-layout-item md-size-100">
      <div style="text-align: center">
        <img
          :src="require('@/assets/img/logo.png')"
          alt="Logo"
          class="logo"
          width="160px"
          style=""
        />
      </div>
      <md-card>
        <div
          class="w-full sm:max-w-2xl mt-6 p-6 bg-white shadow-md overflow-hidden sm:rounded-lg prose"
          style="padding: 24px"
        >
          <h1>Datenschutzbestimmungen</h1>
          <p>
            Verantwortliche Stelle im Sinne der Datenschutzgesetze, insbesondere
            dem Bundesgesetz über den Datenschutz (DSG), der Verordnung zum
            Bundesgesetz über den Datenschutz (VDSG) und der
            EU-Datenschutzverordnung (DSGV), ist:
          </p>
          <p>
            P2B – Tobias Steiner Tobias Steiner Elsauerstrasse 20 8352 Elsau
          </p>
          <p>(nachfolgend P2B genannt)</p>
          <p>
            Email:
            <a href="mailto:info@perfom2balance.ch">info@perfom2balance.ch</a>
            WebSite: app.academyspace.ch
          </p>
          <h2>Datenerfassung</h2>
          <p>
            P2B respektiert die Privatsphäre ihrer Nutzer<em
              >innen. P2B behandelt die personenbezogene Daten ihrer Nutzer</em
            >innen vertraulich und entsprechend der gesetzlichen
            Datenschutzvorschrift sowie dieser Datenschutzerklärung. P2B weist
            daraufhin, dass die Datenübertragung im Internet Sicherheitslücken
            aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff
            unberechtigter Dritter ist nicht möglich. P2B erfasst bestimmte
            Daten ihrer Nutzer<em
              >innen direkt. Darunter fallen Informationen die Nutzer</em
            >innen selbst eingeben, Content-Nutzung sowie von Drittanbieter<em
              >innen die in einem Zusammenhang stehen. Welche Daten P2B erfasst,
              richtet sich nach den in Anspruch genommenen Dienstleistungen der
              Nutzer</em
            >innen. Wenn Nutzer*innen ein Konto erstellen, erfasst P2B folgende
            Daten:
          </p>
          <ul>
            <li>
              Kontodaten (z.B. E-Mail, Passwort, Telefonnummern, Geschlecht,
              Alter, individuelle Identifikationsnummer etc.);
            </li>
            <li>
              Lerncontent (z.B. Kurse, Aufgaben, Quizze, Zoom-Meetings,
              Content-Käufe etc.);
            </li>
            <li>
              Zahlungsdaten (z.B. Personalien, bestimmte Zahlungs- und
              Rechnungsstellungsdaten, Informationen zur Kreditkarte etc.);
            </li>
            <li>Zahlungsdienstleiter stripe.com;</li>
            <li>
              Kontodaten von Drittanbieter*innen (Google-Konti,
              Social-Media-Konti, etc.);
            </li>
            <li>
              Gewinnspiele, Werbeaktionen und Umfragen (Möglicherweise ladet P2B
              Nutzer<em>innen oder eine Drittanbieter</em>innen-Plattform zur
              Teilnahme an einer Umfrage oder Werbeaktion bei.);
            </li>
            <li>
              Kommunikation und Support (z.B. bei Problemstellung zum Login
              etc.).
            </li>
          </ul>
          <p>
            P2B bearbeitet nur jene Personendaten die für eine dauerhafte,
            nutzerfreundliche, sichere und zuverlässige Dienstleistung
            erforderlich sind. P2B bearbeitet Personendaten nur für die
            zweckmässige und gesetzlich erforderliche Dauer.
          </p>
          <h2>Datensammlung</h2>
          <p>
            Diese Website kann Cookies verwenden. Das sind kleine Textdateien,
            die es möglich machen, auf dem Endgerät der Nutzer<em
              >innen spezifische, auf die Nutzer</em
            >innen bezogenen Informationen zu speichern, während der Nutzung der
            Webseite. Cookies ermöglichen es, insbesondere Nutzungshäufigkeit
            und Nutzeranzahl der Seiten zu ermitteln, Verhaltensweisen der
            Seitennutzung zu analysieren, aber auch unser Angebot
            kundenfreundlicher zu gestalten. Cookies bleiben über das Ende einer
            Browser-Sitzung gespeichert und können bei einem erneuten
            Seitenbesuch wieder aufgerufen werden. Für die Verwaltung von
            Cookies sind Nutzer*innen selber zuständig bzw. verantwortlich.
            Einschränkung von Cookies können Dienstleistungen von P2B
            verunmöglichen.
          </p>
          <h2>Datenverwendung</h2>
          <p>
            P2B kann die Personendate von Nutzer*innen für folgende Zwecke
            verwenden:
          </p>
          <ul>
            <li>
              Server-Logdateien (IP-Adressen, http-Statuscodes, Betriebssysteme
              einschliesslich Benutzeroberflächen und Version, Browser,
              Spracheinstellungen, Referrer);
            </li>
            <li>Zählpixel;</li>
            <li>Benachrichtigungen und Mitteilungen;</li>
            <li>
              Bereitstellung und Verwaltung der Dienstleistungen, darunter die
              Ermöglichung der Nutzung von Lern-Content, Kursresultaten,
              Quizresultaten, die Anzeige von individuellen Content;
            </li>
            <li>Verwaltung von Nutzer*innnen-Konti;</li>
            <li>Technische Funktionen und Problembehebungen;</li>
            <li>Feedbacks von Nutzer*innen;</li>
            <li>Google Fonts;</li>
            <li>
              Vermarktung von Produkten, Dienstleistungen, Werbeaktionen,
              Abonnements;
            </li>
            <li>Optimierung der P2B-Diensleistungen, Produkte und Services;</li>
            <li>Gesetzlich vorgeschrieben oder zulässige Daten.</li>
          </ul>
          <h2>Datenverbreitung</h2>
          <p>P2B kann folgende Personendaten von Nutzer*innen weitergeben:</p>
          <ul>
            <li>
              An Dienstanbieter<em>innen, Auftraggeber</em>innen und
              Bevollmächtigte
            </li>
            <li>An Geschäftspartner*innen;</li>
            <li>An Analyse- und Datenanrechnungsservices;</li>
            <li>Social-Media-Features;</li>
            <li>Für personalisierte Werbezwecke;</li>
            <li>Mit der Genehmigung von Nutzer*innen;</li>
            <li>Bei gesellschaftsrechtlichen Umstrukturierungen von P2B.</li>
          </ul>
          <h2>Datenrechte</h2>
          <p>
            Nutzer<em
              >innen haben das Recht auf Einsichtnahme, Löschung und Sperrung
              ihrer Personendaten. Weiter haben Nutzer</em
            >innen ein Beschwerderecht bei der Aufsichtsbehörde für den
            Datenschutz in der Schweiz.
          </p>
          <h2>Datensicherheit</h2>
          <p>
            P2B ergreift angemessene Sicherheitsvorkehrungen, um die
            Personendaten der Nutzer<em
              >innen vor Zugriffen, Änderungen, Offenlegungen oder Vernichtungen
              durch unbefugte Dritte zu schützen. Ein wirklich lückenloser
              Schutz ist leider nicht möglich und kann von P2B nicht garantiert
              werden. Das Passwort der Nutzer</em
            >innen ist eines der wichtigsten Komponente des
            P2B-Sicherheitssystems. Für den rechtmässigen Schutz sind die
            Nutzer<em
              >innen alleine verantwortlich. Das Passwort sollte von Nutzer</em
            >innen niemals an unberechtigte Dritte weitergeben werden. Bei
            Verdacht sollen sich Nutzer*innen direkt an P2B wenden
            (Kontaktadresse
            <a href="mailto:info@perfom2balance.ch">info@perfom2balance.ch</a>).
          </p>
          <h2>Änderung der Datenschutzbestimmungen</h2>
          <p>
            Nutzer<em
              >innen nehmen ausdrücklich zur Kenntnis, dass Änderungen dieser
              Datenschutzbestimmung vorbehalten bleiben und dass ihnen diese in
              geeigneter Form zur Kenntnis gebracht werden. Durch die
              fortgesetzte Nutzung der Dienstleistungen von P2B, nach dem
              Inkrafttreten von Änderungen, stimmen Nutzer</em
            >innen diesen zu. Aus einer Änderung der Datenschutzbestimmung
            können Nutzer*innen keine Rechte ableiten. Alle geänderten
            Bedingungen treten an die Stelle aller früheren Bedingungen.
          </p>
        </div>
      </md-card>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  setup() {
    const store = userStore();
    return {
      store,
    };
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped>
body {
  background: #f3f4f6;
}
.header-filter::before {
  background: #f3f4f6;
}
</style>

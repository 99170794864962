<template>
  <md-dialog
    @md-clicked-outside="closeDialog"
    :md-active.sync="showDialog"
    style="min-width: 100rem"
  >
    <md-dialog-title>{{ item.name }}</md-dialog-title>

    <div class="md-layout" style="overflow: auto">
      <div class="md-layout-item md-size-50">
        <!-- MD Select to choose from exercises-->
        <h4>Übungen</h4>
        <md-field class="md-form-group">
          <label>Nach Übung suchen</label>
          <md-input typeID="content" v-model="exerciseSearch" @input="searchExercise($event)"></md-input>
        </md-field>
        <div style="max-height: 10rem;overflow-y:scroll;">
          <div v-for="exercise in filteredExercises" :key="exercise.id">
            <div class="md-layout">
              <div class="md-layout-item md-size-50">{{ exercise.name }}</div>
              <div class="md-layout-item md-size-50"><md-button class="md-success" @click="addMedia(1, exercise.id)">Hinzufügen</md-button></div>
            </div>
          </div>
        </div>
        <!-- <md-field class="md-form-group">
          <label>Typ</label>
          <md-select
            typeID="content"
            v-model="exerciseOption"
            @md-selected="addMedia(1)"
          >
            <md-option
              v-for="exercise in filteredExercises"
              :key="exercise.id"
              :value="exercise.id"
              >{{ exercise.name }}</md-option
            >
          </md-select>
        </md-field> -->

        <h4>Quiz</h4>
        <md-field class="md-form-group">
          <label>Nach Quiz suchen</label>
          <md-input typeID="content" v-model="quizSearch" @input="searchQuiz($event)"></md-input>
        </md-field>
        <div style="max-height: 10rem;overflow-y:scroll;">
          <div v-for="quiz in filteredQuizzes" :key="quiz.id">
            <div class="md-layout">
              <div class="md-layout-item md-size-50">{{ quiz.name }}</div>
              <div class="md-layout-item md-size-50"><md-button class="md-success" @click="addMedia(2, quiz.id)">Hinzufügen</md-button></div>
            </div>
          </div>
        </div>
        <!-- <md-field class="md-form-group">
          <label>Typ</label>
          <md-select
            typeID="content"
            v-model="quizOption"
            @md-selected="addMedia(2)"
          >
            <md-option
              v-for="exercise in filteredQuizzes"
              :key="exercise.id"
              :value="exercise.id"
              >{{ exercise.name }}</md-option
            >
          </md-select>
        </md-field> -->
      </div>

      <div v-if="course.course_media" class="md-layout-item md-size-50">
        <draggable
          class="dragArea list-group w-full"
          :list="course.course_media"
          @change="log"
        >
          <div
            class="md-card"
            style="
              padding: 10px 20px;
              cursor: move; /* fallback if grab cursor is unsupported */
              cursor: grab;
              cursor: -moz-grab;
              cursor: -webkit-grab;
            "
            v-for="element in course.course_media"
            :key="element.name"
          >
            {{ element.name }}
            <div @click="removeMedia(element.id)">
              <md-icon style="cursor: pointer; color: red; font-size: 24px"
                >delete</md-icon
              >
            </div>
          </div>
        </draggable>
      </div>
      <div v-if="course.course_media" class="md-layout-item md-size-50"></div>
    </div>

    <md-dialog-actions class="mx-auto">
      <md-button class="md-success" @click="closeDialog">Close</md-button>
      <md-button class="md-success" @click="updateCourse">Speichern</md-button>
    </md-dialog-actions>
  </md-dialog>
</template>

<script>
import { useCourseStore } from "@/stores/CourseStore";
import { useExerciseStore } from "@/stores/ExerciseStore";
import draggable from "vuedraggable";

export default {
  components: { draggable },
  props: ["item", "showDialog"],
  setup() {
    const courseStore = useCourseStore();
    const exerciseStore = useExerciseStore();
    return {
      courseStore,
      exerciseStore,
    };
  },
  data() {
    return {
      exerciseSearch: '',
      quizSearch: '',
      course: this.item,
      exercises: this.exerciseStore.exercises,
      exerciseOption: null,
      quizOption: null,
      filteredExercises: [],
      filteredQuizzes: [],
      drag: false,
    };
  },
  created() {
    this.exerciseStore.getCoachExercises().then(() => {
      this.exercises = this.exerciseStore.exercises;
    });
  },
  beforeMount() {
    this.course = Object.assign({}, this.item);
    this.filteredExercises = this.exercises
      .filter((exercise) => exercise.mediaTypeID == 1)
      .filter(
        (exercise) =>
          this.course.course_media.find((media) => media.id == exercise.id) ==
          undefined
      );
    this.filteredQuizzes = this.exercises
      .filter((exercise) => exercise.mediaTypeID == 2)
      .filter(
        (exercise) =>
          this.course.course_media.find((media) => media.id == exercise.id) ==
          undefined
      );
  },
  watch: {
    item() {
      this.course = this.item;
    },
  },
  methods: {
    closeDialog() {
      this.$emit("closeDialog", false);
    },
    log() {
      for (let i = 0; i < this.course.course_media.length; i++) {
        this.course.course_media[i].position = i + 1;
      }
      this.exerciseOption = null;
      this.quizOption = null;
    },
    updateCourse() {
      this.course.directoryID = this.course.directory_id;
      // New Form Data
      const formData = new FormData();
      formData.append("id", this.course.id);
      formData.append("name", this.course.name);
      formData.append("directoryID", this.course.directoryID);
      formData.append("difficultyID", this.course.difficultyID);
      formData.append("course_media", JSON.stringify(this.course.course_media));
      formData.append("banner", this.course.banner);
      formData.append("message", this.course.message);
      formData.append("draft", this.course.draft);
      // Update Course
      this.courseStore.updateCourse(this.course.id, formData);
      this.$emit("closeDialog", true);
    },
    removeMedia(mediaID) {
      var mediaIndex = this.course.course_media.findIndex(
        (media) => media.id == mediaID
      );
      var media = this.course.course_media[mediaIndex];
      this.course.course_media.splice(mediaIndex, 1);
      if (media.mediaTypeID == 1) {
        this.filteredExercises.push(media);
      } else if (media.mediaTypeID == 2) {
        this.filteredQuizzes.push(media);
      }
      this.clearOutSelectFields();
    },
    addMedia(mediaTypeID, id) {
      if (!this.course.course_media) {
        this.course.course_media = [];
      }

      var exerciseID = 0;
      var selectedExercise = [];

      if (mediaTypeID == 1) {
        exerciseID = id;
        selectedExercise = this.filteredExercises.filter(
          (exercise) => exercise.id == exerciseID
        )[0];
        let exercises = this.filteredExercises.filter(
          (exercise) => exercise.id != exerciseID
        );
        this.filteredExercises = exercises;
      } else if (mediaTypeID == 2) {
        exerciseID = id;
        selectedExercise = this.filteredQuizzes.filter(
          (exercise) => exercise.id == exerciseID
        )[0];
        let exercises = this.filteredQuizzes.filter(
          (exercise) => exercise.id != exerciseID
        );
        this.filteredQuizzes = exercises;
      }

      selectedExercise.position = this.course.course_media.length + 1;
      this.course.course_media.push(selectedExercise);
      this.clearOutSelectFields();
    },
    searchExercise(event) {
      if(event.length > 0) {
        this.filteredExercises = this.filteredExercises.filter(exercise =>  exercise.name.includes(event)  )
      } else {
        this.filteredExercises = this.exercises
          .filter((exercise) => exercise.mediaTypeID == 1)
          .filter(
            (exercise) => this.course.course_media.find((media) => media.id == exercise.id) == undefined
          );
      }
    },
    searchQuiz(event) {
      if(event.length > 0) {
        this.filteredQuizzes = this.filteredQuizzes.filter(exercise =>  exercise.name.includes(event)  )
      } else {
        this.filteredQuizzes = this.exercises
          .filter((exercise) => exercise.mediaTypeID == 2)
          .filter(
            (exercise) =>
              this.course.course_media.find((media) => media.id == exercise.id) == undefined
          );
      }
    },
    clearOutSelectFields() {
      this.exerciseOption = undefined;
      this.quizOption = undefined;
    },
  },
};
</script>
<style>
.md-field.md-form-group:before,
.md-field.md-form-group:after {
  width: 100% !important;
}
</style>

<template>
  <div class="md-layout text-center">
    <CreateDifficulty
      :showDialog="showCreateDialog"
      @closeDialog="closeDialog"
    ></CreateDifficulty>
    <EditDifficulty
      v-if="selectedDifficulty"
      :item="selectedDifficulty"
      :showDialog="showEditDialog"
      @closeDialog="closeDialog"
    ></EditDifficulty>

    <div class="md-layout-item md-size-100 md-small-size-100">
      <md-card>
        <div
          style="margin: 10px"
          class="md-layout-item md-size-100 md-small-size-100"
        >
          <h3>test</h3>
          <md-button class="md-success" @click="showCreateDialog = true"
            >Schwierigkeitsgrad hinzufügen</md-button
          >
        </div>
      </md-card>
    </div>

    <TableWrapper
      title="Testtitle"
      :cells="cells"
      :footer="footer"
      :actions="actions"
      :items="items"
      @edit="tableEdit"
      @delete="tableDelete"
    >
    </TableWrapper>
  </div>
</template>
<script>
import { useDifficultyStore } from "@/stores/DifficultyStore";
import TableWrapper from "../Components/TableWrapper.vue";
import EditDifficulty from "@/pages/Dashboard/Pages/Admin/Difficulty/EditDifficulty.vue";
import CreateDifficulty from "@/pages/Dashboard/Pages/Admin/Difficulty/CreateDifficulty.vue";
import Swal from "sweetalert2";

export default {
  components: {
    TableWrapper,
    EditDifficulty,
    CreateDifficulty,
  },
  setup() {
    const difficultyStore = useDifficultyStore();
    return {
      difficultyStore,
    };
  },
  data() {
    return {
      cells: [
        {
          name: "id",
          label: "ID",
          searchable: true,
        },
        {
          name: "name",
          label: "Name",
          searchable: true,
        },
      ],
      actions: ["edit", "delete"],
      items: "",
      footer: ["ID", "Name", "Actions"],
      selectedDifficulty: [],
      showEditDialog: false,
      showCreateDialog: false,
    };
  },
  beforeMount() {
    this.difficultyStore.getDifficulties().then(() => {
      this.items = this.difficultyStore.difficulties;
    });
  },
  mounted() {},
  methods: {
    tableEdit(item) {
      this.showEditDialog = true;
      this.selectedDifficulty = item;
    },
    tableDelete(difficulty) {
      Swal.fire({
        title: "Sind Sie sicher?",
        text: "Diesen Schwierigkeitsgrad wirklich löschen?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ja, löschen!",
        cancelButtonText: "Nein, abbrechen!",
      }).then((result) => {
        if (result.value) {
          this.difficultyStore.deleteDifficulty(difficulty.id).then(
            (response) => {
              this.difficultyStore.getDifficulties().then((response) => {
                this.items = this.difficultyStore.difficulties;
              });
            },
            (errpr) => {}
          );
        }
      });
    },
    closeDialog(withChanges) {
      this.selectedDifficulty = null;
      if (withChanges) {
        this.showEditDialog = false;
        this.showCreateDialog = false;
        // Readload table data
        this.difficultyStore.getDifficulties().then((response) => {
          this.items = this.difficultyStore.difficulties;
        });
      } else {
        this.showEditDialog = false;
        this.showCreateDialog = false;
      }
    },
  },
};
</script>
<style></style>

<template>
  <div class="md-layout">
    <EditCourseContent
      class="dialog-container"
      v-if="selectedCourseItem && showEditContentDialog"
      :item="selectedCourseItem"
      :showDialog="showEditContentDialog"
      @md-clicked-outsitde="closeDialog"
      @closeDialog="closeDialog"
    ></EditCourseContent>
    <EditCourse
      v-if="selectedCourse"
      :item="selectedCourse"
      :showDialog="showEditDialog"
      @closeDialog="closeDialog"
    ></EditCourse>
    <CreateCourse
      :showDialog="showCreateDialog"
      @closeDialog="closeDialog"
    ></CreateCourse>
    <div class="md-layout-item md-size-100 text-center">
      <md-card>
        <div
          style="margin: 10px"
          class="md-layout-item md-size-100 md-small-size-100"
        >
          <h3>Kurse verwalten</h3>
          <md-button class="md-size-20 md-success" @click="openCreateDialog"
            >Kurs erstellen</md-button
          >
          <md-button
            class="md-size-20 md-success"
            @click="openEditContentDialog"
            :disabled="!selectedCourseItem"
            >Kursinhalt anpassen</md-button
          >
        </div>
      </md-card>
    </div>
    <div class="md-size-100 md-layout-item">
      <TableWrapper
        :title="title"
        :cells="cells"
        :footer="footer"
        :actions="actions"
        :items="items"
        @edit="tableEdit"
        @delete="tableDelete"
        @selectedItem="selectedItem"
      ></TableWrapper>
    </div>
  </div>
</template>
<script>
import { useCourseStore } from "@/stores/CourseStore";
import { useDifficultyStore } from "@/stores/DifficultyStore";
import { useCategoryStore } from "@/stores/CategoryStore";
import { useExerciseStore } from "@/stores/ExerciseStore";
import CreateCourse from "@/pages/Dashboard/Pages/Admin/Course/CreateCourse";
import EditCourse from "@/pages/Dashboard/Pages/Admin/Course/EditCourse";
import EditCourseContent from "@/pages/Dashboard/Pages/Admin/Course/EditCourseContent.vue";
import TableWrapper from "../Components/TableWrapper.vue";
import Swal from "sweetalert2";

export default {
  components: {
    TableWrapper,
    EditCourse,
    EditCourseContent,
    CreateCourse,
  },
  setup() {
    const courseStore = useCourseStore();
    const difficultyStore = useDifficultyStore();
    const categoryStore = useCategoryStore();
    const exerciseStore = useExerciseStore();
    return {
      courseStore,
      difficultyStore,
      categoryStore,
      exerciseStore,
    };
  },
  data() {
    return {
      title: "Kurse",
      cells: [
        {
          name: "name",
          label: "Name",
          searchable: true,
        },
        {
          name: "directoryPath",
          label: "Pfad",
          searchable: true,
        },
        {
          name: "difficulty",
          label: "Schwierigkeit",
          searchable: true,
        },
        {
          name: "coachName",
          label: "Coach",
          searchable: true,
        },
      ],
      actions: ["edit", "delete"],
      items: "",
      footer: ["Name", "Pfad", "Schwierigkeit", "Coach", "Actions"],
      selectedCourse: null,
      selectedCourseItem: null,
      showEditDialog: false,
      showCreateDialog: false,
      showEditContentDialog: false,
    };
  },
  beforeMount() {
    this.courseStore.getAdminCourses().then(() => {
      this.items = this.courseStore.courses;
    });
    this.difficultyStore.getDifficulties();
    this.categoryStore.getCategories();
  },
  mounted() {
    this.exerciseStore.getExercises();
  },
  methods: {
    selectedItem(item) {
      this.selectedCourseItem = item;
    },
    tableEdit(item) {
      this.showEditDialog = true;
      this.selectedCourse = item;
    },
    tableDelete(course) {
      Swal.fire({
        title: "Sind Sie sicher?",
        text: "Diesen Kurs wirklich löschen?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ja, löschen!",
        cancelButtonText: "Nein, abbrechen!",
      }).then((result) => {
        if (result.value) {
          this.courseStore.deleteCourse(course.id).then(
            (response) => {
              this.courseStore.getAdminCourses().then((response) => {
                this.items = this.courseStore.courses;
              });
            },
            (errpr) => {}
          );
        }
      });
    },
    openCreateDialog() {
      this.showCreateDialog = true;
    },
    openEditContentDialog() {
      this.courseStore
        .getAdminCourse(this.selectedCourseItem.id)
        .then((response) => {
          this.selectedCourseItem = this.courseStore.course;
          this.showEditContentDialog = true;
        });
    },
    closeDialog(withChanges) {
      this.selectedCourse = null;
      this.showEditDialog = false;
      this.showEditContentDialog = false;
      this.showCreateDialog = false;
      if (withChanges) {
        // Readload table data
        this.courseStore.getAdminCourses().then((response) => {
          this.items = this.courseStore.courses;
        });
      }
    },
    closeEditContentDialog(withChanges) {
      this.showEditCOntentDialog = false;
    },
  },
};
</script>
<style scoped>
.md-button {
  margin-left: 10px;
  margin-right: 10px;
}

.md-field.md-form-group:before,
.md-field.md-form-group:after {
  width: 100% !important;
}
.dialog-container .md-dialog-container {
  min-width: 768px !important;
  max-width: calc(100% - 720px) !important;
}
</style>

<template>
    <div v-if="categories">
        <div class="md-layout">
            <div class="md-layout-item md-size-80 md-small-size-100 mx-auto">
                <div class="md-layout-item md-size-100 md-layout">
                    <div
                        class="md-layout-item md-size-80 md-small-size-100"
                        style="margin-top: 15px"
                    >
                        <md-field class="md-form-group">
                            <label>Suchtext eingeben</label>
                            <md-input
                                @input="runSearch()"
                                v-model="search"
                                autocomplete="off"
                            ></md-input>
                        </md-field>
                        <div
                            v-if="searchResult.length > 0 && search.length > 0"
                            class="search-wrapper"
                        >
                            <div
                                v-if="
                                    searchResult.length > 0 && search.length > 0
                                "
                                class="search-results md-layout md-layout-item md-size-100"
                            >
                                <div
                                    v-for="result in searchResult"
                                    :key="result.id"
                                    class="md-layout-item md-size-100"
                                >
                                    <span
                                        v-for="path in result.searchPath"
                                        :key="path.id"
                                    >
                                        <router-link
                                            class="custom-link md-layout-item md-size-100 mx-auto"
                                            :to="{
                                                path:
                                                    '/exercises/category/' +
                                                    path.id
                                            }"
                                        >
                                            {{ path.path }}
                                        </router-link>
                                        \
                                    </span>
                                    <router-link
                                        v-if="result.name && result.unlocked"
                                        :to="{
                                            path:
                                                '/exercises/exercise/' +
                                                result.id
                                        }"
                                        >{{ result.name }}</router-link
                                    >
                                    <span
                                        v-if="result.name && !result.unlocked"
                                        v-html="compileMessage(result)"
                                    ></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="md-layout-item md-size-20">
                        <md-button
                            class="md-success"
                            @click="displayFilters = !displayFilters"
                            style="margin-top: 15px"
                        >
                            <md-icon>filter_list</md-icon>
                            {{
                                filtering
                                    ? "Filter schliessen"
                                    : "Filter Öffnen"
                            }}
                        </md-button>
                    </div>
                </div>

                <div
                    v-if="displayFilters"
                    class="md-layout-item md-layout md-size-100 mx-auto"
                >
                    <!-- Coach Filter -->
                    <div class="md-layout-item">
                        <md-field>
                            <label for="movies">Coach</label>
                            <md-select
                                v-model="filter.userID"
                                name="coachFilter"
                                id="coachFilter"
                                multiple
                            >
                                <md-option
                                    v-for="coach in coaches"
                                    :value="coach.id"
                                    :key="coach.id"
                                    >{{ coach.name }}</md-option
                                >
                            </md-select>
                        </md-field>
                    </div>

                    <!-- Schwierigkeit Filter -->
                    <div class="md-layout-item">
                        <md-field>
                            <label for="movies">Schwierigkeit</label>
                            <md-select
                                v-model="filter.difficultyID"
                                name="difficultFilter"
                                id="difficultFilter"
                                multiple
                            >
                                <md-option
                                    v-for="difficulty in difficulties"
                                    :value="difficulty.id"
                                    :key="difficulty.id"
                                    >{{ difficulty.name }}</md-option
                                >
                            </md-select>
                        </md-field>
                    </div>

                    <!-- Kategorie Filter -->
                    <div class="md-layout-item">
                        <md-field>
                            <label for="movies">Kategorie</label>
                            <md-select
                                v-model="filter.directory_id"
                                name="difficultFilter"
                                id="difficultFilter"
                                multiple
                            >
                                <md-option
                                    v-for="directory in directories"
                                    :value="directory.id"
                                    :key="directory.id"
                                    >{{ directory.name }}</md-option
                                >
                            </md-select>
                        </md-field>
                    </div>

                    <!-- Inhalt Typ Filter -->
                    <div class="md-layout-item">
                        <md-field>
                            <label for="movies">Inhalt Typ</label>
                            <md-select
                                v-model="filter.content"
                                name="contentFilter"
                                id="contentFilter"
                                multiple
                            >
                                <md-option :value="'video'">Video</md-option>
                                <md-option :value="'audio'">Audio</md-option>
                            </md-select>
                        </md-field>
                    </div>
                    <div
                        class="md-layout-item md-size-50"
                        style="margin-top: 15px"
                    >
                        <label for="lengthFilter" class="form-label"
                            >Dauer (Max-Dauer:
                            {{ minmax.maxtime }} Minuten)</label
                        >
                        <input
                            id="lengthFilter"
                            v-model="filter.time.stop"
                            type="range"
                            class="form-control-range"
                            :min="minmax.mintime"
                            :max="minmax.maxtime"
                        />
                        <p v-if="filter.time.stop > 0">
                            Maximal erlaubte Zeit:
                            {{ filter.time.stop }} Minute(n)
                        </p>
                    </div>

                    <div
                        class="md-layout-item md-size-100"
                        style="margin-top: 15px"
                    >
                        <md-button class="md-success" @click="applyFilters">
                            <md-icon>filter_list</md-icon>
                            Filter anwenden
                        </md-button>
                        <md-button
                            class="md-success"
                            @click="filtering = false"
                        >
                            <md-icon>filter_list</md-icon>
                            Filter zurücksetzen
                        </md-button>
                    </div>
                </div>
            </div>
            <div
                class="wrapper md-layout-item md-size-80 md-layout mx-auto text-left"
            >
                <div
                    v-if="filtering"
                    style="
                        display: flex;
                        flex-wrap: wrap;
                        align-content: flex-start;
                    "
                    class="md-layout md-gutter md-alignment-top-left mx-auto"
                >
                    <CardComponent
                        v-for="mediaEntry in filterResults"
                        :key="mediaEntry.id"
                        @favoriteAction="favoriteAction"
                        :directoryID="mediaEntry.directory_id"
                        :mediaEntry="mediaEntry"
                        class="md-layout-item md-xlarge-size-25 md-large-size-33 md-medium-size-50 md-small-size-100 md-xsmall-size-100"
                    ></CardComponent>
                </div>
                <div
                    v-if="!filtering"
                    style="
                        display: flex;
                        flex-wrap: wrap;
                        align-content: flex-start;
                    "
                    class="md-layout md-gutter md-alignment-top-left mx-auto"
                >
                    <CardCategoryComponent
                        v-for="category in categories"
                        :key="category.id"
                        :category="category"
                        class="md-layout-item md-xlarge-size-25 md-large-size-33 md-medium-size-50 md-small-size-100 md-xsmall-size-100"
                    ></CardCategoryComponent>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import request from "@/utils/request";
import { useExerciseStore } from "@/stores/ExerciseStore";
import { useCategoryStore } from "@/stores/CategoryStore";
import CardComponent from "@/pages/Dashboard/Components/CardComponent";
import CardCategoryComponent from "@/pages/Dashboard/Components/CardCategoryComponent";

export default {
    components: {
        CardComponent,
        CardCategoryComponent
    },
    data() {
        return {
            search: "",
            categories: [],
            searchResult: "",
            filter: {
                userID: [],
                difficultyID: [],
                directory_id: [],
                time: {
                    start: 0,
                    stop: 0
                }
            },
            presentation: "simple",
            coaches: [],
            directories: [],
            difficulties: [],
            filterResults: [],
            filtering: false,
            displayFilters: false,
            minmax: [],
            searchLockSVG:
                '<svg style="height: 18px; margin-bottom: 5px;   display: inherit;" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="lock" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M400 224h-24v-72C376 68.2 307.8 0 224 0S72 68.2 72 152v72H48c-26.5 0-48 21.5-48 48v192c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V272c0-26.5-21.5-48-48-48zm-104 0H152v-72c0-39.7 32.3-72 72-72s72 32.3 72 72v72z"></path></svg>'
        };
    },
    mounted() {
        this.getCategories();
        this.getCoaches();
        this.getDifficulties();
        this.getDirectories();
        this.getMinMax();
    },
    methods: {
        getMinMax() {
            request.get("/api/media/data/minmax").then((response) => {
                this.minmax = [...response.data];
                this.minmax = this.minmax[0];
                this.filter.time.stop = this.minmax.maxtime;
            });
        },
        getCategories() {
            request.get("/api/directory/root").then((response) => {
                this.categories = [...response.data];
            });
        },
        getCoaches() {
            request.get("/api/user/coach").then((response) => {
                this.coaches = [...response.data];
            });
        },
        getDifficulties() {
            request.get("/api/media/difficulty").then((response) => {
                this.difficulties = [...response.data];
            });
        },
        getDirectories() {
            request.get("/api/directory").then((response) => {
                this.directories = [...response.data];
            });
        },
        runSearch() {
            if (this.search.length > 0) {
                request
                    .get("/api/media/search/" + this.search)
                    .then((response) => {
                        this.searchResult = response.data;
                    });
            } else {
                this.searchResult = [];
            }
        },
        applyFilters() {
            this.filtering = true;
            request
                .post("/api/filter/media", {
                    filters: this.filter,
                    presentation: this.presentation
                })
                .then((response) => {
                    this.filterResults = response.data;
                });
        },
        compileMessage(result) {
            if (result.matching_tiers.length > 1) {
                var sub_string = "";
                result.matching_tiers.forEach((tier) => {
                    sub_string = sub_string + (tier.name + ", ");
                });
                sub_string = sub_string.trim();
                sub_string = sub_string.substring(0, sub_string.length - 1);
                if (result.matching_tiers.length > 1) {
                    var lastIndex = sub_string.lastIndexOf(",");
                    sub_string =
                        sub_string.substr(0, lastIndex) +
                        " und " +
                        sub_string.substr(lastIndex + 1);
                }
                return (
                    this.searchLockSVG +
                    " " +
                    result.name +
                    " Zugänglich mit Abos: " +
                    sub_string
                );
            } else {
                return (
                    this.searchLockSVG +
                    " " +
                    result.name +
                    " Zugänglich mit Abo: " +
                    result.matching_tiers[0].name
                );
            }
        }
    }
};
</script>
<style>
.wrapper {
    height: initial !important;
}
</style>
<style lang="scss" scoped>
.search-wrapper {
    position: relative;
    background: transparent !important;
    .search-results {
        padding-top: 6px;
        background-color: #fff !important;
        position: absolute;
        text-align: left;
        width: 100%;
        top: 0;
        right: 0;
        z-index: 7;
    }
}

.wrapper {
    position: relative;
    background: transparent !important;
    .search-results {
        padding-top: 6px;
        background-color: #fff !important;
        position: absolute;
        text-align: left;
        width: 100%;
        top: 0;
        right: 0;
        z-index: 7;
    }
}
</style>

import { defineStore } from 'pinia'
import request from '@/utils/request'

// useStore could be anything like useUser, useCart
// the first argument is a unique id of the store across your application
export const useMemberStore = defineStore('memberStore', {
    state: () => ({
        users: []
    }),
    actions: {
        async getMembers() {
            await request.get('/api/user').then(response => {
                this.users = response.data
            })
        },
        async updateMember(userID, user) {
            await request.put('/api/user/' + userID, user)
        },
        async deleteMember(userID) {
            await request.delete('/api/user/' + userID)
        }
    }
  // other options...
})
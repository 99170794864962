<template>
  <div class="md-layout">
    <div
      style="cursor: pointer; vertical-align: middle; padding-left: 0px"
      class="md-layout-item md-size-100"
    >
      <img
        v-if="coachIMG != ''"
        @click="navigateToCoachProfile(mediaObject.userID)"
        :src="coachIMG"
        style="
          max-height: 3rem;
          border-radius: 100px;
          width: 3rem;
          margin-right: 15px;
        "
      />
      <img
        v-if="!coachIMG"
        @click="navigateToCoachProfile(mediaObject.userID)"
        :src="avatar"
        alt="..."
        style="
          max-height: 3rem;
          border-radius: 100px;
          width: 3rem;
          margin-right: 15px;
        "
      />
      <i
        v-if="!isFavorite"
        @click="favorite($event, mediaObject.id)"
        style="vertical-align: middle; margin-right: 15px"
      >
        <font-awesome-icon
          class="favorite-position favorite"
          size="2x"
          icon="fa-regular fa-star"
        />
      </i>
      <i
        v-if="isFavorite"
        @click="unfavorite($event, mediaObject.id, mediaObject.favorite.id)"
        style="vertical-align: middle; margin-right: 15px"
      >
        <font-awesome-icon
          class="favorite-position current-favorite"
          size="2x"
          icon="fa-solid fa-star"
        />
      </i>
      <span @click="navigateToCoachProfile(mediaObject.userID)">
        {{ coachName }} - {{ coachSlogan }}
      </span>
      <hr />
    </div>
  </div>
</template>

<script>
import { userStore } from "@/stores/UserStore";
import request from "@/utils/request";

export default {
  props: ["mediaObject"],
  setup() {
    const memberStore = userStore();
    return { memberStore };
  },
  mounted() {
    this.loadCoach();
    this.avatar = require("@/assets/img/faces/avatar.png");
  },
  data() {
    return {
      coachName: "",
      coachSlogan: "",
      coachIMG: "",
      coachID: 0,
      isFavorite: this.mediaObject.favorite,
      avatar: "",
    };
  },
  methods: {
    loadCoach() {
      this.memberStore.loadCoach(this.mediaObject.userID).then((response) => {
        this.coachName = response.data.name;
        this.coachSlogan = response.data.slogan;
        this.coachIMG = response.data.img;
      });
    },
    favorite(e, mediaID) {
      e.preventDefault();
      request
        .post("/api/favorite", {
          id: mediaID,
          type: "exercise",
        })
        .then((response) => {
          this.$emit("favorite", true);
          this.isFavorite = true;
        });
    },
    unfavorite(e, mediaID, favoriteID) {
      e.preventDefault();
      request
        .put("/api/favorite/" + favoriteID, {
          id: favoriteID,
        })
        .then((response) => {
          this.$emit("favorite", false);
          this.isFavorite = false;
        });
    },
    navigateToCoachProfile(coachID) {
      window.open("/coaches/" + coachID);
    },
  },
};
</script>

<style lang="scss" scoped>
.favorite-position {
  z-index: 1 !important;
  cursor: pointer;
}

.favorite {
  &:hover {
    transform: scale(1.2);
    color: yellow;
  }

  &:focus {
    color: yellow;
  }
}

.favorite:focus {
  color: yellow;
}

.current-favorite {
  color: yellow !important;
}
</style>

import axios from "axios";
import VueCookies from "vue-cookies";
import { userStore } from "@/stores/UserStore";
import Swal from "sweetalert2";
import router from "@/utils/router";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import { createToastInterface } from "vue-toastification";
import Vue from "vue";

const service = axios.create({
  baseURL: process.env.VUE_APP_API_ENDPOINT,
  headers: {},
  withCredentials: true,
  // ...other options
});

const domDecoder = (str) => {
  let parser = new DOMParser();
  let dom = parser.parseFromString("<!doctype html><body>" + str, "text/html");
  return dom.body.textContent;
};

// Function that will be called to refresh authorization
const refreshAuthLogic = (failedRequest) =>
  service.get("/sanctum/csrf-cookie").then((response) => {
    service.defaults.headers.common["X-XSRF-TOKEN"] =
      VueCookies.get("XSRF-Token");
    failedRequest.response.config.headers["X-XSRF-TOKEN"] =
      VueCookies.get("XSRF-Token");
  });

// Instantiate the interceptor
createAuthRefreshInterceptor(axios, refreshAuthLogic, {
  statusCodes: [401, 419], // default: [ 401 ]
});

// request interceptor
service.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    if (config.url == "/sanctum/csrf-cookie") {
      return config;
    }
    // Do something before request is sent
    // Add XSRF Token if not present
    if (!config.headers.hasOwnProperty("X-XSRF-TOKEN")) {
      // Check if Token is stored somewhere
      if (VueCookies.isKey("XSRF-TOKEN")) {
        config.headers["X-XSRF-TOKEN"] = VueCookies.get("XSRF-TOKEN");
      } else {
        service.get("/sanctum/csrf-cookie").then((response) => {
          service.defaults.headers.common["X-XSRF-TOKEN"] =
            VueCookies.get("XSRF-Token");
          config.headers["X-XSRF-TOKEN"] = VueCookies.get("XSRF-Token");
        });
      }
    }
    // Add the header to the already running request

    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    // Create the interface
    const toast = createToastInterface();
    if (["post", "put", "patch", "delete"].includes(response.config.method)) {
      if (response.data.message) {
        toast.success(domDecoder(response.data.message));
      } else {
        if (
          !response.config.url.includes("/track") &&
          !response.config.url.includes("filter/course") &&
          !response.config.url.includes("filter/media")
        ) {
          toast.success("Erfolgreich");
        }
      }
    }

    return response;
  },
  function (error) {
    const toast = createToastInterface();
    const {
      config,
      response: { status },
    } = error;
    const originalRequest = config;
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.response.status === 401) {
      // Initialize user store to check for login
      const store = userStore();
      router.push("/login");
    } else if (error.response.status === 419) {
      const store = userStore();
      store.logout();
    } else if (error.response.status === 422) {
      var errorString = "";
      if(error.response.data.message == "") {
        for (const k in error.response.data.errors) {
          errorString += error.response.data.errors[k] + "\n";
        }
      } else {
        errorString = error.response.data.message
      }
      if (errorString != "") {
        toast.error(domDecoder(errorString));
      } else {
        toast.error("Etwas ist schiefgelaufen");
      }
    } else if (error.response.status === 500) {
      if (error.response.data.message) {
        toast.error(domDecoder(error.response.data.message));
      } else {
        toast.error("Etwas ist schiefgelaufen");
      }
    } else {
      if (error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Etwas ist schiefgelaufen");
      }
    }
    return Promise.reject(error);
  }
);

export default service;

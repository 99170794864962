<template>
  <div>
    <md-dialog @md-clicked-outside="closeDialog" :md-active.sync="showDialog">
      <md-dialog-title>{{ item.name }}</md-dialog-title>

      <div class="md-layout">
        <md-field class="md-layout-item md-size-50 md-form-group">
          <label>Name</label>
          <md-input typeID="content" v-model="course.name"></md-input>
        </md-field>
        <md-field class="md-layout-item md-size-50 md-form-group">
          <label>Kurstext</label>
          <md-input typeID="content" v-model="course.message"></md-input>
        </md-field>
        <div class="md-layout-item md-size-50 md-form-group">
          <md-checkbox typeID="content" v-model="course.draft"
            >Entwurf</md-checkbox
          >
        </div>
        <md-field class="md-layout-item md-size-50">
          <label>Kursbanner</label>
          <md-file @change="handleFileInput" />
        </md-field>
        <md-field class="md-layout-item md-size-50 md-form-group">
          <label>Typ</label>
          <md-select typeID="content" v-model="course.directory_id">
            <md-option
              v-for="category in categories"
              :key="category.id"
              :value="category.id"
              >{{ category.name }}</md-option
            >
          </md-select>
        </md-field>
        <md-field class="md-layout-item md-size-50 md-form-group">
          <label>Schwierigkeit</label>
          <md-select typeID="content" v-model="course.difficultyID">
            <md-option
              v-for="difficulty in difficulties"
              :key="difficulty.id"
              :value="difficulty.id"
              >{{ difficulty.name }}</md-option
            >
          </md-select>
        </md-field>
      </div>

      <md-dialog-actions>
        <md-button class="md-success" @click="closeDialog">Close</md-button>
        <md-button class="md-success" @click="updateCourse"
          >Speichern</md-button
        >
      </md-dialog-actions>
    </md-dialog>
  </div>
</template>

<script>
import { useCourseStore } from "@/stores/CourseStore";
import { useDifficultyStore } from "@/stores/DifficultyStore";
import { useCategoryStore } from "@/stores/CategoryStore";

export default {
  components: {},
  props: ["item", "showDialog"],
  setup() {
    const courseStore = useCourseStore();
    const difficultyStore = useDifficultyStore();
    const categoryStore = useCategoryStore();
    return {
      courseStore,
      difficultyStore,
      categoryStore,
    };
  },
  data() {
    return {
      showEditCOntentDialog: false,
      course: this.item,
      categories: this.categoryStore.categories,
      difficulties: this.difficultyStore.difficulties,
      course_media: [],
    };
  },
  beforeMount() {
    this.course = Object.assign({}, this.item);
  },
  created() {},
  methods: {
    closeDialog() {
      this.$emit("closeDialog", false);
    },
    handleFileInput(event) {
      const file = event.target.files[0];
      this.course.banner = file;
    },
    updateCourse() {
      this.course.directoryID = this.course.directory_id;
      // New Form Data
      const formData = new FormData();
      formData.append("id", this.course.id);
      formData.append("name", this.course.name);
      formData.append("directoryID", this.course.directoryID);
      formData.append("difficultyID", this.course.difficultyID);
      formData.append("banner", this.course.banner);
      formData.append("message", this.course.message);
      formData.append("draft", this.course.draft);
      // Update Course
      this.courseStore.updateCourse(this.course.id, formData);
      this.$emit("closeDialog", true);
    },
  },
};
</script>
<style scoped>
.md-field.md-form-group:before,
.md-field.md-form-group:after {
  width: 100% !important;
}
</style>

<template>
  <div class="md-layout">
    <md-card class="md-layout-item md-size-80 mx-auto">
      <div class="md-layout">
        <div class="md-layout-item md-size-50">
          <h4>{{ article.title }} bearbeiten</h4>
        </div>
        <div class="md-layout-item md-size-50 text-right">
          <md-button @click="saveArticle" class="md-success"
            ><md-icon>save</md-icon></md-button
          >
        </div>
      </div>
    </md-card>
    <md-card class="md-layout-item md-size-80 mx-auto">
      <div class="md-layout" style="margin-bottom: 1rem">
        <div class="md-layout-item md-size-50">
          <h4>Metadaten bearbeiten</h4>
        </div>
        <div class="md-layout-item md-size-50 text-right">
          <md-button class="md-success" @click="editMeta = !editMeta">
            <md-icon v-if="!editMeta" style="cursor: pointer">edit</md-icon>
            <md-icon v-if="editMeta" style="cursor: pointer">check</md-icon>
          </md-button>
        </div>
        <div style="margin-bottom: 1rem" class="md-layout-item md-size-100">
          <md-divider></md-divider>
        </div>
        <div class="md-layout-item md-size-100 md-layout">
          <div class="md-layout-item md-size-25">
            <b>Titel</b>
          </div>
          <div v-if="!editMeta" class="md-layout-item md-size-25">
            <h3>{{ article.title }}</h3>
          </div>
          <div v-if="editMeta" class="md-layout-item md-size-25">
            <md-field class="md-form-group">
              <label>Titel</label>
              <md-input v-model="article.title"></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-size-25">
            <b>Typ</b>
          </div>
          <div v-if="!editMeta" class="md-layout-item md-size-25">
            <p>
              {{ types.filter((type) => type.id === article.typeID)[0].name }}
            </p>
          </div>
          <div v-if="editMeta" class="md-layout-item md-size-25">
            <md-field class="md-form-group">
              <md-select v-model="article.typeID">
                <md-option
                  v-for="articleType in types"
                  :value="articleType.id"
                  :key="articleType.id"
                  >{{ articleType.name }}</md-option
                >
              </md-select>
            </md-field>
          </div>
        </div>
        <div style="margin-top: 1rem" class="md-layout">
          <div class="md-layout-item md-size-25">
            <b>Vorschautext</b>
          </div>
          <div class="md-layout-item md-size-25">
            <div
              v-if="!editMeta"
              class="md-layout-item md-layout-size-100"
              v-html="article.preview"
            ></div>
            <div v-if="editMeta" class="md-layout-item md-layout-size-100">
              <VueEditor
                style="margin-bottom: 4rem"
                class="md-layout-item md-size-100"
                v-model="article.preview"
                :editor-config="previewEditorConfig"
                :editor-toolbar="previewEditorConfig.toolbar"
              ></VueEditor>
            </div>
          </div>
          <div class="md-layout-item md-size-25">
            <b>Artikelbild</b>
          </div>
          <div v-if="editMeta" class="md-layout-item md-size-25">
            <md-field>
              <md-file @change="handleFileInput" />
            </md-field>
          </div>
          <div
            v-if="!editMeta && fileUploaded"
            class="md-layout-item md-size-25"
          >
            <p>Upload wird nach Speichern sichtbar</p>
          </div>
          <div
            v-if="!editMeta && !fileUploaded"
            class="md-layout-item md-size-25"
          >
            <img style="width: 15rem; height: 7.5rem" :src="article.img" />
          </div>
          <div class="md-layout-item md-size-25">
            <b>Entwurf</b>
          </div>
          <div v-if="!editMeta" class="md-layout-item md-size-25">
            <p v-if="article.draft">Ja</p>
            <p v-if="!article.draft">Nein</p>
          </div>
          <div v-if="editMeta" class="md-layout-item md-size-25">
            <md-checkbox typeID="content" v-model="article.draft"></md-checkbox>
          </div>
        </div>
      </div>
    </md-card>
    <md-card class="md-layout-item md-size-80 mx-auto">
      <div class="md-layout" style="margin-top: 1rem; padding: 0px 10px">
        <div class="md-layout-item md-size-50">
          <h3>Inhalt</h3>
        </div>
        <div class="md-layout-item md-size-50 text-right">
          <md-button class="md-success" @click="togglePreview">
            <md-icon v-if="!editContent" style="cursor: pointer">edit</md-icon>
            <md-icon v-if="editContent" style="cursor: pointer">check</md-icon>
          </md-button>
        </div>
        <div class="md-layout-item md-size-100">
          <md-divider></md-divider>
        </div>
        <div
          v-if="editContent"
          class="md-layout-item md-size-100 text-center"
          style="margin-top: 10px; max-height: 35rem; overflow-y:scroll;"
        >
          <VueEditor
            style="margin-bottom: 4rem"
            v-model="article.content"
            :editor-config="editorConfig"
            :editor-toolbar="editorConfig.toolbar"
          ></VueEditor>
        </div>
        <div v-if="!editContent" v-html="article.content" class="ql-editor ql-editor-view md-layout-item md-size-100"></div>
      </div>
    </md-card>
  </div>
</template>

<script>
import { useNewsfeedStore } from "@/stores/NewsfeedStore";
import { useArticleTypeStore } from "@/stores/ArticleTypeStore";
import { VueEditor } from "vue2-editor";
import { QuillDeltaToHtmlConverter } from "quill-delta-to-html";

export default {
  name: "Article",
  components: {
    VueEditor,
  },
  setup() {
    const newsFeedStore = useNewsfeedStore();
    const articleTypeStore = useArticleTypeStore();
    return {
      newsFeedStore,
      articleTypeStore,
    };
  },
  data() {
    return {
      article: [],
      editMeta: false,
      editContent: false,
      fileUploaded: false,
      types: [],
      editorConfig: {
        toolbar: [
          ["bold", "italic", "underline", "strike"],
          ["blockquote", "code-block"],
          [{ header: 1 }, { header: 2 }],
          [{ list: "ordered" }, { list: "bullet" }],
          [{ script: "sub" }, { script: "super" }],
          [{ indent: "-1" }, { indent: "+1" }],
          [{ direction: "rtl" }],
          [{ size: ["small", false, "large", "huge"] }],
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          [{ color: [] }, { background: [] }],
          [{ font: [] }],
          [{ align: [] }],
          ["clean"],
          ["link", "image", "video"],
        ],
        video: {
          tag: "iframe",
          attributes: {
            style: "width: 100%",
            frameborder: "0",
            allowfullscreen: "",
          },
        },
      },
      previewEditorConfig: {
        toolbar: [["clean"]],
      },
    };
  },
  beforeMount() {
    this.newsFeedStore.getArticle(this.$route.params.id).then(() => {
      this.article = this.newsFeedStore.article;
      if (this.article.content.includes('{"ops":[')) {
        var rawDelta = JSON.parse(this.article.content);
        let cfg = {};
        let converter = new QuillDeltaToHtmlConverter(rawDelta.ops, cfg);
        this.article.content = converter.convert();
      }
    });
    this.articleTypeStore.getTypes().then((response) => {
      this.types = this.articleTypeStore.types;
    });
  },
  methods: {
    handleFileInput(event) {
      const file = event.target.files[0];
      this.article.img = file;
      this.fileUploaded = true;
    },
    togglePreview() {
      this.editContent = !this.editContent;
    },
    editArticle(article) {
      this.$emit("editArticle", article);
    },
    deleteArticle() {
      if (
        confirm("Sind Sie sicher, dass Sie diesen Artikel löschen möchten?")
      ) {
        this.newsFeedStore.deleteArticle(this.article.id);
      }
    },
    saveArticle() {
      // Create Formdata
      let formData = new FormData();
      formData.append("name", this.article.title);
      formData.append("preview", this.article.preview);
      formData.append("content", this.article.content);
      formData.append("image", this.article.img);
      formData.append("id", this.article.id);
      formData.append("draft", this.article.draft);
      formData.append("typeID", this.article.typeID);
      // Send to Server
      this.fileUploaded = false;
      this.newsFeedStore.updateArticle(this.article.id, formData).then(() => {
        this.newsFeedStore.getArticle(this.article.id).then(() => {
          this.article = this.newsFeedStore.article;
        });
      });
    },
  },
};
</script>

<style scoped>
.ql-editor {
  max-height: 30rem;
  overflow: scroll;
}
</style>

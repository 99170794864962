<template>
  <div style="margin-top: 30px" class="text-center md-layout">
    <h3
      class="md-layout-item md-size-20 md-small-size-100 md-small-size-100 md-medium-size-100"
    >
      {{ file.name.replace(/(\.\w+)+$/, "") }}
    </h3>
    <audio
      class="md-layout-item md-size-60 md-small-size-100 mx-auto"
      :key="file.id"
      style="height: 55px"
      controls
      controlsList="nodownload"
      autostart="0"
    >
      <source :src="file.path" :type="'audio/' + file.extension" />
      Ihr Browser unterstütz keine Audiodateien
    </audio>
  </div>
</template>

<script>
export default {
  props: {
    file: Object,
  },
};
</script>

<style></style>

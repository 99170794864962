<template>
    <div class="md-layout">
        <md-card class="md-layout-item text-center">
            <md-card-content>
                <div v-if="!validated" class="md-layout-item">
                    <h4>Prüfung im Gange....</h4>
                </div>
                <div v-if="validated" class="md-layout-item">
                    <h4>Alles erledigt, viel Spass mit Ihrem neuen Abo!</h4>
                    <md-button class="md-success" @click="$router.push('/courses')">Loslegen</md-button>
                </div>
            </md-card-content>
        </md-card>
    </div>
</template>

<script>

import { useSubscriptionStore } from '@/stores/SubscriptionStore'

export default {
  components: {
  },
  props: ['id'],
  setup() {
    const subscriptionStore = useSubscriptionStore();
    return {
     subscriptionStore
    };
  },
  data() {
    return {
        validated: false,
    };
  },
  beforeMount() {
    this.subscriptionStore.validateCheckout(this.$route.params.id).then(response => {
        if(response.data.check) {
            this.validated = true
        }
    })
  },
  mounted() {
  },
}
</script>

<style>

</style>
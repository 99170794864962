<template>
  <div class="md-layout text-center">
    <div class="md-layout-item md-size-90 mx-auto md-card md-small-size-100">
      <div class="md-layout">
        <div class="md-layout-item md-size-100 md-small-size-100">
          <h2>
            {{ news.title }}
          </h2>
          <p>{{ news.created_at }}</p>
          <div v-html="html" class="content ql-editor ql-editor-view"></div>
        </div>
      </div>
    </div>
  </div>
</template>



<script>
import { QuillDeltaToHtmlConverter } from "quill-delta-to-html";
import request from "@/utils/request";
export default {
  components: {
    // TestimonialCard
  },
  props: {
    //newsID: Number
  },
  data() {
    return {
      news: [],
      html: "",
    };
  },

  mounted() {
    this.newsID = this.$route.params.newsID;
    request.get("/api/newsfeed/" + this.newsID).then((response) => {
      var data = response.data;
      this.news = data;
      if (this.news.content.includes('{"ops":[')) {
        var rawDelta = JSON.parse(this.news.content);
        let cfg = {};
        let converter = new QuillDeltaToHtmlConverter(rawDelta.ops, cfg);
        this.html = converter.convert();
      } else {
        this.html = this.news.content;
      }
    });
  },
};
</script>

<style scoped>
h2 {
  margin-bottom: 0px;
}
p {
  margin-bottom: 0px;
}

.arrow-back {
  margin-top: 30px;
  text-align: right;
}
.arrow-back .material-icons {
  font-size: 36px;
  line-height: 54px;
  color: #007bff;
}
.card-block {
  padding: 50px;
  background-color: rgb(230, 230, 230);
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.04), 0 41.8px 33.4px rgba(0, 0, 0, 0.05),
    0 100px 80px rgba(0, 0, 0, 0.02);
}
.content ul li {
  list-style: disc outside none;
  display: list-item;
  margin-left: 1em;
}
.content ol li {
  list-style: decimal outside none;
  display: list-item;
  margin-left: 1em;
}
</style>

<template>
    <div class="md-layout md-gutter md-alignment-top-left mx-auto">
        <CardCategoryComponent
            class="md-layout-item md-xlarge-size-20 md-large-size-33 md-medium-size-50 md-small-size-100 md-xsmall-size-100"
            v-for="child in children"
            :key="child.id"
            :category="child"
        ></CardCategoryComponent>
        <CardComponent
            v-for="mediaEntry in media"
            :key="mediaEntry.id"
            class="md-layout-item md-xlarge-size-20 md-large-size-33 md-medium-size-50 md-small-size-100 md-xsmall-size-100"
            @favoriteAction="favoriteAction"
            :directoryID="categoryID"
            :mediaEntry="mediaEntry"
        ></CardComponent>
    </div>
</template>

<script>
import CardComponent from "@/pages/Dashboard/Components/CardComponent";
import CardCategoryComponent from "@/pages/Dashboard/Components/CardCategoryComponent";
import request from "@/utils/request";

export default {
    components: {
        CardComponent,
        CardCategoryComponent
    },
    props: {
        category: String,
        categoryID: Number
    },
    data() {
        return {
            children: [],
            media: []
        };
    },
    mounted() {
        this.loadCategories();
    },
    methods: {
        loadCategories() {
            let categoryID = this.$route.params.categoryID;
            request
                .get("/api/directory/child/" + categoryID)
                .then((response) => {
                    var data = response.data;
                    this.children = data.children;
                    this.media = data.media;
                });
        },
        favorite(mediaID) {
            request
                .post("/api/favorite", {
                    id: mediaID,
                    type: "exercise"
                })
                .then((response) => {
                    this.media.find(
                        (element) => element.id === mediaID
                    ).favorite = true;
                });
        },
        unfavorite(mediaID, favoriteID) {
            request
                .put("/api/favorite/" + favoriteID, {
                    id: favoriteID
                })
                .then((response) => {
                    this.media.find(
                        (element) => element.id === mediaID
                    ).favorite = false;
                });
        },
        favoriteAction(itemID, type, favoriteID) {
            if (type) {
                this.favorite(itemID);
            } else {
                this.unfavorite(itemID, favoriteID);
            }
        }
    },
    watch: {
        "$route.params.categoryID": function () {
            this.loadCategories();
        }
    }
};
</script>

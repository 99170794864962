<template>
  <div>
    <!-- <div v-if="mutableMediaEntry.unlocked" class="accessable" :href="route('media.article', {directory: directoryID, id: mutableMediaEntry.id})"> -->
    <div
      v-if="mutableMediaEntry.unlocked"
      class="accessable"
      @click="$router.push('/exercises/exercise/' + mutableMediaEntry.id)"
    >
      <div class="card-wrap" ref="card">
        <div
          style="position: relative"
          class="card"
          :style="cardStyle"
          :class="{ highlight: mutableMediaEntry.message }"
        >
          <div class="card-message">
            <span
              style="float: left"
              v-if="mutableMediaEntry.message != 'null'"
              >{{ mutableMediaEntry.message }}</span
            >
            <i
              v-if="!mutableMediaEntry.favorite"
              @click.stop="favorite($event, mutableMediaEntry.id)"
              ><font-awesome-icon
                class="favorite-position favorite"
                size="lg"
                icon="fa-regular fa-star"
            /></i>
            <i
              v-if="mutableMediaEntry.favorite"
              @click.stop="
                unfavorite(
                  $event,
                  mutableMediaEntry.id,
                  mutableMediaEntry.favorite.id
                )
              "
              ><font-awesome-icon
                class="favorite-position current-favorite"
                size="lg"
                icon="fa-solid fa-star"
            /></i>
          </div>
          <div
            class="card-bg"
            :style="'background-image: url(' + mutableMediaEntry.img + ');'"
          ></div>
          <div class="card-info">
            <span>{{ mutableMediaEntry.name }}</span>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!mutableMediaEntry.unlocked" class="card-wrap blocked">
      <div
        style="position: relative"
        class="card"
        :style="cardStyle"
        :class="{ highlight: mutableMediaEntry.message }"
      >
        <div v-if="!mutableMediaEntry.unlocked" class="blocked-content">
          <span v-html="cardLockSVG"></span>
          <p style="position:center !important; !important;">
            {{ subscription_message }} <span v-html="matching_tiers"></span>
          </p>
        </div>
        <div class="card-message">
          <span
            style="float: left"
            v-if="mutableMediaEntry.message != 'null'"
            >{{ mutableMediaEntry.message }}</span
          >
          <i
            @click="favorite($event, mutableMediaEntry.id)"
            v-if="!mutableMediaEntry.favorite"
            class="favorite-position favorite far fa-lg fa-star"
          ></i>
          <i
            @click="
              unfavorite(
                $event,
                mutableMediaEntry.id,
                mutableMediaEntry.favorite.id
              )
            "
            v-if="mutableMediaEntry.favorite"
            class="favorite-position current-favorite fa-lg fas fa-star"
          ></i>
        </div>
        <div
          class="card-bg"
          :style="'background-image: url(' + mutableMediaEntry.img + ');'"
        ></div>
        <div class="card-info">
          <span>{{ mutableMediaEntry.name }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";

export default {
  props: ["mediaEntry", "directoryID"],
  data() {
    return {
      children: [],
      media: [],
      cardLockSVG:
        '<svg style="height: 50px;margin: 50 auto 10 auto;" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="lock" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M400 224h-24v-72C376 68.2 307.8 0 224 0S72 68.2 72 152v72H48c-26.5 0-48 21.5-48 48v192c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V272c0-26.5-21.5-48-48-48zm-104 0H152v-72c0-39.7 32.3-72 72-72s72 32.3 72 72v72z"></path></svg>',
      width: 0,
      height: 0,
      mouseX: 0,
      mouseY: 0,
      mouseLeaveDelay: null,
      matching_tiers: "",
      subscription_message: "",
      mutableMediaEntry: null,
    };
  },
  created() {
    this.mutableMediaEntry = this.mediaEntry;
  },
  mounted() {
    if (
      !this.mutableMediaEntry.unlocked &&
      this.mutableMediaEntry.matching_tiers.length > 0
    ) {
      if (this.mutableMediaEntry.matching_tiers.length == 1) {
        this.subscription_message = "Zugänglich mit Abo:";
      } else {
        this.subscription_message = "Zugänglich mit Abos:";
      }
      this.mutableMediaEntry.matching_tiers.forEach((tier) => {
        this.matching_tiers = this.matching_tiers + (tier.name + ", ");
      });
      this.matching_tiers = this.matching_tiers.trim();
      this.matching_tiers = this.matching_tiers.substring(
        0,
        this.matching_tiers.length - 1
      );
      if (this.mutableMediaEntry.matching_tiers.length > 1) {
        var lastIndex = this.matching_tiers.lastIndexOf(",");
        this.matching_tiers =
          this.matching_tiers.substr(0, lastIndex) +
          " und " +
          this.matching_tiers.substr(lastIndex + 1);
      }
    }
  },
  computed: {
    mousePX() {
      return this.mouseX / this.width;
    },
    mousePY() {
      return this.mouseY / this.height;
    },
    cardStyle() {
      const rX = this.mousePX * 30;
      const rY = this.mousePY * -30;
      return {
        transform: `rotateY(${rX}deg) rotateX(${rY}deg)`,
      };
    },
    cardBgTransform() {
      const tX = this.mousePX * -40;
      const tY = this.mousePY * -40;
      return {
        transform: `translateX(${tX}px) translateY(${tY}px)`,
      };
    },
    cardBgImage() {
      return {
        backgroundImage: `url(${this.dataImage})`,
      };
    },
  },
  methods: {
    handleMouseMove(e) {
      this.mouseX = e.pageX - this.$refs.card.offsetLeft - this.width / 2;
      this.mouseY = e.pageY - this.$refs.card.offsetTop - this.height / 2;
    },
    handleMouseEnter() {
      clearTimeout(this.mouseLeaveDelay);
    },
    handleMouseLeave() {
      this.mouseLeaveDelay = setTimeout(() => {
        this.mouseX = 0;
        this.mouseY = 0;
      }, 1000);
    },
    favorite(e, mediaID) {
      request
        .post("/api/favorite", {
          id: mediaID,
          type: "exercise",
        })
        .then((response) => {
          this.mutableMediaEntry.favorite = response.data.favorite;
        });
    },
    unfavorite(e, mediaID, favoriteID) {
      request
        .put("/api/favorite/" + favoriteID, {
          id: favoriteID,
          type: "exercise",
        })
        .then((response) => {
          this.mutableMediaEntry.favorite = false;
        });
    },
  },
};
</script>

<style scoped>
.accessable {
  cursor: pointer !important;
  text-decoration: none !important;
  color: white !important;
  margin: 10px;
}

.blocked {
  cursor: not-allowed !important;
  text-decoration: none !important;
  color: white !important;
  margin: 10px;
}

.blocked-content {
  color: white;
  background: rgba(0, 0, 0, 0.5);
  background-size: cover;
  z-index: 999999 !important;
  height: 100% !important;
  width: 100% !important;
  position: absolute;
  text-align: center;
  border-radius: 5px;
}

.lockSVG {
  height: 50px;
  margin: 15px 15px;
}

.wrapper {
  background-color: rgb(230, 230, 230) !important;
  margin: 10px 0px 10px 0px;
  padding: 10px 0px 10px 15px;
  border-radius: 8px;
}

.link,
.link:hover,
.link:focus,
.link:active {
  text-decoration: none;
  color: inherit;
}
</style>

<style lang="scss" scoped>
$hoverEasing: cubic-bezier(0.23, 1, 0.32, 1);
$returnEasing: cubic-bezier(0.445, 0.05, 0.55, 0.95);

.favorite {
  &:hover {
    transform: scale(1.2);
    color: yellow;
  }

  &:focus {
    color: yellow;
  }
}

.accessable {
  .card-wrap {
    &:hover {
      .card-info p {
        opacity: 1;
      }

      .card-bg {
        transform: scale(1.2) !important;
      }

      .card {
        box-shadow: rgba(white, 0.2) 0 0 40px 5px, rgba(white, 1) 0 0 0 1px,
          rgba(black, 0.66) 0 30px 60px 0, inset #333 0 0 0 5px,
          inset white 0 0 0 6px;
      }
    }
  }
}

.card-message {
  padding-left: 15px;
  z-index: 1;
  text-decoration: none !important;
  position: absolute;
  top: 0.5rem;
  color: white !important;
  font-weight: bold;
  text-align: right;
  padding: 0 5px;
  width: 100%;

  i {
    text-align: right;
  }

  .favorite-position {
    position: absolute !important;
    top: 0.75rem !important;
    right: 0.5rem !important;
    z-index: 1 !important;
  }

  .favorite {
    color: yellow;

    &:hover {
      text-decoration: none !important;
      color: yellow !important;
    }

    &:focus {
      color: yellow;
    }
  }

  &:hover {
    text-decoration: none !important;
  }
}

.current-favorite {
  color: yellow;
}

body {
  margin: 40px 0;
  font-family: "Raleway";
  font-size: 14px;
  font-weight: 500;
  background-color: #bcaaa4;
  -webkit-font-smoothing: antialiased;
}

p {
  line-height: 1.5em;
}

h1 + p,
p + p {
  margin-top: 10px;
}

.card-wrap {
  transform: perspective(800px);
  transform-style: preserve-3d;
  cursor: pointer;

  .card-info {
    padding-top: 10px;
    padding-bottom: 30px;
  }
}

.highlight {
  border: 1px yellow solid;
}

.card {
  margin: 0 auto;
  flex: 0 0 240px;
  width: 16rem;
  height: 24rem;
  background-color: #333;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: rgba(black, 0.66) 0 30px 60px 0, inset #333 0 0 0 5px,
    inset rgba(white, 0.5) 0 0 0 6px;
  transition: 1s $returnEasing;

  .card-info {
    margin-bottom: 15px;
    font-size: 20px;
  }
}

.card-bg {
  opacity: 0.5;
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 20px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  transition: 1s $returnEasing, opacity 5s 1s $returnEasing;
  pointer-events: none;
  transition: 0.6s $hoverEasing, opacity 5s $hoverEasing;
  opacity: 0.8;
}

.card-info {
  padding: 20px;
  position: absolute;
  bottom: 0;
  color: #fff;
  transform: translateY(40%);
  transition: 0.6s 1.6s cubic-bezier(0.215, 0.61, 0.355, 1);

  p {
    opacity: 0;
    text-shadow: rgba(black, 1) 0 2px 3px;
    transition: 0.6s 1.6s cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  * {
    position: relative;
    z-index: 1;
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(
      to bottom,
      transparent 0%,
      rgba(#000, 0.6) 100%
    );
    background-blend-mode: overlay;
    opacity: 0;
    transition: 5s 1s $returnEasing;
  }
}

.card-info h1 {
  font-family: "Playfair Display";
  font-size: 36px;
  font-weight: 700;
  text-shadow: rgba(black, 0.5) 0 10px 10px;
}
</style>

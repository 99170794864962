<template>
  <div>
    <md-dialog :md-active.sync="showDialog">

    <md-dialog-title>{{ item.name }}</md-dialog-title>


      <div 
        class="
          md-layout-item
          md-size-100
          md-medium-size-100
          md-small-size-100
          md-xsmall-size-100
        "
      >

        <md-field class="md-form-group">
          <label>Name</label>
          <md-input typeID="content" v-model="difficulty.name"></md-input>
        </md-field>

      </div>

      <md-dialog-actions>
        <md-button class="md-success" @click="closeDialog">Close</md-button>
        <md-button class="md-success" @click="updateDifficulty">Speichern</md-button>
      </md-dialog-actions>
    </md-dialog>

  </div>
</template>

<script>

import { useDifficultyStore } from '@/stores/DifficultyStore';

export default {
    components: {
    },
    props: [
        'item',
        'showDialog'
    ],
    setup() {
        const difficultyStore = useDifficultyStore();
        return {
            difficultyStore,
        };
    },
    data() {
        return {
            difficulty: this.item
        }
    },
    beforeMount() {
        this.difficulty = Object.assign({}, this.item);
    },
    methods: {
        closeDialog() {
            this.$emit("closeDialog", false)
        },
        updateDifficulty() {
            this.difficultyStore.updateDifficulty(this.difficulty);
            this.$emit("closeDialog", true)
        }
    },
}
</script>
<style scoped>
  .md-field.md-form-group:after{
    width:100% !important;
  }
</style>

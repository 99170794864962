<template>
  <div class="text-center">
    <div class="md-layout md-gutter">
      <div
        class="md-layout-item md-gutter md-size-33 md-small-size-100 md-medium-size-100"
      >
        <div class="md-layout">
          <div class="md-layout-item md-size-100">
            <md-card>
              <div class="md-layout">
                <div class="md-layout-item md-size-50">
                  <h3>Informationen</h3>
                </div>
                <div class="md-layout-item md-size-50 text-right">
                  <md-button class="md-success" @click="editInfo = !editInfo">
                    <md-icon v-if="!editInfo" style="cursor: pointer"
                      >edit</md-icon
                    >
                    <md-icon v-if="editInfo" style="cursor: pointer"
                      >check</md-icon
                    >
                  </md-button>
                </div>
                <div
                  v-if="!editInfo"
                  class="md-layout-item md-size-100 text-center"
                >
                  <img :src="coachStore.coach.img" />
                  <h4>{{ coachStore.coach.name }}</h4>
                  <p>{{ coachStore.coach.slogan }}</p>

                  <h4>Homepage</h4>
                  <a
                    target="_blank"
                    :href="'https://' + coachStore.coach.homepage"
                    >{{ coachStore.coach.homepage }}</a
                  >
                </div>
                <div
                  v-if="editInfo"
                  class="md-layout-item md-size-100 text-center"
                >
                  <div
                    v-if="newProfilePictureSelected"
                    class="md-layout-item md-small-size-100 md-size-100"
                  >
                    <div class="md-layout">
                      <img
                        class="md-layout-item md-size-100"
                        :src="url"
                        style="width: 50%; height: 50%"
                      />
                      <md-button
                        @click="cancelProfilePictureUpdate()"
                        class="md-danger md-success md-size-50"
                        >Abbrechen</md-button
                      >
                      <md-button
                        @click="updateProfilePicture()"
                        class="md-success md-size-50"
                        >Profilbild aktualisieren</md-button
                      >
                    </div>
                  </div>
                  <md-field>
                    <label>Profilbild</label>
                    <md-file @change="handleProfileUpload" />
                  </md-field>
                  <md-field class="md-form-group">
                    <label>Name</label>
                    <md-input v-model="coach.name" />
                  </md-field>
                  <md-field class="md-form-group">
                    <label>Slogan</label>
                    <md-input v-model="coach.slogan" />
                  </md-field>
                  <md-field class="md-form-group">
                    <label>Homepage</label>
                    <md-input v-model="coach.homepage" />
                  </md-field>
                  <md-button class="md-success" @click="updateInfo()"
                    >Speichern</md-button
                  >
                </div>
              </div>
            </md-card>
          </div>
        </div>
      </div>
      <div class="md-layout-item md-gutter md-size-66 md-small-size-100">
        <div class="md-layout">
          <div class="md-layout-item md-size-100">
            <md-card>
              <div class="md-layout">
                <div class="md-layout-item md-size-50">
                  <h3>Beschreibung</h3>
                </div>
                <div class="md-layout-item md-size-50 text-right">
                  <md-button
                    class="md-success"
                    @click="editDescription = !editDescription"
                  >
                    <md-icon v-if="!editDescription" style="cursor: pointer"
                      >edit</md-icon
                    >
                    <md-icon v-if="editDescription" style="cursor: pointer"
                      >check</md-icon
                    >
                  </md-button>
                </div>
                <div
                  v-if="!editDescription"
                  v-html="coach.about"
                  class="md-layout-item md-size-100"
                ></div>
                <div class="md-layout-item md-size-100" v-if="editDescription">
                  <md-field class="md-form-group">
                    <md-textarea v-model="coach.about"> </md-textarea>
                  </md-field>
                </div>
                <div class="md-layout-item md-size-100 text-center">
                  <md-button
                    v-if="editDescription"
                    class="md-success"
                    @click="updateAbout()"
                    >Speichern</md-button
                  >
                </div>
              </div>
            </md-card>
          </div>
          <div v-if="!editShowcase" class="md-layout-item md-size-100">
            <md-card>
              <div class="md-layout">
                <div class="md-layout-item md-size-50">
                  <h3>Showcase</h3>
                </div>
                <div class="md-layout-item md-size-50 text-right">
                  <md-button
                    class="md-success"
                    @click="editShowcase = !editShowcase"
                  >
                    <md-icon v-if="!editShowcase" style="cursor: pointer"
                      >edit</md-icon
                    >
                    <md-icon v-if="editShowcase" style="cursor: pointer"
                      >check</md-icon
                    >
                  </md-button>
                </div>
                <div class="md-layout-item md-size-100"></div>
              </div>
            </md-card>
          </div>
          <div v-if="editShowcase" class="md-layout-item md-size-100">
            <md-card>
              <div class="md-layout">
                <div class="md-layout-item md-size-50">
                  <h3>Kurse/Übungen ergänzen</h3>
                </div>
                <div class="md-layout-item md-size-50 text-right">
                  <md-button
                    class="md-success"
                    @click="editShowcase = !editShowcase"
                  >
                    <md-icon v-if="!editShowcase" style="cursor: pointer"
                      >edit</md-icon
                    >
                    <md-icon v-if="editShowcase" style="cursor: pointer"
                      >check</md-icon
                    >
                  </md-button>
                </div>
                <div v-if="editShowcase" class="md-layout-item md-size-100">
                  <div class="md-layout">
                    <div class="md-layout-item md-size-50 md-small-size-100">
                      <md-field class="md-form-group">
                        <label>Übung oder Kurs suchen</label>
                        <md-input v-model="searchString" @input="searchItems" />
                      </md-field>
                    </div>
                    <div class="md-layout-item md-size-50 md-small-size-100">
                      <md-field class="md-form-group">
                        <label>Typ</label>
                        <md-select
                          @md-selected="searchItems"
                          v-model="searchType"
                        >
                          <md-option value="course">Kurs</md-option>
                          <md-option value="exercise">Übung</md-option>
                        </md-select>
                      </md-field>
                    </div>
                    <div class="md-layout-item md-size-100 md-layout">
                      <div
                        v-for="course in courses"
                        :key="course.id"
                        class="md-layout-item md-size-100 md-layout"
                      >
                        <div
                          class="md-layout-item md-size-50 md-small-size-100 md-medium-size-100"
                        >
                          {{ course.name }}
                        </div>
                        <div
                          class="md-layout-item md-size-50 md-small-size-100 md-medium-size-100"
                        >
                          <md-button
                            @click="
                              addShowcase(course.id, 'course', course.name)
                            "
                            class="md-success"
                            >Shocase hinzufügen</md-button
                          >
                        </div>
                        <div class="md-layout-item md-size-100">
                          <hr />
                        </div>
                      </div>
                      <div
                        v-for="exercise in exercises"
                        :key="exercise.id"
                        class="md-layout-item md-size-100 md-layout"
                      >
                        <div
                          class="md-layout-item md-size-50 md-small-size-100 md-medium-size-100"
                        >
                          {{ exercise.name }}
                        </div>
                        <div
                          class="md-layout-item md-size-50 md-small-size-100 md-medium-size-100"
                        >
                          <md-button
                            @click="
                              addShowcase(
                                exercise.id,
                                'exercise',
                                exercise.name
                              )
                            "
                            class="md-success"
                            >Shocase hinzufügen</md-button
                          >
                        </div>
                        <div class="md-layout-item md-size-100">
                          <hr />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </md-card>
          </div>
          <div v-if="showcases" class="md-layout-item md-size-100">
            <div
              class="md-layout-item md-size-100"
              v-for="showcase in showcases.course"
              :key="showcase.id"
            >
              <h3>
                <div
                  class="md-layout-item md-small-size-100 md-medium-size-100"
                >
                  {{ showcase.title }}
                </div>
                <div
                  class="md-layout-item md-small-size-100 md-medium-size-100"
                >
                  <md-button
                    class="md-success"
                    @click="openEditShowcase(showcase, 'course')"
                    ><md-icon style="cursor: pointer">edit</md-icon></md-button
                  >
                  <md-button
                    @click="deleteShowcase(showcase.id, 'course')"
                    class="md-danger"
                    style="margin-left: 25px"
                    >Löschen</md-button
                  >
                </div>
              </h3>
              <p v-html="showcase.description"></p>
              <CourseComponent
                :directoryID="showcase.course.directory_id"
                :course="showcase.course"
                :key="showcase.course.id"
                class="md-layout-item md-size-100"
              ></CourseComponent>
            </div>
            <div
              class="md-layout-item md-size-100"
              v-for="showcase in showcases.exercise"
              :key="showcase.id"
            >
              <h3>
                <div
                  class="md-layout-item md-small-size-100 md-medium-size-100"
                >
                  {{ showcase.title }}
                </div>
                <div
                  class="md-layout-item md-small-size-100 md-medium-size-100"
                >
                  <md-button
                    class="md-success"
                    @click="openEditShowcase(showcase, 'exercise')"
                    ><md-icon style="cursor: pointer">edit</md-icon></md-button
                  >
                  <md-button
                    @click="deleteShowcase(showcase.id, 'exercise')"
                    class="md-danger"
                    style="margin-left: 25px"
                    >Löschen</md-button
                  >
                </div>
              </h3>
              <p v-html="showcase.description"></p>

              <CardComponent
                class="md-layout-item md-xlarge-size-20 md-large-size-33 md-medium-size-50 md-small-size-100 md-xsmall-size-100"
                :directoryID="showcase.exercise.directory_id"
                :mediaEntry="showcase.exercise"
              ></CardComponent>
            </div>
          </div>

          <!-- Create Showcase Dialog -->
          <CreateShowcase
            v-if="showCreateShowcase"
            :showDialog="showCreateShowcase"
            :objectID="objectID"
            :objectType="objecftType"
            :objectName="objectName"
            @closeDialog="closeDialog"
          ></CreateShowcase>
          <!-- /Create Showcase Dialog -->

          <!-- Edit Showcase Dialog -->
          <EditShowcase
            v-if="showEditShowcase"
            :showDialog="showEditShowcase"
            :showcase="editShowcaseShowcase"
            :objectType="editShowcaseType"
            @closeDialog="closeDialog"
          ></EditShowcase>
          <!-- /Edit Showcase Dialog -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useCoachStore } from "@/stores/CoachStore";
import { userStore } from "@/stores/UserStore";
import { useCourseStore } from "@/stores/CourseStore";
import { useExerciseStore } from "@/stores/ExerciseStore";
import { useCourseShowcaseStore } from "@/stores/CourseShowcaseStore";
import { useExerciseShowcaseStore } from "@/stores/ExerciseShowcaseStore";
import { useShowcaseStore } from "@/stores/ShowcaseStore";
import CreateShowcase from "@/pages/Dashboard/Pages/Coach/Showcase/CreateShowcase.vue";
import EditShowcase from "@/pages/Dashboard/Pages/Coach/Showcase/EditShowcase.vue";
import CourseComponent from "@/pages/Dashboard/Courses/CourseComponent.vue";
import CardComponent from "@/pages/Dashboard/Components/CardComponent";
export default {
  components: {
    CreateShowcase,
    EditShowcase,
    CourseComponent,
    CardComponent,
  },
  setup() {
    const useUserStore = userStore();
    const coachStore = useCoachStore();
    const store = userStore();
    const courseStore = useCourseStore();
    const exerciseStore = useExerciseStore();
    const exerciseShowcaseStore = useExerciseShowcaseStore();
    const courseShowcaseStore = useCourseShowcaseStore();
    const showcaseStore = useShowcaseStore();
    return {
      coachStore,
      store,
      useUserStore,
      courseStore,
      exerciseStore,
      exerciseShowcaseStore,
      courseShowcaseStore,
      showcaseStore,
    };
  },
  data() {
    return {
      editInfo: false,
      editDescription: false,
      editShowcase: false,
      showCreateShowcase: false,
      showEditShowcase: false,
      newProfilePictureSelected: false,
      objectID: null,
      objectType: null,
      objectName: null,
      editShowcaseShowcase: [],
      editShowcaseType: null,
      coach: null,
      showcases: [],
      searchString: "",
      searchType: "",
      courses: [],
      exercises: [],
    };
  },
  beforeMount() {
    let coachID = this.$route.params.coachID;
    this.coachStore.getSelfCoach().then((response) => {
      this.coach = this.coachStore.coach;
    });
    this.getShowcases();
  },
  methods: {
    getShowcases() {
      this.showcaseStore.selfIndex().then((response) => {
        this.showcases = this.showcaseStore.showcases;
      });
    },
    updateInfo() {
      this.useUserStore.name = this.coach.name;
      this.useUserStore.slogan = this.coach.slogan;
      this.useUserStore.homepage = this.coach.homepage;
      this.useUserStore.updateUser().then((response) => {
        this.editInfo = false;
        this.coachStore.getSelfCoach().then((response) => {
          this.coach = this.coachStore.coach;
        });
      });
    },
    updateAbout() {
      this.useUserStore.name = this.coach.name;
      this.useUserStore.slogan = this.coach.slogan;
      this.useUserStore.homepage = this.coach.homepage;
      this.useUserStore.about = this.coach.about;
      this.useUserStore.updateUser().then((response) => {
        this.editDescription = false;
        this.coachStore.getSelfCoach().then((response) => {
          this.coach = this.coachStore.coach;
        });
      });
    },
    handleProfileUpload(event) {
      this.newProfilePictureSelected = true;
      this.newImage = event.target.files[0];
      this.url = URL.createObjectURL(this.newImage);
      const file = event.target.files[0];
      this.newProfilePicutre = file;
    },
    updateProfilePicture() {
      this.store.updateImage(this.newProfilePicutre);
      this.newProfilePictureSelected = false;
      this.newProfilePicutre = null;
    },
    cancelProfilePictureUpdate() {
      this.newProfilePictureSelected = false;
      this.newImage = null;
      this.url = null;
      this.newProfilePicutre = null;
    },
    searchItems() {
      this.courses = [];
      this.exercises = [];
      if (this.searchString.length >= 3) {
        if (this.searchType == "course") {
          this.courseStore
            .searchCoachCourses(this.searchString)
            .then((response) => {
              this.courses = this.courseStore.courses;
            });
        } else if (this.searchType == "exercise") {
          this.exerciseStore
            .searchCoachExercises(this.searchString)
            .then((response) => {
              this.exercises = this.exerciseStore.exercises;
            });
        }
      }
    },
    addShowcase(objectID, objectType, objectName) {
      this.objectID = objectID;
      this.objecftType = objectType;
      this.objectName = objectName;
      this.showCreateShowcase = true;
    },
    openEditShowcase(showcase, objectType) {
      this.editShowcaseShowcase = showcase;
      this.editShowcaseType = objectType;
      this.showEditShowcase = true;
    },
    deleteShowcase(id, objectType) {
      if (objectType == "course") {
        this.courseShowcaseStore.deleteCourseShowcase(id).then((response) => {
          this.getShowcases();
        });
      } else if (objectType == "exercise") {
        this.exerciseShowcaseStore
          .deleteExerciseShowcase(id)
          .then((response) => {
            this.getShowcases();
          });
      }
    },
    closeDialog(hasChanges = false) {
      this.showCreateShowcase = false;
      this.showEditShowcase = false;
      this.editShowcaseShowcae = [];
      this.editShowcaseType = null;
      this.objectID = null;
      this.objecftType = null;
      this.objectName = null;
      if (hasChanges) {
        this.getShowcases();
      }
    },
  },
};
</script>

<style>
.dialog-container .md-dialog-container {
  min-width: 768px !important;
  max-width: calc(100% - 720px) !important;
}
.edit-container .md-dialog-container {
  max-width: calc(100% - 720px) !important;
}
</style>
<style scoped>
h3 {
  text-align: left;
  margin: 15px 0px;
}
.md-button {
  margin: 10px 0;
}
</style>

<template>
  <div class="md-layout text-center">
    <CreateExercise
      :showDialog="showCreateDialog"
      @md-clicked-outside="closeDialog"
      @closeDialog="closeDialog"
      class="dialog-container"
    ></CreateExercise>
    <EditExercise
      v-if="selectedExercise"
      :showDialog="showEditDialog"
      @md-clicked-outside="closeDialog"
      @closeDialog="closeDialog"
      :item="selectedExercise"
      class="dialog-container"
    ></EditExercise>
    <div class="md-layout-item md-size-100 md-small-size-100">
      <md-card>
        <div
          style="margin: 10px"
          class="md-layout-item md-size-100 md-small-size-100"
        >
          <h3>Übungen</h3>
          <md-button class="md-success" @click="showCreateDialog = true"
            >Übung hinzufügen</md-button
          >
        </div>
      </md-card>
    </div>
    <TableWrapper
      :title="title"
      :cells="cells"
      :footer="footer"
      :actions="actions"
      :items="items"
      @edit="tableEdit"
      @delete="tableDelete"
    >
    </TableWrapper>
  </div>
</template>

<script>
import { useExerciseStore } from "@/stores/ExerciseStore";
import TableWrapper from "@/pages/Dashboard/Pages/Admin/Components/TableWrapper.vue";
import CreateExercise from "@/pages/Dashboard/Pages/Admin/Exercise/CreateExercise.vue";
import EditExercise from "@/pages/Dashboard/Pages/Admin/Exercise/EditExercise.vue";

import Swal from "sweetalert2";

export default {
  components: {
    TableWrapper,
    CreateExercise,
    EditExercise,
  },
  setup() {
    const exerciseStore = useExerciseStore();
    return {
      exerciseStore,
    };
  },
  data() {
    return {
      title: "Übungen",
      cells: [
        {
          name: "name",
          label: "Name",
          searchable: true,
        },
        {
          name: "tiers_id",
          label: "Benötigte Stufe",
          searchable: true,
        },
        {
          name: "directoryPath",
          label: "Pfad",
          searchable: true,
        },
        {
          name: "difficulty",
          label: "Schwierigkeit",
          searchable: true,
        },
        {
          name: "coachName",
          label: "Coach",
          searchable: true,
        },
        {
          name: "url",
          label: "Nutzeransicht",
          searchable: false,
          url: true,
        },
      ],
      actions: ["edit", "delete"],
      items: [],
      footer: [
        "Name",
        "Benötigte Stufe",
        "Pfad",
        "Schwierigkeit",
        "Coach",
        "Nutzeransicht",
        "Actions",
      ],
      showCreateDialog: false,
      showEditDialog: false,
      selectedExercise: null,
    };
  },
  beforeMount() {
    this.exerciseStore.getCoachExercises().then((response) => {
      this.items = this.exerciseStore.exercises;
    });
  },
  methods: {
    tableEdit(item) {
      this.selectedExercise = item;
      this.showEditDialog = true;
    },
    tableDelete(item) {
      Swal.fire({
        title: "Sind Sie sicher?",
        text: "Diesen Schwierigkeitsgrad wirklich löschen?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ja, löschen!",
        cancelButtonText: "Nein, abbrechen!",
      }).then(
        (result) => {
          if (result.value) {
            this.exerciseStore.deleteExercise(item.id).then((response) => {
              this.exerciseStore.getCoachExercises().then((response) => {
                this.items = this.exerciseStore.exercises;
              });
            });
          }
        },
        (error) => {}
      );
    },
    closeDialog(hasChanges = false) {
      this.selectedExercise = null;
      this.showEditDialog = false;
      this.showCreateDialog = false;
      if (hasChanges) {
        this.exerciseStore.getCoachExercises().then((response) => {
          this.items = this.exerciseStore.exercises;
        });
      }
    },
  },
};
</script>

<style>
.dialog-container .md-dialog-container {
  min-width: 768px !important;
  max-width: calc(100% - 720px) !important;
}
.edit-container .md-dialog-container {
  max-width: calc(100% - 720px) !important;
}
</style>

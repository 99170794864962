<template>
  <md-dialog @md-clicked-outside="closeDialog" :md-active.sync="showDialog">
    <md-dialog-title class="mx-auto">Übung hinzufügen</md-dialog-title>
    <div class="md-layout text-center">
      <div class="md-layout-item md-size-30">
        <md-field class="md-form-group">
          <label>Name</label>
          <md-input typeID="content" v-model="exercise.name"></md-input>
        </md-field>
        <md-field class="md-form-group">
          <label>Typ</label>
          <md-select typeID="content" v-model="exercise.type">
            <md-option v-for="type in types" :key="type.id" :value="type.id">
              {{ type.name }}
            </md-option>
          </md-select>
        </md-field>
        <md-field class="md-form-group">
          <label>Benötigte Stufe</label>
          <md-select typeID="content" v-model="exercise.tier">
            <md-option v-for="tier in tiers" :key="tier.id" :value="tier.id">{{
              tier.name
            }}</md-option>
          </md-select>
        </md-field>
        <md-field class="md-form-group">
          <label>Zeitaufwand in Minuten</label>
          <md-input typeID="content" v-model="exercise.time"></md-input>
        </md-field>
        <md-checkbox typeID="content" v-model="exercise.draft"
          >Entwurf</md-checkbox
        >
        <md-field class="md-form-group">
          <label>Übungsbild</label>
          <md-file @change="handleFileInput" />
        </md-field>
        <md-field class="md-form-group">
          <label>Kategorie</label>
          <md-select typeID="content" v-model="exercise.category">
            <md-option
              v-for="category in categories"
              :key="category.id"
              :value="category.id"
              >{{ category.name }}</md-option
            >
          </md-select>
        </md-field>
        <md-field class="md-form-group">
          <label>Schwierigkeit</label>
          <md-select typeID="content" v-model="exercise.difficulty">
            <md-option
              v-for="difficulty in difficulties"
              :key="difficulty.id"
              :value="difficulty.id"
              >{{ difficulty.name }}</md-option
            >
          </md-select>
        </md-field>
      </div>

      <div class="md-layout-item md-size-70">
        <div v-if="exercise.type === 1">
          <vue-editor
            v-model="exercise.content"
            :editor-config="editorConfig"
            :editor-toolbar="editorConfig.toolbar"
          ></vue-editor>
          <md-field class="md-form-group">
            <label>Zusätzliche Dokumente</label>
            <md-file
              ref="newExerciseFiles"
              @change="handleAttachmentInput"
              type="file"
              name="file[]"
              multiple="true"
            />
            <br />
          </md-field>
          <!-- Files to be uploaded display -->
          <div v-if="newExerciseFiles" class="md-layout">
            <div
              class="md-layout-item md-size-100"
              v-for="(file, i) of newExerciseFiles"
              :key="i"
            >
              <p>
                {{ file.name }}
                {{ file.size / 1000 / 1000 }} MB
                <button @click="removeFromUploadList(i)" class="btn btn-danger">
                  Entfernen
                </button>
              </p>
            </div>
          </div>
        </div>

        <div
          v-if="exercise.type === 2"
          class="quiz-view"
          style="max-height: 70vh"
        >
          <h3 style="">
            Quiz erstellen
            <md-button @click="addQuestion()" class="btn btn-success"
              >Neue Fragen hinzufügen</md-button
            >
          </h3>
          <div
            v-for="(item, index) in exercise.content"
            :key="index"
            style="border: 1px solid rgba(0, 0, 0, 0.125); margin-bottom: 15px"
            class="md-card"
          >
            <div
              class="md-layout"
              style="background: rgba(0, 0, 0, 0.09); padding: 5px"
            >
              <md-field class="md-layout-item md-size-100 md-form-group">
                <label>Name</label>
                <md-input
                  class="md-size-50"
                  v-model="exercise.content[index].name"
                />
              </md-field>
              <md-button
                @click="addAnswer(index)"
                class="md-layout-item md-size-33 md-sm md-success"
                >Eine Antwort hinzufügen</md-button
              >
              <md-button
                @click="exercise.content.splice(index, 1)"
                class="md-layout-item md-size-33 md-sm md-danger"
                style="margin-left: 10px"
                >Löschen</md-button
              >
            </div>

            <div style="padding: 0">
              <div
                v-for="(answer, answerIndex) of item.options"
                :key="answerIndex"
                class="form-check md-layout text-center"
                style="padding: 10px"
              >
                <md-input
                  class="md-layout-item md-size-20"
                  type="checkbox"
                  v-model="item.options[answerIndex].correct"
                  id="flexCheckDefault"
                  style="margin-left: 0px"
                />
                <label
                  class="md-layout-item md-size-40"
                  for="flexCheckDefault"
                  style="margin-left: 1.25rem"
                  >Ist diese Antwort richtig?</label
                >
                <md-field class="md-layout-item md-size-100">
                  <md-input
                    v-model="item.options[answerIndex].answer"
                  ></md-input>
                </md-field>
                <md-field class="md-layout-item md-size-100">
                  <md-input v-model="item.options[answerIndex].text" />
                </md-field>
                <md-button
                  @click="item.options.splice(answerIndex, 1)"
                  class="md-layout-item md-size-50"
                  >Löschen</md-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <md-dialog-actions class="mx-auto" style="padding-top: 25px">
      <md-button class="md-success" @click="closeDialog">Close</md-button>
      <md-button class="md-success" @click="createExercise"
        >Speichern</md-button
      >
    </md-dialog-actions>
  </md-dialog>
</template>

<script>
import { useExerciseStore } from "@/stores/ExerciseStore";
import { useTierStore } from "@/stores/TierStore";
import { useCategoryStore } from "@/stores/CategoryStore";
import { useDifficultyStore } from "@/stores/DifficultyStore";

import { VueEditor } from "vue2-editor";

export default {
  components: {
    VueEditor,
  },
  props: ["item", "showDialog"],
  setup() {
    const exerciseStore = useExerciseStore();
    const tierStore = useTierStore();
    const categoryStore = useCategoryStore();
    const difficultyStore = useDifficultyStore();
    return {
      exerciseStore,
      tierStore,
      categoryStore,
      difficultyStore,
    };
  },
  data() {
    return {
      exercise: {
        name: "",
        type: 0,
        tier: 0,
        time: 0,
        draft: false,
        image: "",
        category: "",
        difficulty: "",
        content: [],
      },
      newExerciseFiles: [],
      testCounter: 0,
      types: [],
      tiers: [],
      categories: [],
      difficulties: [],
      editorConfig: {
        toolbar: [
          ["bold", "italic", "underline", "strike"],
          ["blockquote", "code-block"],
          [{ header: 1 }, { header: 2 }],
          [{ list: "ordered" }, { list: "bullet" }],
          [{ script: "sub" }, { script: "super" }],
          [{ indent: "-1" }, { indent: "+1" }],
          [{ direction: "rtl" }],
          [{ size: ["small", false, "large", "huge"] }],
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          [{ color: [] }, { background: [] }],
          [{ font: [] }],
          [{ align: [] }],
          ["clean"],
          ["link", "image", "video"],
        ],
        video: {
          tag: "iframe",
          attributes: {
            class: "video-container",
            frameborder: "0",
            allowfullscreen: "",
          },
        },
      },
    };
  },
  beforeMount() {
    this.exerciseStore.getExerciseTypes().then((response) => {
      this.types = this.exerciseStore.types;
    });
    this.tierStore.getTiers().then((response) => {
      this.tiers = this.tierStore.tiers;
    });
    this.categoryStore.getCategories().then((response) => {
      this.categories = this.categoryStore.categories;
    });
    this.difficultyStore.getDifficulties().then((response) => {
      this.difficulties = this.difficultyStore.difficulties;
    });
  },
  methods: {
    handleAttachmentInput(e) {
      const files = [...e.target.files];
      files.forEach((element) => {
        this.newExerciseFiles.push(element);
      });
      this.$refs.newExerciseFiles.value = "";
    },
    removeFromUploadList(index) {
      this.newExerciseFiles.splice(index, 1);
    },
    deleteUpload(file, index) {
      axios.put("/api/media/file/" + file.id).then((response) => {
        this.updateFiles.splice(index, 1);
      });
    },
    addQuestion() {
      this.testCounter = this.testCounter + 1;
      this.exercise.content.push({
        type: "multipleChoice",
        name: "Geben Sie einen Fragenamen ein: " + this.testCounter,
        options: [],
      });
    },
    addAnswer(index) {
      this.exercise.content[index].options.push({
        answer: "Geben Sie hier eine Antwort ein",
        text: "Diese Antwort ist inkorrekt",
        correct: false,
      });
    },
    handleFileInput(event) {
      const file = event.target.files[0];
      this.exercise.image = file;
    },
    closeDialog() {
      this.$emit("closeDialog", false);
    },
    createExercise() {
      // Create new formdata
      const formData = new FormData();
      // Add all data to formdata
      formData.append("name", this.exercise.name);
      formData.append("type", this.exercise.type);
      formData.append("tiers_id", this.exercise.tier);
      formData.append("time", this.exercise.time);
      formData.append("draft", this.exercise.draft);
      formData.append("image", this.exercise.image);
      formData.append("directoryID", this.exercise.category);
      formData.append("difficultyID", this.exercise.difficulty);
      // Only parse json if content actually is json
      if (typeof this.exercise.content === "object") {
        formData.append("content", JSON.stringify(this.exercise.content));
      } else {
        formData.append("content", this.exercise.content);
      }
      // Add all files to formdata
      this.newExerciseFiles.forEach((file) => {
        formData.append("files[]", file);
      });
      this.exerciseStore.createExercise(formData).then((response) => {
        this.$emit("closeDialog", true);
      });
    },
  },
  watch: {
    showDialog: function (val) {
      if (val) {
        this.exercise = {
          name: "",
          type: 0,
          tier: 0,
          time: 0,
          draft: false,
          image: "",
          category: "",
          difficulty: "",
          content: [],
        };
        this.newExerciseFiles = [];
        this.testCounter = 0;
      }
    },
  },
};
</script>

<style scoped>
.quiz-view {
  max-height: 70vh;
  overflow-y: auto;
}

.md-dialog-container,
md-dialog {
  min-width: 768px !important;
}
.md-field.md-form-group:before,
.md-field.md-form-group:after {
  width: 100% !important;
}
</style>

import { defineStore } from "pinia";
import request from "@/utils/request";

// useStore could be anything like useUser, useCart
// the first argument is a unique id of the store across your application
export const useShowcaseStore = defineStore("showcaseStore", {
  state: () => ({
    showcases: [],
  }),
  actions: {
    async index(userID) {
      await request.get("/api/showcase/coach/" + userID).then((response) => {
        this.showcases = response.data;
      });
    },
    async selfIndex() {
      await request.get("/api/showcase/self").then((response) => {
        this.showcases = response.data;
      });
    },
  },
});

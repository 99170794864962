<template>
  <div class="md-layout text-center">
    <EditTier
      v-if="selectedTier"
      :showDialog="showEditDialog"
      :item="selectedTier"
      @closeDialog="closeDialog"
      @md-clicked-outsitde="closeDialog"
    ></EditTier>
    <TableWrapper
      :title="title"
      :cells="cells"
      :footer="footer"
      :actions="actions"
      :items="items"
      @edit="tableEdit"
    >
    </TableWrapper>
  </div>
</template>

<script>
import { useTierStore } from "@/stores/TierStore";
import TableWrapper from "../Components/TableWrapper.vue";
import EditTier from "@/pages/Dashboard/Pages/Admin/Tier/EditTier.vue";

export default {
  components: {
    TableWrapper,
    EditTier,
  },
  setup() {
    const tierStore = useTierStore();
    return {
      tierStore,
    };
  },
  data() {
    return {
      title: "Stufen",
      cells: [
        {
          name: "name",
          label: "Name",
          searchable: false,
        },
        {
          name: "level",
          label: "Tier",
          searchable: false,
        },
      ],
      actions: ["edit"],
      items: [],
      footer: ["Name", "Tier", "Aktionen"],
      showEditDialog: false,
      selectedTier: null,
    };
  },
  beforeMount() {
    this.tierStore.getTiers().then((response) => {
      this.items = this.tierStore.tiers;
    });
  },
  methods: {
    tableEdit(item) {
      this.selectedTier = item;
      this.showEditDialog = true;
    },
    closeDialog(hasChanges) {
      this.selectedTier = null;
      this.showEditDialog = false;
      if (hasChanges) {
        this.tierStore.getTiers().then((response) => {
          this.items = this.tierStore.tiers;
        });
      }
    },
  },
};
</script>

<style></style>

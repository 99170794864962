<template>
    <div class="row">
        <div class="col-md-12">
            <h1>Gratuliere!</h1>
            <p>Mit dem durchführen dieses Kurses hast du einen weiteren wichtigen Schritt zur Verbesserung deines Lebens getan</p>
        </div>
    </div>
</template>

<style scoped>

</style>

<script>
    export default {
        components: {
        },
        props: {
        },
        mounted() {
        }   
    }
</script>
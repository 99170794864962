<template>
  <div class="md-layout">
    <div
      v-if="exercise.typename !== 'Quiz'"
      style="margin-bottom: 1rem; word-wrap: break-word"
      class="md-layout-item md-size-100 content-container ql-editor ql-editor-view"
      v-html="html"
    ></div>
    <div
      style="margin-top: 16px; margin-bottom: 16px"
      class="md-layout-item md-size-100"
      v-if="exercise.typename === 'Quiz'"
    >
      <div class="row">
        <div
          v-for="question in quiz"
          :key="question.id"
          class="md-layout-item md-size-100 form-group"
        >
          <h3>{{ question.name }}</h3>
          <div
            v-if="validationComplete"
            class="text-center"
            v-bind:class="{
              wrong: !question.answer.correct,
              right: question.answer.correct,
            }"
          >
            <h5>{{ question.answer.text }}</h5>
          </div>
          <div
            v-for="answer in question.options"
            :key="answer.id"
            class="checkbox"
            style="margin-left: 1.25rem"
          >
            <input
              @click="selectAnswer(question, answer)"
              v-model="answer.selected"
              type="checkbox"
              class="form-check-input"
            />
            {{ answer.answer }}
            <div
              v-if="answer.selected && validationComplete"
              v-bind:class="{
                wrong: !answer.correct,
                right: answer.correct,
              }"
            >
              {{ answer.text }}
            </div>
          </div>
        </div>
      </div>

      <div class="text-center">
        <md-button
          :disabled="openQuestions"
          @click="validate()"
          class="md-button md-success"
        >
          Kontrollieren
        </md-button>
      </div>
      <br /><br />
    </div>
  </div>
</template>

<script>
import { QuillDeltaToHtmlConverter } from "quill-delta-to-html";

export default {
  props: {
    exercise: Object,
  },
  data() {
    return {
      html: "",
      quiz: [],
      validationComplete: false,
    };
  },
  mounted() {
    if (this.exercise.mediaTypeID == 1) {
      if (this.exercise.content.includes('{"ops":[')) {
        var rawDelta = JSON.parse(this.exercise.content);
        let cfg = {};
        let converter = new QuillDeltaToHtmlConverter(rawDelta.ops, cfg);
        this.html = converter.convert();
      } else {
        this.html = this.exercise.content;
      }
    } else if (this.exercise.mediaTypeID == 2) {
      var content = JSON.parse(this.exercise.content);
      content.forEach((element) => {
        element.selected = false;
      });
      this.quiz = content;
    }
  },
  methods: {
    allQuestionsAnswered() {
      this.openQuestions = false;
      this.quiz.forEach((question) => {
        if (
          question.options.findIndex((option) => option.selected === true) == -1
        ) {
          this.openQuestions = true;
        } else {
          this.openQuestions = false;
        }
      });
    },
    selectAnswer(question, answer) {
      answer.selected = true;
      this.allQuestionsAnswered();
    },
    validate() {
      var selectedAnswers = [];
      this.validationComplete = false;
      // Check if an answer is selected that is wrong
      this.quiz.forEach((question) => {
        selectedAnswers[question.name] = [];
        // Fetch all selected options
        question.options.forEach((option) => {
          if (option.selected) {
            selectedAnswers[question.name].push(option);
          }
        });

        // if one answer selected and one answer correct, then right
        if (
          selectedAnswers[question.name].length ==
          question.options.filter((option) => option.correct).length
        ) {
          selectedAnswers[question.name].forEach((answer) => {
            // See if the matching option is correct
            if (answer.correct) {
              question.answer = {
                correct: true,
                text: "Richtig!",
              };
            } else {
              question.answer = {
                correct: false,
                text: "Leider falsch. Versuche es noch einmal.",
              };
            }
          });
        } else {
          // if more than one answer selected and one answer correct, then wrong
          question.answer = {
            correct: false,
            text: "Leider falsch. Versuche es noch einmal.",
          };
        }
      }); // <-- add missing closing curly brace for second forEach loop
      this.openQuestions = false;
      this.validationComplete = true;
    }, // <-- add missing closing curly brace for validate() function
  },
};
</script>
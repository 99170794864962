<template>
  <div v-if="queriedData" class="md-layout">
    <div class="md-layout-item">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>assignment</md-icon>
          </div>
          <h4 class="title" style="">{{ title }}</h4>
        </md-card-header>
        <md-card-content>
          <md-table
            :value="queriedData"
            :md-sort.sync="currentSort"
            :md-sort-order.sync="currentSortOrder"
            :md-sort-fn="customSort"
            @md-selected="onSelect"
            class="paginated-table table-striped table-hover"
          >
            <md-table-toolbar>
              <md-field>
                <label for="pages">Pro Seite</label>
                <md-select v-model="pagination.perPage" name="pages">
                  <md-option
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                    {{ item }}
                  </md-option>
                </md-select>
              </md-field>

              <md-field>
                <md-input
                  type="search"
                  class="mb-3"
                  clearable
                  style="width: 200px"
                  placeholder="Search records"
                  v-model="searchQuery"
                >
                </md-input>
              </md-field>
            </md-table-toolbar>

            <md-table-row
              md-selectable="single"
              slot="md-table-row"
              slot-scope="{ item }"
              style="text-align: left"
              :class="getClass(item)"
            >
              <md-table-cell
                style="overflow-wrap: break-word"
                v-for="cell in cells"
                :key="cell.name"
                :md-label="cell.label"
                :md-sort-by="cell.name"
              >
                <span v-if="cell.url">
                  <router-link :to="item[cell.name]">
                    <md-button style="width: 100%" class="md-success">
                      Öffnen
                    </md-button>
                  </router-link>
                </span>
                <span v-if="!cell.url">
                  {{ item[cell.name] }}
                </span>
              </md-table-cell>
              <md-table-cell md-label="Actions">
                <!-- <md-button class="md-just-icon edit "><md-icon>edit</md-icon></md-button> -->
                <md-button
                  v-if="actions.includes('edit')"
                  style="margin-right: 8px"
                  class="md-just-icon md-success md-simple`"
                  @click.native="handleEdit(item)"
                >
                  <md-icon>edit</md-icon>
                </md-button>
                <md-button
                  v-if="actions.includes('delete')"
                  class="md-just-icon md-danger md-simple`"
                  @click.native="handleDelete(item)"
                >
                  <md-icon>close</md-icon>
                </md-button>
              </md-table-cell>
            </md-table-row>
          </md-table>
          <div class="footer-table md-table">
            <table>
              <tfoot>
                <tr>
                  <th v-for="item in footer" :key="item" class="md-table-head">
                    <div class="md-table-head-container md-ripple md-disabled">
                      <div class="md-table-head-label">
                        {{ item }}
                      </div>
                    </div>
                  </th>
                </tr>
              </tfoot>
            </table>
          </div>
        </md-card-content>
        <md-card-actions md-alignment="space-between">
          <div class="">
            <p class="card-category">
              Zeigt {{ from + 1 }} bis {{ to }} von {{ total }} Elementen
            </p>
          </div>
          <pagination
            class="pagination-no-border pagination-success"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="total"
          >
          </pagination>
        </md-card-actions>
      </md-card>
    </div>
  </div>
</template>
<script>
import { Pagination } from "@/components";
import Fuse from "fuse.js";

export default {
  components: {
    Pagination,
  },
  props: ["title", "cells", "actions", "items", "footer"],
  data() {
    return {
      tableData: [],
      pagination: {
        currentPage: 1,
        perPage: 10,
        perPageOptions: [10, 20, 50, 100],
      },
      currentSort: "name",
      currentSortOrder: "asc",
      searchQuery: "",
      searchedData: [],
      fuseSearch: null,
      selectedItem: {
        id: 0,
      },
      returnToPage: 0,
    };
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
    searchable() {
      // filter cells for searchable and select name from each
      let searchableCells = this.cells.filter((cell) => cell.searchable);
      let searchableKeys = [];
      searchableCells.forEach((cell) => {
        searchableKeys.push(cell.name);
      });
      return searchableKeys;
    },
  },
  created() {
    // Propagate missing values for cells
    this.cells.forEach((cell) => {
      cell.hasOwnProperty("searchable") || (cell.searchable = false);
      cell.hasOwnProperty("url") || (cell.url = false);
    });
  },
  mounted() {
    // Fuse search initialization.
    this.fuseSearch = new Fuse(this.tableData, {
      keys: this.searchable,
    });
  },
  methods: {
    customSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort;
        if (this.currentSortOrder === "desc") {
          return a[sortBy].localeCompare(b[sortBy]);
        }
        return b[sortBy].localeCompare(a[sortBy]);
      });
    },
    handleEdit(item) {
      this.returnToPage = this.pagination.currentPage;
      this.$emit("edit", item);
    },
    handleDelete(item) {
      this.returnToPage = this.pagination.currentPage;
      this.$emit("delete", item);
    },
    onSelect(item) {
      this.selectedItem = item;
      this.$emit("selectedItem", this.selectedItem);
    },
    getClass(item) {
      if (this.selectedItem && item) {
        if (item.id === this.selectedItem.id) {
          return "md-selected";
        }
      }
    },
  },
  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    searchQuery(value) {
      let result = this.tableData;
      if (value !== "") {
        result = this.fuseSearch.search(this.searchQuery);
        this.searchedData = [];
        result.forEach((searchResult) => {
          this.searchedData.push(searchResult.item);
        });
      } else {
        this.searchedData = this.tableData;
      }
    },
    items(newItems) {
      this.tableData = newItems;
      // Fuse search initialization.
      this.fuseSearch = new Fuse(this.tableData, {
        keys: this.searchable,
        threshold: 0.3,
      });

      if (this.returnToPage > 0) {
        this.pagination.currentPage = this.returnToPage;
        this.returnToPage = 0;
      }
    },
  },
};
</script>

<style lang="css" scoped>
.paginated-table .md-just-icon {
  margin: 0px;
}
.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}
.md-selected-single {
  background-color: lightblue !important;
}
</style>

import { defineStore } from 'pinia'
import request from '@/utils/request'

// useStore could be anything like useUser, useCart
// the first argument is a unique id of the store across your application
export const useArticleTypeStore = defineStore('articleTypeStore', {
    state: () => ({
        types: []
    }),
    actions: {
        async getTypes() {
            await request.get("/api/newstype").then((response) => {
                this.types = response.data
            });
        },
    }
})
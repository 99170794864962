import { defineStore } from "pinia";
import request from "@/utils/request";

// useStore could be anything like useUser, useCart
// the first argument is a unique id of the store across your application
export const useNewsfeedStore = defineStore("newsfeedStore", {
  state: () => ({
    articles: [],
    article: [],
  }),
  actions: {
    async getArticles() {
      await request.get("/api/newsfeed").then((response) => {
        this.articles = response.data;
      });
    },
    async getArticle(articleID) {
      await request.get("/api/newsfeed/admin/" + articleID).then((response) => {
        this.article = response.data;
      });
    },
    createArticle(formData) {
      // Submit post request with formdata header
      request
        .post("/api/newsfeed", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.getArticles();
        });
    },
    async deleteArticle(articleID) {
      await request.delete("/api/newsfeed/" + articleID).then((response) => {
        this.getArticles();
      });
    },
    async updateArticle(articleID, formData) {
      await request.post("/api/newsfeed/patch/" + articleID, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    },
  },
});

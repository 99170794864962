import { defineStore } from "pinia";
import request from "@/utils/request";

// useStore could be anything like useUser, useCart
// the first argument is a unique id of the store across your application
export const userStore = defineStore("userStore", {
    state: () => ({
        name: "",
        email: "",
        image: "",
        slogan: "",
        about: "",
        affiliate_link: "",
        affiliate_user: 0,
        type: 0,
        loaded: false,
        loggedIn: false
    }),
    getters: {
        logonStatus: (state) => state.loggedIn
    },
    actions: {
        login() {
            this.loggedIn = true;
        },
        logout() {
            return request.post("/api/logout");
        },
        async loadCoach(coachID) {
            return await request.get("/api/user/coach/" + coachID);
        },
        async loadUser() {
            const response = await request.get("/api/user/self");
            this.name = response.data.name;
            this.email = response.data.email;
            this.image = response.data.img;
            this.slogan = response.data.slogan;
            this.about = response.data.about;
            this.type = response.data.type;
            this.affiliate_link = response.data.affiliate_link;
            this.affiliate_user = response.data.affiliate_user;
            this.login();
            this.loaded = true;
        },
        updateName(name) {
            this.name = name;
        },
        async updateUser() {
            const response = await request.patch("/api/user/self", {
                name: this.name,
                email: this.email,
                slogan: this.slogan,
                about: this.about
            });
        },
        async updateImage(image) {
            // Create new FormData
            const formData = new FormData();
            // Add image to FormData
            formData.append("img", image);
            await request
                .post("/api/user/profileimg", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                })
                .then((response) => {
                    this.loadUser();
                });
        }
    }
    // other options...
});

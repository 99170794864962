<template>
  <div class="md-layout">
    <div
      class="text-center md-layout-item md-size-80 mx-auto"
      style="margin-left: 1rem; margin-right: 1rem"
    >
      <h3>Übungen</h3>
      <md-divider></md-divider>
    </div>
    <div
      style="margin-bottom: 3rem"
      class="md-layout-item md-layout md-size-80 mx-auto"
    >
      <div
        v-for="exercise in favorites.exercise"
        :key="exercise.id"
        class="md-layout-item"
      >
        <CardComponent
          :directoryID="exercise.media.directory_id"
          :mediaEntry="exercise.media"
        ></CardComponent>
      </div>
    </div>
    <div
      class="text-center md-layout-item md-size-80 mx-auto"
      style="margin-left: 1rem; margin-right: 1rem"
    >
      <h3>Kurse</h3>
      <md-divider></md-divider>
    </div>
    <div class="md-layout-item md-size-80 mx-auto">
      <div
        v-for="course in favorites.course"
        :key="course.id"
        class="md-layout-item md-size-100 mx-auto"
      >
        <CourseComponent
          :course="course.course"
          :courseID="course.course.id"
          class="md-layout-item"
          :key="course.course.id"
        ></CourseComponent>
      </div>
    </div>
  </div>
</template>
<script>
import request from "@/utils/request";
import CardComponent from "@/pages/Dashboard/Components/CardComponent";
import CourseComponent from "@/pages/Dashboard/Courses/CourseComponent";

export default {
  components: {
    CardComponent,
    CourseComponent,
  },
  data() {
    return {
      favorites: [],
    };
  },
  beforeMount() {
    this.getFavorites();
  },
  methods: {
    getFavorites() {
      request.get("/api/favorite").then((response) => {
        this.favorites = response.data;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
#fullCalendar {
  min-height: 300px;
}

.md-card-calendar {
  .md-card-content {
    padding: 0 !important;
  }
}

.text-center {
  text-align: center;
}
</style>

// router setup
import VueRouter from "vue-router";
import routes from "@/routes/routes";

// configure router
const router = new VueRouter({
    mode: 'history',
    routes, // short for routes: routes
    scrollBehavior: (to) => {
      if (to.hash) {
        return { selector: to.hash };
      } else {
        return { x: 0, y: 0 };
      }
    },
    linkExactActiveClass: "nav-item active",
  });
  
  export default router
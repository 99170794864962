<template>
    <div>
        <h3 class="title text-center">Beiträge</h3>
        <div class="md-layout">
            <!-- Real Feed --->
            <div
                class="md-layout-item md-size-75 md-small-size-100 mx-auto"
                v-for="newsEntry of news"
                :key="newsEntry.id"
            >
                <md-card>
                    <md-card-content>
                        <div class="md-layout">
                            <div class="md-layout-item md-size-33 div-blog-img">
                                <img :src="newsEntry.img" class="blog-img" />
                            </div>
                            <div class="md-layout-item md-size-66">
                                <h3>{{ newsEntry.title }}</h3>
                                <h5>{{ newsEntry.created_at }}</h5>
                                <div v-html="newsEntry.preview"></div>

                                <router-link
                                    :to="{ path: '/News/' + newsEntry.id }"
                                >
                                    <md-button class="md-info"
                                        >Mehr lesen</md-button
                                    ></router-link
                                >
                            </div>
                        </div>
                    </md-card-content>
                </md-card>
            </div>

            <!-- /Real Feed --->
        </div>
    </div>
</template>
<script>
// import AppLayout from '@/Layouts/AppLayout'
import { QuillDeltaToHtmlConverter } from "quill-delta-to-html";
import request from "@/utils/request";

export default {
    components: {
        // JetAuthenticationCardLogo,
        // AppLayout
    },
    data() {
        return {
            news: [],
            html: "",
            newsEntry: []
        };
    },
    props: {
        newsID: Number
    },
    mounted() {
        request.get("/api/newsfeed/public").then((response) => {
            var data = response.data;
            this.news = data;
        });
    }
};
</script>
<style>
.blog-img {
    width: 60%;
    margin: auto;
    display: block;
    border-radius: 6px;
}
.div-blog-img {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
}
.md-layout-item h3 {
    margin-top: 0px;
}
.md-layout-item h5 {
    margin-top: 0px;
}
.md-layout-item p {
    /* min-height: 65px; */
}
</style>

import { defineStore } from "pinia";
import request from "@/utils/request";

// useStore could be anything like useUser, useCart
// the first argument is a unique id of the store across your application
export const useExerciseStore = defineStore("exerciseStore", {
  state: () => ({
    exercises: [],
    types: [],
  }),
  actions: {
    async getExercises() {
      await request.get("/api/media").then((response) => {
        this.exercises = response.data;
      });
    },
    async getCoachExercises() {
      await request.get("/api/media/coach").then((response) => {
        this.exercises = response.data;
      });
    },
    async getExerciseTypes() {
      await request.get("/api/media/type").then((response) => {
        this.types = response.data;
      });
    },
    async createExercise(exercise) {
      await request.post("/api/media", exercise, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    },
    async deleteExercise(exerciseID) {
      await request.delete("/api/media/" + exerciseID);
    },
    async updateExercise(exercise) {
      await request.post("/api/media/patch", exercise, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    },
    async deleteExerciseFile(fileID) {
      await request.put("/api/media/file/" + fileID);
    },
    async searchCoachExercises(searchString) {
      await request
        .get("/api/search/coach/exercise/" + searchString)
        .then((response) => {
          this.exercises = response.data;
        });
    },
  },
});

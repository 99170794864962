import { defineStore } from 'pinia'
import request from '@/utils/request'

// useStore could be anything like useUser, useCart
// the first argument is a unique id of the store across your application
export const useSubscriptionStore = defineStore('subscriptionStore', {
    state: () => ({
        id: 0,
        name: '',
        price: [],
        description: '',
        paymentMethod: [],
        subscription: [],
        subscriptions: [],
    }),
    getters: {
        getPaymentMethod() {
            return this.paymentMethod
        },
    },
    actions: {
        getPriceInterval(interval) {
            switch(interval) {
                case "month": return "Monat"
                case "year": return "Jahr"
            }
        },
        async loadSubscription() {
            const response = await request.get('/api/stripe/subscription')
            this.subscriptions = response.data
        },
        async loadSubscriptionStatus() {
            const response = await request.get('/api/stripe/checkout/status')
            this.subscription = response.data
        },
        async loadPaymentMethod() {
            const response = await request.get('/api/stripe/payment_method')
            this.paymentMethod = response.data[0]
        },
        async subscribe(price) {
            const response = await request.post('/api/stripe/checkout/session', {
                price_id: price.id,
                mode: 'subscribe'
            })
            return response
        },
        async reactivate() {
            const response = await request.get('/api/stripe/subscription/reactivate')
            return response
        },
        async deletePaymentMethod(id) {
            const response = await request.delete('/api/stripe/payment_method/'+id)
            return response
        },
        async terminateSubscription(type) {
            const response = await request.post('/api/stripe/terminate/'+type, {})
            return response
        },
        async addPaymentMethod() {
            const response = await request.post('/api/stripe/checkout/session', {
                mode: 'payment_method',
                revert_url: process.env.VUE_APP_APP_ENDPOINT+'/pages/user/subscription',
            })
            return response
        },
        async validateCheckout(sessionID) {
            const response = await request.get('/api/stripe/checkout/validate/'+sessionID)
            return response
        }
    }
})
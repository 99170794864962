<template>
  <div class="md-layout text-center">
    <CreateNewsfeed
      :showDialog="showCreateDialog"
      @closeDialog="closeDialog"
      class="dialog-container"
    ></CreateNewsfeed>
    <EditArticle
      @closeEditArticle="closeEditArticle"
      v-if="selectedArticle"
      :showDialog="showEditDialog"
      :article="selectedArticle"
    ></EditArticle>

    <div class="md-layout-item md-size-100">
      <md-card>
        <div
          style="margin: 10px"
          class="md-layout-item md-size-100 md-small-size-100"
        >
          <h3 style="display: inline">Beiträge</h3>
          <br />
          <md-button class="md-success" @click="showCreateDialog = true"
            >Beitrag hinzufügen</md-button
          >
        </div>
      </md-card>
    </div>

    <div class="md-layout-item md-size-100">
      <!-- <md-card> -->
      <TableWrapper
        :title="title"
        :cells="cells"
        :footer="footer"
        :actions="actions"
        :items="items"
        @edit="tableEdit"
        @delete="tableDelete"
      >
      </TableWrapper>
      <!-- </md-card> -->
    </div>
  </div>
</template>
<script>
import CreateNewsfeed from "@/pages/Dashboard/Pages/Admin/Newsfeed/CreateNewsfeed.vue";
import EditArticle from "@/pages/Dashboard/Pages/Admin/Newsfeed/EditArticle.vue";
import { useNewsfeedStore } from "@/stores/NewsfeedStore";
import TableWrapper from "../Components/TableWrapper.vue";
import Swal from "sweetalert2";

export default {
  components: {
    CreateNewsfeed,
    EditArticle,
    TableWrapper,
  },
  setup() {
    const newsFeedStore = useNewsfeedStore();
    return {
      newsFeedStore,
    };
  },
  data() {
    return {
      title: "Artikel",
      cells: [
        {
          name: "title",
          label: "Name",
          searchable: true,
        },
        {
          name: "typeName",
          label: "Kategorie",
          searchable: true,
        },
        {
          name: "coachName",
          label: "Autor",
          searchable: true,
        },
      ],
      actions: ["edit", "delete"],
      items: [],
      footer: ["Name", "Autor", "Aktionen"],
      showCreateDialog: false,
      showEditDialog: false,
      selectedArticle: null,
    };
  },
  mounted() {
    this.newsFeedStore.getArticles().then((response) => {
      this.items = this.newsFeedStore.articles;
    });
  },
  methods: {
    tableEdit(item) {
      this.$router.push("/admin/newsfeed/article/" + item.id);
    },
    tableDelete(item) {
      Swal.fire({
        title: "Sind Sie sicher?",
        text: "Diesen Artikel wirklich löschen?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ja, löschen!",
        cancelButtonText: "Nein, abbrechen!",
      }).then((result) => {
        if (result.value) {
          this.newsFeedStore.deleteArticle(item.id).then(
            (response) => {
              this.newsFeedStore.getArticles().then((response) => {
                this.items = this.newsFeedStore.articles;
              });
            },
            (error) => {}
          );
        }
      });
    },
    closeDialog(hasChanges) {
      this.showCreateDialog = false;
      if (hasChanges) {
        this.newsFeedStore.getArticles().then((response) => {
          this.items = this.newsFeedStore.articles;
        });
      }
    },
    editArticle(article) {
      this.selectedArticle = article;
      this.showEditDialog = true;
    },
    closeEditArticle() {
      this.selectedArticle = null;
      this.showEditDialog = false;
      this.newsFeedStore.getArticles();
    },
  },
};
</script>
<style></style>

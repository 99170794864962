<template>
  <div class="md-layout">


    <!--<div class="md-layout-item md-size-80 md-medium-size-100 mx-auto">
      <md-card>
        <md-card-content style="text-align: center;">

        </md-card-content>
      </md-card>
    </div>-->

    
  </div>
</template>

<script>

export default {
  components: {},
  data() {
    return {};
  },
};
</script>

import DashboardLayout from "@/pages/Dashboard/Layout/DashboardLayout.vue";
import AdminLayout from "@/pages/Dashboard/Layout/AdminLayout.vue";
import CoachLayout from "@/pages/Dashboard/Layout/CoachLayout.vue";
import AuthLayout from "@/pages/Dashboard/Auth/AuthLayout.vue";

// Dashboard pages
import Dashboard from "@/pages/Dashboard/Dashboard.vue";

// Auth
import Login from "@/pages/Dashboard/Auth/Login.vue";
import Forgot from "@/pages/Dashboard/Auth/ForgotPassword.vue";
import Reset from "@/pages/Dashboard/Auth/ResetPassword.vue";
import Register from "@/pages/Dashboard/Auth/Register.vue";
import Terms from "@/pages/Dashboard/Auth/Terms.vue";
import Privacy from "@/pages/Dashboard/Auth/Privacy.vue";

// P2B News Pages
import NewsFeed from "@/pages/Dashboard/NewsFeed.vue";
import News from "@/pages/Dashboard/News.vue";

// P2B Course Pages
import Courses from "@/pages/Dashboard/Courses.vue";
import Category from "@/pages/Dashboard/Courses/Category.vue";
import Course from "@/pages/Dashboard/Courses/Course.vue";

// P2B Exercise Pages
import ExerciseCategory from "@/pages/Dashboard/Exercise/ExerciseCategory.vue";
import ExerciseArticle from "@/pages/Dashboard/Exercise/ExerciseArticle.vue";

// P2B  Pages
import Favourites from "@/pages/Dashboard/Favourites.vue";
import Exercises from "@/pages/Dashboard/Exercises.vue";
import Coaches from "@/pages/Dashboard/Coaches.vue";
import Coach from "@/pages/Dashboard/Coach.vue";

// Pages
import User from "@/pages/Dashboard/Pages/UserProfile.vue";
import Subscription from "@/pages/Dashboard/Pages/Subscription.vue";
import SubscriptionCheck from "@/pages/Dashboard/Pages/SubscriptionCheck.vue";
import PaymentMethodCheck from "@/pages/Dashboard/Pages/PaymentMethodCheck.vue";
import Pricing from "@/pages/Dashboard/Pages/Pricing.vue";
import TimeLine from "@/pages/Dashboard/Pages/TimeLinePage.vue";
import RtlSupport from "@/pages/Dashboard/Pages/RtlSupport.vue";
import Lock from "@/pages/Dashboard/Pages/Lock.vue";

// Components pages
import Buttons from "@/pages/Dashboard/Components/Buttons.vue";
import GridSystem from "@/pages/Dashboard/Components/GridSystem.vue";
import Panels from "@/pages/Dashboard/Components/Panels.vue";
import SweetAlert from "@/pages/Dashboard/Components/SweetAlert.vue";
import Notifications from "@/pages/Dashboard/Components/Notifications.vue";
import Icons from "@/pages/Dashboard/Components/Icons.vue";
import Typography from "@/pages/Dashboard/Components/Typography.vue";

// TableList pages
import RegularTables from "@/pages/Dashboard/Tables/RegularTables.vue";
import ExtendedTables from "@/pages/Dashboard/Tables/ExtendedTables.vue";
import PaginatedTables from "@/pages/Dashboard/Tables/PaginatedTables.vue";

// Maps pages
import GoogleMaps from "@/pages/Dashboard/Maps/GoogleMaps.vue";
import FullScreenMap from "@/pages/Dashboard/Maps/FullScreenMap.vue";
import VectorMaps from "@/pages/Dashboard/Maps/VectorMaps.vue";

// Calendar
import Calendar from "@/pages/Dashboard/Calendar.vue";
// Charts
import Charts from "@/pages/Dashboard/Charts.vue";
import Widgets from "@/pages/Dashboard/Widgets.vue";

// Admin Pages
import AdminNewsfeed from "@/pages/Dashboard/Pages/Admin/Newsfeed/Newsfeed.vue";
import AdminUser from "@/pages/Dashboard/Pages/Admin/User/User.vue";
import AdminDifficulty from "@/pages/Dashboard/Pages/Admin/Difficulty/Difficulty.vue";
import AdminCategory from "@/pages/Dashboard/Pages/Admin/Category/Category.vue";
import AdminTier from "@/pages/Dashboard/Pages/Admin/Tier/Tier.vue";
import AdminGroup from "@/pages/Dashboard/Pages/Admin/Group/Group.vue";
import AdminExercise from "@/pages/Dashboard/Pages/Admin/Exercise/Exercise.vue";
import AdminCourse from "@/pages/Dashboard/Pages/Admin/Course/Course.vue";
import AdminArticle from "@/pages/Dashboard/Pages/Admin/Newsfeed/Article.vue";

// Coach Pages
import CoachExercise from "@/pages/Dashboard/Pages/Coach/Exercise/Exercise.vue";
import CoachCourse from "@/pages/Dashboard/Pages/Coach/Course/Course.vue";
import CoachShowcase from "@/pages/Dashboard/Pages/Coach/Showcase/Showcase.vue";

// Subscription Pages
import Abonnement from "@/pages/Dashboard/Pages/Subscription.vue";

let componentsMenu = {
  path: "/components",
  component: DashboardLayout,
  redirect: "/components/buttons",
  name: "Components",
  children: [
    {
      path: "buttons",
      name: "Buttons",
      components: { default: Buttons },
    },
    {
      path: "grid-system",
      name: "Grid System",
      components: { default: GridSystem },
    },
    {
      path: "panels",
      name: "Panels",
      components: { default: Panels },
    },
    {
      path: "sweet-alert",
      name: "Sweet Alert",
      components: { default: SweetAlert },
    },
    {
      path: "notifications",
      name: "Notifications",
      components: { default: Notifications },
    },
    {
      path: "icons",
      name: "Icons",
      components: { default: Icons },
    },
    {
      path: "typography",
      name: "Typography",
      components: { default: Typography },
    },
  ],
};

let tablesMenu = {
  path: "/table-list",
  component: DashboardLayout,
  redirect: "/table-list/regular",
  name: "Tables",
  children: [
    {
      path: "regular",
      name: "Regular Tables",
      components: { default: RegularTables },
    },
    {
      path: "extended",
      name: "Extended Tables",
      components: { default: ExtendedTables },
    },
    {
      path: "paginated",
      name: "Pagianted Tables",
      components: { default: PaginatedTables },
    },
  ],
};

let mapsMenu = {
  path: "/maps",
  component: DashboardLayout,
  name: "Maps",
  redirect: "/maps/google",
  children: [
    {
      path: "google",
      name: "Google Maps",
      components: { default: GoogleMaps },
    },
    {
      path: "full-screen",
      name: "Full Screen Map",
      meta: {
        hideContent: true,
        hideFooter: true,
        navbarAbsolute: true,
      },
      components: { default: FullScreenMap },
    },
    {
      path: "vector-map",
      name: "Vector Map",
      components: { default: VectorMaps },
    },
  ],
};

let pagesMenu = {
  path: "/pages",
  component: DashboardLayout,
  name: "Pages",
  redirect: "/pages/user",
  children: [
    {
      path: "user",
      name: "Benutzerprofil",
      meta: {
        title: "Benutzerprofil",
      },
      components: { default: User },
    },
    {
      path: "user/subscription",
      name: "Abonnement",
      meta: {
        title: "Abo",
      },
      components: { default: Subscription },
    },
    {
      path: "user/subscription/callback/:id",
      name: "Abschluss",
      meta: {
        title: "Abo Abschluss",
      },
      props: true,
      components: { default: SubscriptionCheck },
    },
    {
      path: "user/payment_method/callback/:id",
      name: "Abschluss",
      meta: {
        title: "Abo Abschluss",
      },
      props: true,
      components: { default: PaymentMethodCheck },
    },
  ],
};

let authPages = {
  path: "/",
  component: AuthLayout,
  name: "Authentication",
  children: [
    {
      path: "/login",
      name: "Login",
      meta: {
        title: "Login",
      },
      component: Login,
    },
    {
      path: "/logout",
      name: "Logout",
      meta: {
        title: "Logout",
      },
    },
    {
      path: "/register",
      name: "Register",
      meta: {
        title: "Registrieren",
      },
      components: { default: Register },
    },
    {
      path: "/register/:affiliate_link",
      name: "RegisterWithAffiliate",
      meta: {
        title: "Registrieren",
      },
      props: true,
      components: { default: Register },
    },
    {
      path: "/forgot",
      name: "ForgotPassword",
      meta: {
        title: "Passwort vergessen",
      },
      props: true,
      components: { default: Forgot },
    },
    {
      path: "/reset-password/:token",
      name: "ResetPassword",
      meta: {
        title: "Passwort zurücksetzen",
      },
      props: true,
      components: { default: Reset },
    },
    {
      path: "/pricing",
      name: "Preise",
      meta: {
        title: "Preise",
      },
      component: Pricing,
    },
    {
      path: "/terms",
      name: "terms",
      meta: {
        title: "Allgemeine Geschäftsbedingungen",
      },
      component: Terms,
    },
    {
      path: "/terms-of-service",
      name: "terms",
      meta: {
        title: "Allgemeine Geschäftsbedingungen",
      },
      component: Terms,
    },
    {
      path: "/privacy",
      name: "privacy",
      meta: {
        title: "Datenschutzeinstellungen",
      },
      component: Privacy,
    },
    {
      path: "/privacy-policy",
      name: "privacy",
      meta: {
        title: "Datenschutzeinstellungen",
      },
      component: Privacy,
    },
  ],
};

let coachRoutes = {
  path: "/coach",
  component: CoachLayout,
  name: "Coach Space",
  children: [
    {
      path: "exercise",
      name: "Übungen",
      meta: {
        title: "Übungen",
      },
      components: { default: CoachExercise },
    },
    {
      path: "course",
      name: "Kurse",
      meta: {
        title: "Kurse",
      },
      components: { default: CoachCourse },
    },
    {
      path: "showcase",
      name: "Showcase",
      meta: {
        title: "Showcase",
      },
      components: { default: CoachShowcase },
    },
  ],
};

let adminRoutes = {
  path: "/admin",
  component: AdminLayout,
  name: "Administration",
  // Insert Administration sites here
  children: [
    {
      path: "newsfeed",
      name: "Newsfeed",
      meta: {
        title: "News",
      },
      components: { default: AdminNewsfeed },
    },
    {
      path: "newsfeed/article/:id",
      name: "Article",
      components: { default: AdminArticle },
      meta: {
        title: "Artikel",
      },
    },
    {
      path: "user",
      name: "Benutzer",
      components: { default: AdminUser },
      meta: {
        title: "Benutzer",
      },
    },
    {
      path: "difficulty",
      name: "Schwierigkeiten",
      components: { default: AdminDifficulty },
      meta: {
        title: "Schwierigkeit",
      },
    },
    {
      path: "category",
      name: "Kategorien",
      components: { default: AdminCategory },
      meta: {
        title: "Abo",
      },
    },
    {
      path: "tier",
      name: "Stufen",
      components: { default: AdminTier },
      meta: {
        title: "Stufen",
      },
    },
    {
      path: "group",
      name: "Gruppen",
      components: { default: AdminGroup },
      meta: {
        title: "Gruppen",
      },
    },
    {
      path: "exercise",
      name: "Übungen",
      components: { default: AdminExercise },
      meta: {
        title: "Übungen",
      },
    },
    {
      path: "course",
      name: "Kurse",
      meta: {
        title: "Kurse",
      },
      components: { default: AdminCourse },
    },
  ],
};

const routes = [
  {
    path: "/",
    redirect: "/dashboard",
    name: "Home",
    meta: {
      requiresAuth: true,
    },
  },
  componentsMenu,
  tablesMenu,
  mapsMenu,
  pagesMenu,
  authPages,
  coachRoutes,
  adminRoutes,
  {
    path: "/",
    component: DashboardLayout,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "dashboard",
        name: "Dashboard",
        components: { default: Dashboard },
      },
      {
        path: "Newsfeed",
        name: "Newsfeed",
        meta: {
          title: "News",
        },
        components: { default: NewsFeed },
      },
      {
        path: "News/:newsID",
        name: "News",
        props: true,
        meta: {
          title: "Newsartikel",
        },
        components: { default: News },
      },
      {
        path: "Favourites",
        name: "Favoriten",
        meta: {
          title: "Favoriten",
        },
        components: { default: Favourites },
      },
      {
        path: "Courses",
        name: "Courses",
        meta: {
          title: "Kurse",
        },
        components: { default: Courses },
      },
      {
        path: "courses/category/:categoryID",
        name: "Kategorie Übersicht",
        props: true,
        meta: {
          title: "Kategorie",
        },
        components: { default: Category },
      },
      {
        path: "course/:courseID",
        name: "Kurs",
        props: true,
        meta: {
          title: "Kurs",
        },
        components: { default: Course },
      },
      {
        path: "Exercises",
        name: "Exercises",
        meta: {
          title: "Übungen",
        },
        components: { default: Exercises },
      },
      {
        path: "exercises/category/:categoryID",
        name: "Kategorie Übersicht",
        props: true,
        meta: {
          title: "Kategorien",
        },
        components: { default: ExerciseCategory },
      },
      {
        path: "exercises/exercise/:exerciseID",
        name: "Übung",
        props: true,
        meta: {
          title: "Übung",
        },
        components: { default: ExerciseArticle },
      },
      {
        path: "Coaches",
        name: "Coaches",
        meta: {
          title: "Coaches",
        },
        components: { default: Coaches },
      },
      {
        path: "coaches/:coachID",
        name: "Coach",
        meta: {
          title: "Coach",
        },
        components: { default: Coach },
      },
      {
        path: "abonnement",
        name: "Abonnement",
        meta: {
          title: "Abonnement",
        },
        components: { default: Abonnement },
      },
    ],
  },
];

export default routes;

<template>
  <md-card class="md-card-profile">
    <md-card-content>
      <h6 class="category text-gray">Empfehlen</h6>
      <h4 class="card-title" style="cursor:pointer;" @click="copyAffiliateLink">{{ affiliate_link }}</h4>
      <p class="card-description">
        Mit obigem Empfehlungslink können Sie weitere Benutzer zur Nutzung vom Academy Space einladen.
      </p>
    </md-card-content>
  </md-card>
</template>
<script>

import { storeToRefs } from 'pinia'
import { userStore } from '@/stores/UserStore'

export default {
  name: "affiliate-card",
  props: {
    cardUserImage: {
      type: String,
      default: "./img/faces/marc.jpg",
    },
    buttonColor: {
      type: String,
      default: "",
    },
  },
  setup() {
    const store = userStore()
    const { affiliate_link, affiliate_user} = storeToRefs(store)
    return {
      affiliate_link, affiliate_user
    }
  },
  data() {
    return {};
  },
  methods: {
    getColorButton: function (buttonColor) {
      return "md-" + buttonColor + "";
    },
    copyAffiliateLink() {
      navigator.clipboard.writeText(process.env.VUE_APP_APP_ENDPOINT + '/register/'+this.affiliate_link)
      this.$toast.success("Link kopiert");
    }
  },
};
</script>
<style></style>

<template>
  <div class="content">
    <div class="md-layout">
      <div class="md-layout-item md-medium-size-100 md-size-100">
        <edit-profile-form header-color="green"> </edit-profile-form>
      </div>
      <div class="md-layout-item md-medium-size-100 md-size-50">
        <affiliate-card> </affiliate-card>
      </div>
    </div>
  </div>
</template>

<script>
import { EditProfileForm, AffiliateCard } from "@/pages";
export default {
  components: {
    EditProfileForm,
    AffiliateCard,
  },
};
</script>
<style lang="scss">
.text-right {
  display: flex;
}
</style>

<template>
  <div class="md-layout">
    <EditUser
      @closeEditDialog="closeEditDialog"
      v-if="selectedUser"
      :showDialog="showEditDialog"
      :user="selectedUser"
    ></EditUser>

    <div class="md-layout-item">
      <!-- <md-card> -->
      <TableWrapper
        :title="title"
        :cells="cells"
        :footer="footer"
        :actions="actions"
        :items="items"
        @edit="tableEdit"
        @delete="tableDelete"
      >
      </TableWrapper>
      <!-- </md-card> -->
    </div>
  </div>
</template>

<script>
import Fuse from "fuse.js";
import Swal from "sweetalert2";

// Import Member Store
import { useMemberStore } from "@/stores/MemberStore";
import { useTierStore } from "@/stores/TierStore";
import { useAffiliateGroupStore } from "@/stores/AffiliateGroupStore";
import EditUser from "@/pages/Dashboard/Pages/Admin/User/EditUser";
import TableWrapper from "@/pages/Dashboard/Pages/Admin/Components/TableWrapper.vue";

export default {
  components: {
    EditUser,
    TableWrapper,
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
  },
  setup() {
    const memberStore = useMemberStore();
    const tierStore = useTierStore();
    const affiliateGroupStore = useAffiliateGroupStore();
    return {
      memberStore,
      tierStore,
      affiliateGroupStore,
    };
  },
  data() {
    return {
      title: "Benutzer",
      cells: [
        {
          name: "id",
          label: "ID",
          searchable: true,
        },
        {
          name: "name",
          label: "Name",
          searchable: true,
        },
        {
          name: "email",
          label: "Email",
          searchable: true,
        },
      ],
      actions: ["edit", "delete"],
      items: [],
      footer: ["ID", "Name", "Email", "Aktionen"],
      showEditDialog: false,
      selectedUser: null,
    };
  },
  methods: {
    closeEditDialog(changesDone) {
      this.showEditDialog = false;
      this.selectedUser = null;
      if (changesDone) {
        this.memberStore.getMembers().then((response) => {
          this.items = this.memberStore.users;
        });
      }
    },
    customSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort;
        if (this.currentSortOrder === "desc") {
          return a[sortBy].localeCompare(b[sortBy]);
        }
        return b[sortBy].localeCompare(a[sortBy]);
      });
    },
    tableEdit(item) {
      this.showEditDialog = true;
      this.selectedUser = item;
    },
    tableDelete(user) {
      Swal.fire({
        title: "Sind Sie sicher?",
        text: "Diesen Benutzer wirklich löschen?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ja, löschen!",
        cancelButtonText: "Nein, abbrechen!",
      }).then((result) => {
        if (result.value) {
          this.memberStore.deleteMember(user.id).then(
            (response) => {
              this.memberStore.getMembers().then((response) => {
                this.items = this.memberStore.users;
              });
            },
            (error) => {}
          );
        }
      });
    },
  },
  beforeMount() {
    this.memberStore.getMembers().then(() => {
      this.items = this.memberStore.users;
    });
    this.tierStore.getTiers();
    this.affiliateGroupStore.getGroups();
  },
};
</script>

<style lang="css" scoped>
.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}
</style>

<template>
    <div class="md-layout text-center">
        <EditCategory
            v-if="selectedCategory"
            :showDialog="showEditDialog"
            @closeDialog="closeDialog"
            :item="selectedCategory"
        ></EditCategory>
        <CreateCategory
            :showDialog="showCreateDialog"
            @closeDialog="closeDialog"
        ></CreateCategory>

        <div class="md-layout-item md-size-100 md-small-size-100">
            <md-card>
                <div
                    style="margin: 10px"
                    class="md-layout-item md-size-100 md-small-size-100"
                >
                    <h3>Kategorien</h3>
                    <md-button
                        class="md-success"
                        @click="showCreateDialog = true"
                        >Kategorie hinzufügen</md-button
                    >
                </div>
            </md-card>
        </div>

        <TableWrapper
            title="Testtitle"
            :cells="cells"
            :footer="footer"
            :actions="actions"
            :items="items"
            @edit="tableEdit"
            @delete="tableDelete"
        >
        </TableWrapper>
    </div>
</template>
<script>
import { useCategoryStore } from "@/stores/CategoryStore";
import TableWrapper from "@/pages/Dashboard/Pages/Admin/Components/TableWrapper.vue";
import CreateCategory from "@/pages/Dashboard/Pages/Admin/Category/CreateCategory.vue";
import EditCategory from "@/pages/Dashboard/Pages/Admin/Category/EditCategory.vue";
import Swal from "sweetalert2";

export default {
    components: {
        TableWrapper,
        CreateCategory,
        EditCategory
    },
    setup() {
        const categoryStore = useCategoryStore();
        return {
            categoryStore
        };
    },
    data() {
        return {
            cells: [
                {
                    name: "path",
                    label: "Pfad",
                    searchable: true
                }
            ],
            actions: ["edit", "delete"],
            items: "",
            footer: ["Pfad", "Actions"],
            selectedCategory: null,
            showCreateDialog: false,
            showEditDialog: false
        };
    },
    beforeMount() {
        this.categoryStore.getCategories().then(() => {
            this.items = this.categoryStore.categories;
        });
    },
    mounted() {},
    methods: {
        tableEdit(item) {
            this.selectedCategory = item;
            this.showEditDialog = true;
        },
        tableDelete(category) {
            Swal.fire({
                title: "Sind Sie sicher?",
                text: "Diese Kategorie wirklich löschen?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Ja, löschen!",
                cancelButtonText: "Nein, abbrechen!"
            }).then((result) => {
                if (result.value) {
                    this.categoryStore.deleteCategory(category.id).then(() => {
                        this.categoryStore.getCategories().then(() => {
                            this.items = this.categoryStore.categories;
                        });
                    });
                }
            });
        },
        closeDialog(withChanges) {
            this.selectedCategory = null;
            if (withChanges) {
                this.showEditDialog = false;
                this.showCreateDialog = false;
                // Reload table data
                this.categoryStore.getCategories().then((response) => {
                    this.items = this.categoryStore.categories;
                });
            } else {
                this.showCreateDialog = false;
                this.showEditDialog = false;
            }
        },
    },
    watch: {
        showDialog: function (val) {
            if (val) {
                this.categoryStore.getCategories().then(() => {
                    this.items = this.categoryStore.categories;
                });
            }
        }
    }
};
</script>
<style scoped>
.md-field.md-form-group:after {
    width: 100% !important;
}
</style>

<template>
  <div class="md-layout text-center">
    <div
      class="md-layout-item md-size-33 md-medium-size-50 md-small-size-70 md-xsmall-size-100"
    >
      <login-card header-color="green">
        <h4 slot="title" class="title">Registrieren</h4>
        <md-field class="md-form-group" slot="inputs">
          <label>Name</label>
          <md-input v-model="name" type="text"></md-input>
        </md-field>
        <md-field class="md-form-group" slot="inputs">
          <md-icon>email</md-icon>
          <label>Email Adresse</label>
          <md-input v-model="email" type="email"></md-input>
        </md-field>
        <md-field class="md-form-group" slot="inputs">
          <md-icon>lock_outline</md-icon>
          <label>Passwort</label>
          <md-input type="password" v-model="password"></md-input>
        </md-field>
        <md-field class="md-form-group" slot="inputs">
          <md-icon>lock_outline</md-icon>
          <label>Passwort wiederholen</label>
          <md-input type="password" v-model="password_confirmation"></md-input>
        </md-field>
        <md-field class="md-form-group" slot="inputs">
          <label>Empfehlungscode</label>
          <md-input type="text" v-model="affiliate_link"></md-input>
        </md-field>
        <md-checkbox name="accept" v-model="accept" slot="inputs">
          <label for="accept">
            Ich akzeptiere die
            <a v-on:click.stop="openTermsOfService()">AGB</a> und die
            <a v-on:click.stop="openPrivacyPolicy()">Datenschutzerklärung</a>
          </label>
        </md-checkbox>
        <md-button
          @click="$router.push('/login')"
          slot="footer"
          class="md-simple md-success md-lg"
        >
          Login
        </md-button>
        <md-button
          @click="register()"
          :disabled="isValid()"
          slot="footer"
          class="md-simple md-success md-lg"
        >
          Registrieren
        </md-button>
      </login-card>
    </div>
  </div>
</template>
<script>
import { LoginCard } from "@/components";
import request from "@/utils/request";
import { userStore } from "@/stores/UserStore";

export default {
  components: {
    LoginCard,
  },
  setup() {
    const store = userStore();
    return {
      store,
    };
  },
  data() {
    return {
      name: null,
      email: "",
      password: null,
      password_confirmation: null,
      checkboxChecked: false,
      affiliate_link: null,
      termsOfServiceLink: process.env.VUE_APP_APP_ENDPOINT + "/terms",
      privacyPolicyLink: process.env.VUE_APP_APP_ENDPOINT + "/privacy",
      accept: false,
    };
  },
  mounted() {
    this.email = "";
    this.password = "";
    // Get affiliate_link from url
    let affiliate_link = this.$route.params.affiliate_link;
    if (affiliate_link) {
      this.affiliate_link = affiliate_link;
    }
  },
  methods: {
    openTermsOfService(e) {
      window.open(this.termsOfServiceLink);
    },
    openPrivacyPolicy(e) {
      window.open(this.privacyPolicyLink);
    },
    isValid() {
      return !(
        this.email.length > 0 &&
        this.password.length > 0 &&
        this.accept &&
        this.password === this.password_confirmation &&
        this.name.length > 0
      );
    },
    async register() {
      this.loading = true; // can use this to triggle a :disabled on login button
      this.errors = null;

      try {
        request.get("/sanctum/csrf-cookie");
        request
          .post("/register", {
            email: this.email,
            password: this.password,
            name: this.name,
            password_confirmation: this.password_confirmation,
            terms: this.accept,
            affiliate_link: this.affiliate_link,
          })
          .then((response) => {
            this.store.login();
            this.$router.push("/dashboard");
          });

        // Do something amazing
      } catch (error) {
        this.errors = error.response && error.response.data.errors;
      }

      this.loading = false;
    },
    googleLogin() {
      request.get("/api/login/google").then((response) => {
        window.location.href = response.data.url;
      });
    },
  },
};
</script>

<style scoped>
.md-checkbox {
  display: flex;
}
</style>

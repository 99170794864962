<template>
  <div class="md-layout text-center">
    <div
      class="md-layout-item md-size-50 md-medium-size-100 md-small-size-100 md-xsmall-size-100"
    >
      <login-card header-color="blue" class="zoom-mobile">
        <h4 slot="title" class="title">Anmelden</h4>
        <div class="g-signin2" data-onsuccess="onSignIn"></div>
        <md-button
          @click="googleLogin()"
          slot="buttons"
          href="#google"
          class="md-raised md-success"
          style="height: 40px"
        >
          Mit Google fortfahren
        </md-button>
        <md-field class="md-form-group" slot="inputs">
          <md-icon>email</md-icon>
          <label>Email...</label>
          <md-input v-model="email" type="email"></md-input>
        </md-field>
        <md-field class="md-form-group" slot="inputs">
          <md-icon>lock_outline</md-icon>
          <label>Password...</label>
          <md-input type="password" v-model="password"></md-input>
        </md-field>
        <md-button
          @click="login()"
          :disabled="isValid()"
          slot="footer"
          class="md-simple md-success md-lg"
        >
          Login
        </md-button>
        <p slot="register">
          Noch ohne Account?
          <router-link :to="{ path: '/register' }">Registrieren</router-link>
        </p>
        <p slot="forgot">
          <router-link :to="{ path: '/forgot' }"
            >Passwort zurücksetzen</router-link
          >
        </p>
      </login-card>
    </div>
  </div>
</template>
<script>
import { LoginCard } from "@/components";
import request from "@/utils/request";
import { userStore } from "@/stores/UserStore";

export default {
  components: {
    LoginCard,
  },
  setup() {
    const store = userStore();
    return {
      store,
    };
  },
  data() {
    return {
      email: null,
      password: null,
      registrationLink: process.env.VUE_APP_APP_ENDPOINT + "/register",
    };
  },
  mounted() {
    this.email = "";
    this.password = "";
  },
  methods: {
    isValid() {
      return !(this.email.length > 0 && this.password.length > 0);
    },
    async login() {
      this.loading = true; // can use this to triggle a :disabled on login button
      this.errors = null;

      try {
        request.get("/sanctum/csrf-cookie");
        request
          .post("/login", {
            email: this.email,
            password: this.password,
          })
          .then((response) => {
            this.store.login();
            this.$router.push("/newsfeed");
          });

        // Do something amazing
      } catch (error) {
        this.errors = error.response && error.response.data.errors;
      }

      this.loading = false;
    },
    googleLogin() {
      request.get("/api/login/google").then((response) => {
        window.location.href = response.data.url;
      });
    },
  },
};
</script>

<style>
@media (max-width: 991px) {
  .zoom-mobile {
    /*zoom:2;*/
  }
}
</style>

<template>
    <div
        @click="$router.push('/coaches/' + coach.id)"
        class="card-wrap"
        ref="card"
    >
        <div
            style="position: relative"
            class="card"
            :class="{ highlight: coach.message }"
            :style="cardStyle"
        >
            <div class="card-message">{{ coach.name }}</div>
            
            <div class="card-bg" :style="[cardBgTransform, cardBgImage]"></div>
            <div class="card-info">
                <slot name="header"> </slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    mounted() {
        this.width = this.$refs.card.offsetWidth;
        this.height = this.$refs.card.offsetHeight;
    },
    props: ["coach"],
    data: () => ({
        width: 0,
        height: 0,
        mouseX: 0,
        mouseY: 0,
        mouseLeaveDelay: null
    }),
    computed: {
        mousePX() {
            return this.mouseX / this.width;
        },
        mousePY() {
            return this.mouseY / this.height;
        },
        cardStyle() {
            const rX = this.mousePX * 30;
            const rY = this.mousePY * -30;
            return {
                transform: `rotateY(${rX}deg) rotateX(${rY}deg)`
            };
        },
        cardBgTransform() {
            const tX = this.mousePX * -40;
            const tY = this.mousePY * -40;
            return {
                transform: `translateX(${tX}px) translateY(${tY}px)`
            };
        },
        cardBgImage() {
            if (!this.coach.img) {
                //this.coach.img = '/resources/default.jpg';
            }
            return {
                backgroundImage: `url(${this.coach.img})`
            };
        }
    },
    methods: {
        handleMouseMove(e) {
            this.mouseX = e.pageX - this.$refs.card.offsetLeft - this.width / 2;
            this.mouseY = e.pageY - this.$refs.card.offsetTop - this.height / 2;
        },
        handleMouseEnter() {
            clearTimeout(this.mouseLeaveDelay);
        },
        handleMouseLeave() {
            this.mouseLeaveDelay = setTimeout(() => {
                this.mouseX = 0;
                this.mouseY = 0;
            }, 1000);
        }
    }
};
</script>

<style lang="scss" scoped>
$hoverEasing: cubic-bezier(0.23, 1, 0.32, 1);
$returnEasing: cubic-bezier(0.445, 0.05, 0.55, 0.95);

a {
    &:hover {
        text-decoration: none !important;
    }
}

a:hover {
    text-decoration: none !important;
}

.card-message {
    padding-left: 10px !important;
    z-index: 1;
    text-decoration: none !important;
    position: absolute;
    top: 0.5rem;
    color: white !important;
    font-weight: bold;
    padding: 0 5px;

    i {
        text-align: right;
    }
}

.favorite {
    &:hover {
        transform: scale(1.2);
        color: yellow;
    }

    &:focus {
        color: yellow;
    }
}

body {
    margin: 40px 0;
    font-family: "Raleway";
    font-size: 14px;
    font-weight: 500;
    background-color: #bcaaa4;
    -webkit-font-smoothing: antialiased;
}

.title {
    font-family: "Raleway";
    font-size: 24px;
    font-weight: 700;
    color: #5d4037;
    text-align: center;
}

p {
    line-height: 1.5em;
}

h1 + p,
p + p {
    margin-top: 10px;
}

.container {
    padding: 40px 80px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.card-wrap {
    margin: 10px;
    transform: perspective(800px);
    transform-style: preserve-3d;
    cursor: pointer;

    .card-info {
        padding-top: 10px;
        padding-bottom: 30px;
    }

    &:hover {
        .card-info {
            // transform: translateY(0);
        }
        .card-info p {
            opacity: 1;
        }
        /*.card-info, .card-info p {
            transition: 0.6s $hoverEasing;
            }*/
        /*.card-info:after {
            transition: 5s $hoverEasing;
            opacity: 1;
            transform: translateY(0);
            }*/
        .card-bg {
            /*transition: 
                    0.6s $hoverEasing,
                    opacity 5s $hoverEasing;
                opacity: 0.8; */
            transform: scale(1.2) !important;
        }
        .card {
            // transition:
            // 0.6s $hoverEasing;
            // box-shadow 2s $hoverEasing;
            box-shadow: rgba(white, 0.2) 0 0 40px 5px, rgba(white, 1) 0 0 0 1px,
                rgba(black, 0.66) 0 30px 60px 0, inset #333 0 0 0 5px,
                inset white 0 0 0 6px;
        }
    }
}

.highlight {
    border: 1px yellow solid;
}

.card {
    margin: 0 auto;
    flex: 0 0 240px;
    // width: 175px;
    // height: 240px;
    width: 210px;
    height: 288px;
    background-color: #333;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: rgba(black, 0.66) 0 30px 60px 0, inset #333 0 0 0 5px,
        inset rgba(white, 0.5) 0 0 0 6px;
    transition: 1s $returnEasing;

    .card-info {
        margin-bottom: 15px;
        font-size: 20px;
    }
    /*box-shadow:
            rgba(white, 0.2) 0 0 40px 5px,
            rgba(white, 1) 0 0 0 1px,
            rgba(black, 0.66) 0 30px 60px 0,
            inset #333 0 0 0 5px,
            inset white 0 0 0 6px;*/
}

.card-bg {
    opacity: 0.5;
    position: absolute;
    // top: -20px; left: -20px;
    width: 100%;
    height: 100%;
    padding: 20px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    transition: 1s $returnEasing, opacity 5s 1s $returnEasing;
    pointer-events: none;
    transition: 0.6s $hoverEasing, opacity 5s $hoverEasing;
    opacity: 0.8;
}

.card-info {
    padding: 20px;
    position: absolute;
    bottom: 0;
    color: #fff;
    transform: translateY(40%);
    transition: 0.6s 1.6s cubic-bezier(0.215, 0.61, 0.355, 1);

    p {
        opacity: 0;
        text-shadow: rgba(black, 1) 0 2px 3px;
        transition: 0.6s 1.6s cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    * {
        position: relative;
        z-index: 1;
    }

    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(
            to bottom,
            transparent 0%,
            rgba(#000, 0.6) 100%
        );
        background-blend-mode: overlay;
        opacity: 0;
        //transform: translateY(100%);
        transition: 5s 1s $returnEasing;
    }
}

.card-info h1 {
    font-family: "Playfair Display";
    font-size: 36px;
    font-weight: 700;
    text-shadow: rgba(black, 0.5) 0 10px 10px;
}
</style>

<template>
  <div v-if="course">
    <div
      @click="$router.push('/course/' + mutableCourse.id)"
      :class="{
        blocked: !mutableCourse.unlocked,
        accessable: mutableCourse.unlocked,
      }"
      v-if="mutableCourse.message == 'null' && mutableCourse.unlocked"
    >
      <div class="card-wrap" ref="card">
        <div style="position: relative" class="card custom-card">
          <div v-if="!mutableCourse.unlocked" class="blocked-content">
            Fehlender Zugang
          </div>
          <i
            v-if="!mutableCourse.favorite"
            @click.stop="favorite(mutableCourse.id)"
            ><font-awesome-icon
              class="course-favorite-position favorite"
              size="lg"
              icon="fa-regular fa-star"
            />
          </i>
          <i
            v-if="mutableCourse.favorite"
            @click.stop="unfavorite(mutableCourse.favorite.id)"
            ><font-awesome-icon
              class="course-favorite-position current-favorite"
              size="lg"
              icon="fa-solid fa-star"
            />
          </i>
          <div
            class="card-bg"
            :style="'background-image: url(' + mutableCourse.banner + ');'"
          ></div>
          <div class="card-info">
            {{ mutableCourse.name }}
          </div>
        </div>
      </div>
    </div>
    <div
      @click="$router.push('/course/' + mutableCourse.id)"
      :class="{
        blocked: !mutableCourse.unlocked,
        accessable: mutableCourse.unlocked,
      }"
      v-if="mutableCourse.message != 'null' && mutableCourse.unlocked"
    >
      <div class="card-wrap" ref="card">
        <div
          style="position: relative"
          class="card custom-card"
          :class="{ highlight: mutableCourse.message }"
        >
          <div class="course-message" v-if="mutableCourse.message">
            {{ mutableCourse.message }}
          </div>
          <i
            v-if="!mutableCourse.favorite"
            @click.stop="favorite(mutableCourse.id)"
            ><font-awesome-icon
              class="course-favorite-position favorite"
              size="lg"
              icon="fa-regular fa-star"
            />
          </i>
          <i
            v-if="mutableCourse.favorite"
            @click.stop="unfavorite(mutableCourse.favorite.id)"
            ><font-awesome-icon
              class="course-favorite-position current-favorite"
              size="lg"
              icon="fa-solid fa-star"
            />
          </i>
          <div
            class="card-bg"
            :style="'background-image: url(' + mutableCourse.banner + ');'"
          ></div>
          <div class="card-info">
            {{ mutableCourse.name }}
          </div>
        </div>
      </div>
    </div>
    <div v-if="!mutableCourse.unlocked" class="blocked">
      <div class="card-wrap" ref="card">
        <div
          style="position: relative"
          class="card custom-card"
          :class="{ highlight: mutableCourse.message }"
        >
          <div v-if="!mutableCourse.unlocked" class="blocked-content">
            <span v-html="cardLockSVG"></span>
            <p style="position:center !important; !important;">
              {{ subscription_message }}
              <span v-html="matching_tiers"></span>
            </p>
          </div>
          <div class="course-message" v-if="mutableCourse.message">
            {{ mutableCourse.message }}
          </div>
          <i
            v-if="!mutableCourse.favorite"
            @click.stop="favorite(mutableCourse.id)"
            ><font-awesome-icon
              class="course-favorite-position favorite"
              size="lg"
              icon="fa-regular fa-star"
            />
          </i>
          <i
            v-if="mutableCourse.favorite"
            @click.stop="unfavorite(mutableCourse.favorite.id)"
            ><font-awesome-icon
              class="course-favorite-position current-favorite"
              size="lg"
              icon="fa-solid fa-star"
            />
          </i>
          <div
            class="card-bg"
            :style="'background-image: url(' + mutableCourse.banner + ');'"
          ></div>
          <div class="card-info">
            {{ mutableCourse.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";

export default {
  props: {
    course: Object,
    directoryID: Number,
  },
  emits: ["unfavorite"],
  data() {
    return {
      cardLockSVG:
        '<svg style="height: 50px;margin: 25 auto 10 auto;" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="lock" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M400 224h-24v-72C376 68.2 307.8 0 224 0S72 68.2 72 152v72H48c-26.5 0-48 21.5-48 48v192c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V272c0-26.5-21.5-48-48-48zm-104 0H152v-72c0-39.7 32.3-72 72-72s72 32.3 72 72v72z"></path></svg>',
      matching_tiers: "",
      subscription_message: "",
      mutableCourse: "",
    };
  },
  created() {
    this.mutableCourse = this.course;
  },
  mounted() {
    if (
      !this.mutableCourse.unlocked &&
      this.mutableCourse.matching_tiers.length > 0
    ) {
      if (this.mutableCourse.matching_tiers.length == 1) {
        this.subscription_message = "Zugänglich mit Abo:";
      } else {
        this.subscription_message = "Zugänglich mit Abos:";
      }
      this.mutableCourse.matching_tiers.forEach((tier) => {
        this.matching_tiers = this.matching_tiers + (tier.name + ", ");
      });
      this.matching_tiers = this.matching_tiers.trim();
      this.matching_tiers = this.matching_tiers.substring(
        0,
        this.matching_tiers.length - 1
      );
      if (this.mutableCourse.matching_tiers.length > 1) {
        var lastIndex = this.matching_tiers.lastIndexOf(",");
        this.matching_tiers =
          this.matching_tiers.substr(0, lastIndex) +
          " und " +
          this.matching_tiers.substr(lastIndex + 1);
      }
    }
  },
  methods: {
    handleMouseMove(e) {
      this.mouseX = e.pageX - this.$refs.card.offsetLeft - this.width / 2;
      this.mouseY = e.pageY - this.$refs.card.offsetTop - this.height / 2;
    },
    handleMouseEnter() {
      clearTimeout(this.mouseLeaveDelay);
    },
    handleMouseLeave() {
      this.mouseLeaveDelay = setTimeout(() => {
        this.mouseX = 0;
        this.mouseY = 0;
      }, 1000);
    },
    favorite(courseID) {
      request
        .post("/api/favorite", {
          id: courseID,
          type: "course",
        })
        .then((response) => {
          this.mutableCourse.favorite = response.data.favorite;
        });
    },
    unfavorite(favoriteID) {
      request
        .put("/api/favorite/" + favoriteID, {
          id: favoriteID,
          type: "course",
        })
        .then((response) => {
          this.mutableCourse.favorite = false;
        });
    },
  },
};
</script>

<style scoped>
.accessable {
  cursor: pointer !important;
  text-decoration: none !important;
  color: white !important;
  /*width: 100% !important;*/
  margin: 0 10px;
}

.blocked {
  cursor: not-allowed !important;
  text-decoration: none !important;
  color: white !important;
  /*width:100% !important;*/
  margin: 0 10px;
}

.blocked-content {
  background: rgba(0, 0, 0, 0.5);
  background-size: cover;
  z-index: 999999 !important;
  height: 100% !important;
  width: 100% !important;
  position: absolute;
  text-align: center;
  border-radius: 5px;
}

.lockSVG {
  height: 50px;
  margin: 15px 15px;
}

.custom-card {
  width: 100% !important;
  height: 200px;
  margin-bottom: 16px;
}

.link,
.link:hover,
.link:focus,
.link:active {
  text-decoration: none;
  color: inherit;
}
</style>

<style lang="scss" scoped>
.course-favorite-position {
  position: absolute !important;
  top: 0.75rem !important;
  right: 0.5rem !important;
  z-index: 1 !important;
}

.course-message {
  position: absolute;
  padding-left: 10px !important;
  z-index: 1 !important;
  top: 1rem;
  left: 1rem !important;
  text-decoration: none !important;
  color: white;

  &:hover {
    text-decoration: none !important;
    color: white !important;
  }
}

$hoverEasing: cubic-bezier(0.23, 1, 0.32, 1);
$returnEasing: cubic-bezier(0.445, 0.05, 0.55, 0.95);

.favorite-position {
  position: absolute !important;
  float: right;
  left: 64rem;
  top: 0.75rem !important;
  right: 1rem !important;
  z-index: 1 !important;
}

.favorite {
  &:hover {
    transform: scale(1.2);
    color: yellow;
  }

  &:focus {
    color: yellow;
  }
}

.current-favorite {
  color: yellow;
}

.title {
  font-family: "Raleway";
  font-size: 24px;
  font-weight: 700;
  color: #5d4037;
  text-align: center;
}

p {
  line-height: 1.5em;
}

h1 + p,
p + p {
  margin-top: 10px;
}

.container {
  padding: 40px 80px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.accessable {
  .card-wrap {
    &:hover {
      .card-info p {
        opacity: 1;
      }

      .card-bg {
        transform: scale(1.2) !important;
      }
      .card {
        box-shadow: rgba(white, 0.2) 0 0 40px 5px, rgba(white, 1) 0 0 0 1px,
          rgba(black, 0.66) 0 30px 60px 0, inset #333 0 0 0 5px,
          inset white 0 0 0 6px;
      }
    }
  }
}

.card-wrap {
  margin: 10px;
  transform: perspective(800px);
  transform-style: preserve-3d;

  .card-info {
    padding-top: 10px;
    padding-bottom: 30px;
  }
}

.highlight {
  border: 1px yellow solid;
}

.card {
  flex: 0 0 240px;
  width: 100% !important;
  height: 150px;
  background-color: #333;
  overflow: hidden;
  border-radius: 5px;
  box-shadow: rgba(black, 0.66) 0 30px 60px 0, inset #333 0 0 0 5px,
    inset rgba(white, 0.5) 0 0 0 6px;
  transition: 1s $returnEasing;

  .card-info {
    margin-bottom: 15px;
    font-size: 20px;
  }
}

.card-bg {
  opacity: 0.5;
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 20px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  transition: 1s $returnEasing, opacity 5s 1s $returnEasing;
  pointer-events: none;
  transition: 0.6s $hoverEasing, opacity 5s $hoverEasing;
  opacity: 0.8;
}

.card-info {
  padding: 20px;
  position: absolute;
  bottom: 0;
  color: #fff;
  transform: translateY(40%);
  transition: 0.6s 1.6s cubic-bezier(0.215, 0.61, 0.355, 1);

  p {
    opacity: 0;
    text-shadow: rgba(black, 1) 0 2px 3px;
    transition: 0.6s 1.6s cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  * {
    position: relative;
    z-index: 1;
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(
      to bottom,
      transparent 0%,
      rgba(#000, 0.6) 100%
    );
    background-blend-mode: overlay;
    opacity: 0;
    transition: 5s 1s $returnEasing;
  }
}

.card-info h1 {
  font-family: "Playfair Display";
  font-size: 36px;
  font-weight: 700;
  text-shadow: rgba(black, 0.5) 0 10px 10px;
}
</style>

import { defineStore } from 'pinia'
import request from '@/utils/request'

// useStore could be anything like useUser, useCart
// the first argument is a unique id of the store across your application
export const useTierStore = defineStore('tierStore', {
    state: () => ({
        tiers: []
    }),
    actions: {
        async getTiers() {
            await request.get('/api/tier').then(response => {
                this.tiers = response.data
            })
        },
        async updateTier(tier) {
            await request.put('/api/tier', tier).then(response => {
                this.getTiers()
            })
        }
    }
})
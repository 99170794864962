import { render, staticRenderFns } from "./CourseCategoryComponent.vue?vue&type=template&id=8bf04826&scoped=true&"
import script from "./CourseCategoryComponent.vue?vue&type=script&lang=js&"
export * from "./CourseCategoryComponent.vue?vue&type=script&lang=js&"
import style0 from "./CourseCategoryComponent.vue?vue&type=style&index=0&id=8bf04826&prod&scoped=true&lang=css&"
import style1 from "./CourseCategoryComponent.vue?vue&type=style&index=1&id=8bf04826&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8bf04826",
  null
  
)

export default component.exports
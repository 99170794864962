<template>
  <div class="md-layout text-center">
    <EditGroup v-if="selectedGroup" :item="selectedGroup" :showDialog="showEditDialog" @closeDialog="closeDialog"></EditGroup>
    <TableWrapper 
        :title="title"
        :cells="cells"
        :footer="footer"
        :actions="actions"
        :items="items"
        @edit="tableEdit"
    >
    </TableWrapper>
  </div>
</template>

<script>

import { useGroupStore } from '@/stores/GroupStore';
import TableWrapper from '@/pages/Dashboard/Pages/Admin/Components/TableWrapper.vue';
import EditGroup from '@/pages/Dashboard/Pages/Admin/Group/EditGroup.vue';

export default {
    components: {
        TableWrapper,
        EditGroup
    },
    setup() {
        const groupStore = useGroupStore()
        return {
            groupStore
        }
    },    
    data() {
        return {
            title: 'Gruppen',
            cells: [
                {
                    name: 'name',
                    label: 'Name',
                    searchable: true,
                },
                {
                    name: 'first',
                    label: 'Während dem ersten Jahr',
                    searchable: true,
                },
                {
                    name: 'after',
                    label: 'Nach dem ersten Jahr',
                    searchable: true,
                },
            ],
            actions: [
                "edit",
            ],
            items: [],
            footer: [
                'Name',
                'Während dem ersten Jahr',
                'Nach dem ersten Jahr',
                'Actions'
            ],
            showEditDialog: false,
            selectedGroup: null,
        };
    },
    beforeMount() {
        this.groupStore.getGroups().then(response => {
            this.items = this.groupStore.groups
        })
    },
    methods: {
        tableEdit(item) {
            this.selectedGroup = item
            this.showEditDialog = true
        },
        closeDialog(hasChanges) {
            this.selectedGroup = null
            this.showEditDialog = false
            if(hasChanges) {
                this.groupStore.getGroups().then(response => {
                    this.items = this.groupStore.groups
                })
            }
        }
    }
}
</script>

<style>

</style>
<template>
    <div>
        <md-dialog :md-active.sync="showDialog">

            <div
            class="
                md-layout-item
                md-size-100
                md-medium-size-100
                md-small-size-100
                md-xsmall-size-100
            "
            >
            <md-field class="md-form-group">
                <md-icon>thumb_up</md-icon>
                <label>Titel</label>
                <md-input typeID="content" v-model="name"></md-input>
            </md-field>
            <md-field class="md-form-group">
                <label>Typ</label>
                <md-select v-model="typeID">
                    <md-option v-for="articleType in articleTypeStore.types" :key="articleType.id" :value="articleType.id">{{ articleType.name }}</md-option>
                </md-select>
            </md-field>
            <md-checkbox v-model="draft" class="md-success">Entwurf</md-checkbox>
            <md-field class="md-form-group">
                <label>Bild</label>
                <md-file @change="handleFileInput" />
            </md-field>
            <md-field class="md-form-group">
                <label>Vorschautext</label>
                <md-textarea typeID="content" v-model="preview"></md-textarea>
            </md-field>
            <md-field class="md-form-group">
                <VueEditor v-model="content" :editor-config="editorConfig" :editor-toolbar="editorConfig.toolbar"></VueEditor>
            </md-field>
            </div>

            <md-dialog-actions>
            <md-button class="md-success" @click="$emit('closeEditArticle')">Close</md-button>
            <md-button class="md-success" @click="updateArticle">Speichern</md-button>
            </md-dialog-actions>
        </md-dialog>

    </div>   
</template>

<script>

  import { useNewsfeedStore } from '@/stores/NewsfeedStore';
  import { useArticleTypeStore } from '@/stores/ArticleTypeStore';
  import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';
  import { VueEditor } from "vue2-editor";

  export default {
    name: 'EditArticle',
    components: {
      VueEditor,
    },
    props: [
        'article',
        'showDialog'
    ],
    data() {
        return {
            name: this.article.title,
            typeID: this.article.typeID,
            draft: this.article.draft,
            preview: this.article.preview,
            content: this.article.content,
            image: this.article.image,
            editorConfig: {
                toolbar: [
                    ["bold", "italic", "underline", "strike"],
                    ["blockquote", "code-block"],
                    [{ header: 1 }, { header: 2 }],
                    [{ list: "ordered" }, { list: "bullet" }],
                    [{ script: "sub" }, { script: "super" }],
                    [{ indent: "-1" }, { indent: "+1" }],
                    [{ direction: "rtl" }],
                    [{ size: ["small", false, "large", "huge"] }],
                    [{ header: [1, 2, 3, 4, 5, 6, false] }],
                    [{ color: [] }, { background: [] }],
                    [{ font: [] }],
                    [{ align: [] }],
                    ["clean"],
                    ["link", "image", "video"],
                ],
            },
        }
    },
    setup() {
      const newsFeedStore = useNewsfeedStore()
      const articleTypeStore = useArticleTypeStore()
      return {
        newsFeedStore,
        articleTypeStore
      }
    },
    beforeMount() {
        this.content = JSON.parse(this.content)
    },  
    mounted() {
    },
    methods: {
        convertDeltaToHtml() {
            var converter = new QuillDeltaToHtmlConverter(this.exercise.content.ops, {});
            this.exercise.content = converter.convert(); 
        },
        handleFileInput(event) {
            const file = event.target.files[0]
            this.image = file
        },
        updateArticle() {
            // Convert article to formdatra
            const formData = new FormData();
            formData.append('name', this.name);
            formData.append('typeID', this.typeID);
            formData.append('draft', this.draft);
            formData.append('image', this.image);
            formData.append('preview', this.preview);
            formData.append('content', this.content);
            // Update article
            this.newsFeedStore.updateArticle(this.article.id, formData);
            // Close dialog
            this.$emit("closeEditArticle");
        },
    },
  }
</script>

<style lang="scss" scoped>
  .md-dialog /deep/.md-dialog-container {
    max-width: 768px;
  }

  
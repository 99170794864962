<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-20 md-small-size-100 text-center">
      <img :src="coachStore.coach.img" />
      <md-card class="custom-profile-card">
        <h4>{{ coachStore.coach.name }}</h4>
        <p>{{ coachStore.coach.slogan }}</p>

        <h4>Homepage</h4>
        <a target="_blank" :href="'https://' + coachStore.coach.homepage">{{
          coachStore.coach.homepage
        }}</a>
      </md-card>
    </div>
    <div class="md-layout-item md-size-66 md-small-size-100 text-center">
      <md-card class="text-center custom-profile-card">
        <h4>Über mich</h4>
        <p>{{ coachStore.coach.about }}</p>
      </md-card>
      <div style="text: center" class="wrapper">
        <div
          v-for="showcase in showcaseStore.showcases.course"
          :key="'A' + showcase.id"
          style="text-align: center; justify-content: center"
          class="mx-auto row"
        >
          <h3>
            {{ showcase.title }}
          </h3>
          <p v-html="showcase.description"></p>
          <CourseComponent
            @favoriteAction="favoriteAction"
            :directoryID="showcase.course.directory_id"
            :course="showcase.course"
            :key="showcase.course.id"
            class="md-layout-item md-size-100"
          ></CourseComponent>
        </div>
        <div class="mx-auto row md-layout">
          <div
            v-for="showcase in showcaseStore.showcases.exercise"
            :key="showcase.id"
            class="md-layout-item md-xlarge-size-33 md-large-size-50 md-medium-size-100 md-small-size-100 md-xsmall-size-100"
            style="text-align: center; justify-content: center"
          >
            <h3>
              {{ showcase.title }}
            </h3>
            <p v-html="showcase.description"></p>

            <CardComponent
              class=""
              @favoriteAction="favoriteAction"
              :directoryID="showcase.exercise.directory_id"
              :mediaEntry="showcase.exercise"
            ></CardComponent>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import request from "@/utils/request";
import CourseComponent from "@/pages/Dashboard/Courses/CourseComponent.vue";
import CardComponent from "@/pages/Dashboard/Components/CardComponent";
import { useCoachStore } from "@/stores/CoachStore";
import { useShowcaseStore } from "@/stores/ShowcaseStore";

export default {
  components: {
    CourseComponent,
    CardComponent,
  },
  setup() {
    const coachStore = useCoachStore();
    const showcaseStore = useShowcaseStore();
    return { coachStore, showcaseStore };
  },
  data() {
    return {
      coach: [],
      filterCoachID: [],
      filterResults: [],
      filterResultsEx: [],
      coaches: [],
      filter: {
        coachID: [],
      },
      filterex: {
        userID: [],
      },
      presentation: "simple",
    };
  },
  beforeMount() {
    let coachID = this.$route.params.coachID;
    this.coachStore.getCoach(coachID).then((response) => {
      this.coach = this.coachStore.coach;
    });
  },
  mounted() {
    this.filter.coachID[0] = this.$route.params.coachID;
    this.filterex.userID[0] = this.$route.params.coachID;

    this.getShowcases();
  },
  methods: {
    getShowcases() {
      this.showcaseStore.index(this.filter.coachID).then((response) => {});
    },
    getCourses() {
      request
        .post("/api/filter/course", {
          filters: this.filter,
          presentation: this.presentation,
        })
        .then((response) => {
          this.filterResults = response.data;
        });
    },
    getExercises() {
      request
        .post("/api/filter/media", {
          filters: this.filterex,
          presentation: this.presentation,
        })
        .then((response) => {
          this.filterResultsEx = response.data;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-profile-card {
  margin-top: 0px !important;
}
</style>

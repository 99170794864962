// =========================================================
// * Vue Material Dashboard PRO - v1.5.0
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vue-material-dashboard-pro
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
import Vue from "vue";
import VueRouter from "vue-router";
import DashboardPlugin from "./material-dashboard";
import VueCookies from "vue-cookies";

// Plugins
import App from "./App.vue";
import Chartist from "chartist";
import router from "@/utils/router";

// Store setup
import { createPinia, PiniaVuePlugin } from "pinia";


import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";

const options = {};

import Vue2Editor from "vue2-editor";
Vue.use(Vue2Editor);

// Quill Imports
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css"; 

Vue.use(Toast, options);

// Custom Css Load
import "./assets/css/custom.css"

// Font awesome setup
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faStar } from "@fortawesome/free-regular-svg-icons";
import {
  faUserSecret,
  faSignOut,
  faStar as faStarSolid,
  faAnglesRight,
  faAnglesLeft,
} from "@fortawesome/free-solid-svg-icons";
import { userStore } from "@/stores/UserStore";

library.add(
  faUserSecret,
  faStar,
  faStarSolid,
  faSignOut,
  faAnglesRight,
  faAnglesLeft
);

import titleMixin from "./mixins/titleMixin";

Vue.mixin(titleMixin);

/* add font awesome icon component */
Vue.component("font-awesome-icon", FontAwesomeIcon);

// plugin setup
Vue.use(VueRouter);
Vue.use(DashboardPlugin);
Vue.use(VueCookies);
Vue.use(PiniaVuePlugin);

const pinia = createPinia();

// global library setup
Vue.prototype.$Chartist = Chartist;

/* eslint-disable no-new */
new Vue({
  el: "#app",
  render: (h) => h(App),
  router,
  pinia,
});

// Check before each route if session is set
router.beforeEach((to, from, next) => {
  // Initialize user store to check for login
  const store = userStore();
  document.title = to.meta.title || "Academy Space";
  if (to.fullPath === "/logout") {
    store.logout().then((response) => {
      store.loggedIn = false;
      next("/login");
    });
  }

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.logonStatus) {
      next();
    } else {
      next({
        path: "/login",
        query: { redirect: to.fullPath },
      });
    }
  } else {
    next();
  }
});

import { defineStore } from 'pinia'
import request from '@/utils/request'

// useStore could be anything like useUser, useCart
// the first argument is a unique id of the store across your application
export const useStatusStore = defineStore('statusStore', {
    state: () => ({
        statuses: [
            {
                id: 0,
                name: 'Normaler Zugang'
            },
            {
                id: 1,
                name: 'Eingeschränkter Zugang'
            },
            {
                id: 2,
                name: 'Gesperrt'
            }
        ],
    }),
})
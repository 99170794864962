<template>
    <md-dialog @md-clicked-outside="closeDialog" :md-active.sync="showDialog">

      <md-dialog-content>

        <md-field class="md-form-group">
          <md-icon>thumb_up</md-icon>
          <label>Titel</label>
          <md-input typeID="content" v-model="name"></md-input>
        </md-field>
        <md-field class="md-form-group">
          <label>Typ</label>
          <md-select typeID="content" v-model="newsType">
            <md-option v-for="type in atypes" :key="type.id" :value="type.id">
              {{ type.name }}
            </md-option>
          </md-select>
        </md-field>
        <md-checkbox v-model="draft" class="md-success">Entwurf</md-checkbox>
        <md-field class="md-form-group">
          <label>Bild</label>
          <md-file @change="handleFileInput" />
        </md-field>
        <md-field class="md-form-group">
          <label>Vorschautext</label>
          <md-textarea typeID="content" v-model="preview"></md-textarea>
        </md-field>
        <div style="margin-top:1rem;">
          <VueEditor v-model="content" 	 :editor-config="editorConfig" 	:editor-toolbar="editorConfig.toolbar"></VueEditor>
        </div>
        
      </md-dialog-content>

      <md-dialog-actions>
        <md-button class="md-success" @click="closeDialog">Close</md-button>
        <md-button class="md-success" @click="createNewsfeed()">Erstellen</md-button>
      </md-dialog-actions>
    </md-dialog>
</template>

<script>

  import { useNewsfeedStore } from '@/stores/NewsfeedStore';
  import { useArticleTypeStore } from '@/stores/ArticleTypeStore';
  import { VueEditor } from "vue2-editor";

  export default {
    name: 'CreateNewsfeed',
    components: {
      VueEditor,
    },
    props: [
      'showDialog'
    ],  
    setup() {
      const newsFeedStore = useNewsfeedStore()
      const articleTypeStore = useArticleTypeStore()
      return {
        newsFeedStore,
        articleTypeStore
      }
    },
    data() {
      return {
        name: null,
        atypes: [],
        newsType: 0,
        draft: true,
        image: null,
        preview: null,
        content: null,
        bTypes: [
          { id: 1, name: "test123"},
          { id: 2, name: "test1"}
        ],
        editorConfig: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ direction: "rtl" }],
            [{ size: ["small", false, "large", "huge"] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }],
            [{ font: [] }],
            [{ align: [] }],
            ["clean"],
            ["link", "image", "video"],
          ],
          video: {
            tag: "iframe",
            attributes: {
              style: "width: 100%",
              frameborder: "0",
              allowfullscreen: "",
            },
          },
        },
      };
    },
    beforeMount() {
      this.articleTypeStore.getTypes().then((response) => {
        this.atypes = this.articleTypeStore.types
      });
    },
    mounted() {
    },
    methods: {
      handleFileInput(event) {
        const file = event.target.files[0]
        this.image = file
      },
      closeDialog() {
        this.$emit('closeDialog', false)
      },
      createNewsfeed() {
        let formData = new FormData();
        formData.append('name', this.name);
        formData.append('typeID', this.newsType);
        formData.append('draft', this.draft);
        // Append image to form data
        formData.append('image', this.image);
        formData.append('preview', this.preview);
        formData.append('content', this.content);
        // Reset all variables to null
        this.name = null;
        this.typeID = null;
        this.draft = true;
        this.image = null;
        this.preview = null;
        this.content = null;
        // Create newsfeed
        this.newsFeedStore.createArticle(formData)
        this.$emit("closeDialog", true)
      },
    },
  }
</script>

<style lang="scss" scoped>
  .md-dialog /deep/.md-dialog-container {
    max-width: 768px;
  }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper",class:[
    { 'nav-open': _vm.$sidebar.showSidebar },
    { rtl: _vm.$route.meta.rtlActive } ]},[_c('notifications'),_c('side-bar',{attrs:{"active-color":_vm.sidebarBackground,"background-image":_vm.sidebarBackgroundImage,"data-background-color":_vm.sidebarBackgroundColor}},[_c('user-menu'),_c('template',{slot:"links"},[(_vm.store.type >= 1)?_c('sidebar-item',{attrs:{"link":{
          name: 'Coach',
          icon: 'admin_panel_settings',
          path: '/coach',
        }}}):_vm._e(),(_vm.store.type >= 2)?_c('sidebar-item',{attrs:{"link":{
          name: 'Administration',
          icon: 'admin_panel_settings',
          path: '/admin',
        }}}):_vm._e(),_c('sidebar-item',{attrs:{"link":{
          name: 'Dashboard',
          icon: 'dashboard',
          path: '/Dashboard',
        }},on:{"click":_vm.toggleMenu}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Newsfeed',
          icon: 'feed',
          path: '/newsfeed',
        }},on:{"click":_vm.toggleMenu}}),_c('sidebar-item',{attrs:{"link":{ name: 'Kurse', icon: 'route', path: '/courses' }},on:{"click":_vm.toggleSidebar}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Favoriten',
          icon: 'star',
          path: '/favourites',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Übungen',
          icon: 'run_circle',
          path: '/exercises',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Coaches',
          icon: 'groups',
          path: '/coaches',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Abonnement',
          icon: 'payment',
          path: '/abonnement',
        }}})],1)],2),_c('div',{staticClass:"main-panel"},[_c('top-navbar'),_c('div',{class:{ content: !_vm.$route.meta.hideContent },on:{"click":_vm.toggleSidebar}},[_c('zoom-center-transition',{attrs:{"duration":200,"mode":"out-in"}},[_c('router-view')],1)],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
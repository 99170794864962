import { defineStore } from "pinia";
import request from "@/utils/request";

// useStore could be anything like useUser, useCart
// the first argument is a unique id of the store across your application
export const useCoachStore = defineStore("coachStore", {
  state: () => ({
    coaches: [],
    coach: [],
  }),
  actions: {
    async getCoaches() {
      await request.get("/api/user/coach").then((response) => {
        this.coaches = response.data;
      });
    },
    async getCoach(id) {
      await request.get("/api/user/coach/" + id).then((response) => {
        this.coach = response.data;
      });
    },
    async getSelfCoach() {
      await request.get("/api/user/coach/self").then((response) => {
        this.coach = response.data;
      });
    },
  },
});

<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100 text-center">
      <h2 v-if="category">{{ category.name }}</h2>
    </div>
    <div v-if="children" class="md-layout-item md-size-100 md-layout">
      <CourseCategoryComponent
        v-for="child in children"
        :category="child"
        :key="child.id"
        class="md-layout-item md-size-100"
      ></CourseCategoryComponent>
    </div>
    <div v-if="courses" class="md-layout-item md-size-100 md-layout">
      <CourseComponent
        v-for="course in courses"
        :course="course"
        :courseID="course.id"
        class="md-layout-item md-size-100"
        :key="course.id"
      ></CourseComponent>
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";

import CourseCategoryComponent from "@/pages/Dashboard/Components/CourseCategoryComponent";
import CourseComponent from "@/pages/Dashboard/Courses/CourseComponent.vue";

export default {
  components: {
    CourseCategoryComponent,
    CourseComponent,
  },
  data() {
    return {
      category: null,
      children: null,
      courses: null,
      categoryID: Number,
    };
  },
  mounted() {
    this.loadCategories();
  },
  methods: {
    loadCategories() {
      this.categoryID = this.$route.params.categoryID;
      request
        .get("/api/directory/child/" + this.$route.params.categoryID)
        .then((response) => {
          this.category = response.data;
          this.children = response.data.children;
        });
      request
        .get("/api/directory/child/course/" + this.$route.params.categoryID)
        .then((response) => {
          this.courses = response.data.media;
        });
    },
  },
  watch: {
    "$route.params.categoryID": function () {
      this.loadCategories();
    },
  },
};
</script>

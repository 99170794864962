import { defineStore } from "pinia";
import request from "@/utils/request";

// useStore could be anything like useUser, useCart
// the first argument is a unique id of the store across your application
export const useCourseStore = defineStore("courseStore", {
  state: () => ({
    courses: [],
    course: [],
  }),
  actions: {
    async getAdminCourses() {
      await request.get("/api/course").then((response) => {
        this.courses = response.data;
      });
    },
    async getCoachCourses() {
      await request.get("/api/course/coach").then((response) => {
        this.courses = response.data;
      });
    },
    async getCourse(courseID) {
      await request.get("/api/course/" + courseID).then((response) => {
        this.course = response.data;
      });
    },
    async getAdminCourse(courseID) {
      await request.get("/api/course/admin/" + courseID).then((response) => {
        this.course = response.data;
      });
    },
    async createCourse(course) {
      await request.post("/api/course", course).then((response) => {
        this.getAdminCourses();
      });
    },
    async updateCourse(courseID, course) {
      await request
        .post("/api/course/patch/" + courseID, course)
        .then((response) => {
          this.getAdminCourses();
        });
    },
    async deleteCourse(courseID) {
      await request.delete("/api/course/" + courseID).then((response) => {
        this.getAdminCourses();
      });
    },
    async searchCoachCourses(searchString) {
      await request
        .get("/api/course/coach/search/" + searchString)
        .then((response) => {
          this.courses = response.data;
        });
    },
  },
});

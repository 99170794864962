<template>
  <div class="md-card">
    <div class="md-layout">
      <div
        class="md-layout-item md-size-60 text-center md-small-size-100 md-medium-size-100"
      >
        <h2>{{ courseObject.name }}</h2>
      </div>
      <div
        class="md-layout-item md-size-30 text-cener md-small-size-100 md-medium-size-100"
      >
        <md-button @click="resetTracker()" class="md-button md-success"
          >Kurs neu starten</md-button
        >
      </div>
    </div>
    <div v-if="courseObject['course_media'].length > 0" class="md-layout">
      <div
        id="stepsContainer"
        class="md-layout-item md-size-25 md-small-size-100"
      >
        <p
          class="course-element-selector active-step"
          style="cursor: pointer; margin-bottom: 0px"
          v-on:click="selectMedia(courseMedia, index)"
          v-for="(courseMedia, index) in courseObject['course_media']"
          :key="courseMedia.id"
        >
          <span v-if="trackerIndex > index"
            ><span
              class="material-icons"
              style="font-size: 1rem; color: green"
              icon="check"
              >done</span
            ></span
          >
          {{ index + 1 }}. {{ courseMedia.name }}
        </p>
      </div>
      <div
        v-if="componentKey > 0"
        class="md-layout-item md-size-75 md-small-size-100"
        style="margin-bottom: 1rem"
      >
        <MediaDisplay
          @updateFavorite="updateFavorite"
          v-on:progressionstatus="progressionstatus"
          v-if="!completed"
          :key="componentKey"
          v-bind:mediaObject="selectedMediaObject"
        />
        <CourseCompleted v-if="completed"></CourseCompleted>
        <div style="text-align: center">
          <md-button
            v-if="!completed"
            :disabled="mediaIndex == 0"
            v-on:click="lastMedia()"
            style="margin-right: 15px"
            class="md-button md-success"
            >Zurück</md-button
          >
          <md-button
            :disabled="progressionBlocked"
            v-if="
              mediaIndex < courseObject.course_media.length - 1 && !completed
            "
            v-on:click="nextMedia()"
            class="md-button md-success"
            >Weiter</md-button
          >
          <md-button
            :disabled="progressionBlocked"
            v-if="
              mediaIndex == courseObject.course_media.length - 1 && !completed
            "
            v-on:click="completeCourse()"
            class="md-button md-success"
            >Kurs abschliessen</md-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useCourseStore } from "@/stores/CourseStore";
import request from "@/utils/request";
import MediaDisplay from "./MediaDisplayComponent.vue";
import CourseCompleted from "./CourseCompletedComponent.vue";

export default {
  components: {
    MediaDisplay,
    CourseCompleted,
  },
  props: {
    directory: Number,
    course: String,
    object: {},
  },
  setup() {
    const courseStore = useCourseStore();
    return {
      courseStore,
    };
  },
  data() {
    return {
      courseObject: { course_media: [] },
      selectedMediaObject: {},
      componentKey: 0,
      mediaIndex: 0,
      trackerIndex: 0,
      completed: false,
      progressionBlocked: false,
    };
  },
  beforeMount() {
    let courseID = this.$route.params.courseID;
    this.courseStore
      .getCourse(courseID)
      .then((response) => {
        this.courseObject = this.courseStore.course;
        this.selectedMediaObject =
          this.courseObject["course_media"][this.mediaIndex];
        this.componentKey += 1;
        if (this.courseObject.tracker > 0) {
          this.hopMedia(this.courseObject.tracker);
        }
      })
      .then(() => {
        this.changeStep();
      });
  },
  mounted() {},
  methods: {
    selectMedia(mediaObject, index) {
      this.mediaIndex = index;
      this.componentKey += 1;
      this.selectedMediaObject = mediaObject;
      this.changeStep();
    },
    hopMedia(index) {
      this.progressionBlocked = true;
      this.mediaIndex = index;
      this.trackerIndex = index;
      this.selectedMediaObject =
        this.courseObject.course_media[this.mediaIndex];
      this.componentKey += index;
    },
    lastMedia() {
      this.mediaIndex = this.mediaIndex - 1;
      this.selectedMediaObject =
        this.courseObject.course_media[this.mediaIndex];
      this.componentKey += 1;
      this.trackerIndex - 1;
      this.changeStep();
    },
    nextMedia() {
      this.mediaIndex = this.mediaIndex + 1;
      this.selectedMediaObject =
        this.courseObject.course_media[this.mediaIndex];
      this.componentKey += 1;
      if (this.trackerIndex <= this.mediaIndex) {
        this.trackerIndex += 1;
      }
      if (this.mediaIndex > this.trackerIndex) {
        this.trackerIndex = this.mediaIndex;
      }
      request
        .post("/api/course/" + this.courseObject.id + "/track", {
          courseID: this.courseObject.id,
          position: this.trackerIndex,
          done: false,
          override: false,
        })
        .then((response) => {});
      this.changeStep();
    },
    changeStep() {
      this.progressionBlocked = true;
      var children = document.getElementById("stepsContainer").childNodes;
      var arrayIndex = 0;
      children.forEach((element) => {
        if (element.classList) {
          element.classList.remove("active-step");
          if (arrayIndex == this.mediaIndex) {
            element.classList.add("active-step");
          }
        } else {
        }
        arrayIndex += 1;
      });
      this.progressionBlocked = false;
    },
    completeCourse() {
      this.completed = true;
      request
        .post("/api/course/" + this.courseObject.id + "/track", {
          courseID: this.courseObject.id,
          position: this.mediaIndex,
          done: true,
          override: false,
        })
        .then((response) => {});
    },
    resetTracker() {
      this.mediaIndex = 0;
      this.componentKey += 1;
      this.trackerIndex = 0;
      this.completed = false;
      request
        .post("/api/course/" + this.courseObject.id + "/track", {
          courseID: this.courseObject.id,
          position: 0,
          done: false,
          override: true,
        })
        .then((response) => {});
      this.selectedMediaObject =
        this.courseObject.course_media[this.mediaIndex];
      this.changeStep();
    },
    progressionstatus(progressionblocked) {
      this.progressionBlocked = progressionblocked;
    },
    updateFavorite(favorite, mediaObject) {
      if (favorite) {
        this.courseObject["course_media"][this.mediaIndex].favorite = true;
      } else {
        this.courseObject["course_media"][this.mediaIndex].favorite = false;
      }
    },
  },
};
</script>

<style>
.course-element-selector {
  padding-top: 8px;
  padding-bottom: 8px;
}

.course-element-selector:hover {
  transition-duration: 0.3s;
  background-color: #cacfd2;
}

.active-step {
  font-weight: bold;
}

</style>

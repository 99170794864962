<template>
  <div class="md-layout md-alignment-top-center text-center">
    <div class="md-layout-item md-size-50 md-small-size-100">
      <md-card>
        <md-card-content v-if="subscription">
          <h5 class="category text-gray">Aktuelles Abo</h5>
          <p v-if="subscription.subscribed">
            {{ subscription.name }}
          </p>
          <p v-if="!subscription.subscribed">Sie verfügen noch über kein Abo</p>
          <p v-if="!subscription.canceled && subscription.subscribed">
            Nächste Rechnung über {{ subscription.price / 100 }}.- am
            {{ subscription.next_invoice_date }}
          </p>
          <p v-if="subscription.canceled">
            <b>Ihr Abo wird am {{ subscription.cancel_at }} gekündet.</b>
          </p>
          <div v-if="subscription.canceled">
            <md-button @click="reactivate()" class="md-success"
              >Abo reaktivieren</md-button
            >
          </div>
          <md-button
            v-if="!subscription.canceled && subscription.subscribed"
            @click="terminateSubscription()"
            class="md-danger"
            >Künden</md-button
          >
        </md-card-content>
        <md-card-content v-if="!subscription">
          <h5>Sie haben noch kein Abo</h5>
          <p>
            Profitieren Sie von vielen Vorteilen, in dem Sie ein Abo
            abschliessen.
          </p>
        </md-card-content>
      </md-card>
    </div>
    <div class="md-layout-item md-size-50 md-small-size-100">
      <md-card>
        <md-card-content>
          <h5 class="category text-gray">Zahlungsmethode</h5>
          <p v-if="paymentMethod">
            Ihre Zahlungsmethode wird automatisch belastet. Einen
            Zahlungsnachweis erhalten Sie per Email.
          </p>
          <div v-if="paymentMethod" class="md-layout text-center">
            <div class="md-layout-item md-size-33">
              <p>{{ paymentMethod.brand }}</p>
            </div>
            <div class="md-layout-item md-size-33">
              <p>*** {{ paymentMethod.last_4 }}</p>
            </div>
            <div class="md-layout-item md-size-33">
              <p>{{ paymentMethod.exp_month }}/{{ paymentMethod.exp_year }}</p>
            </div>
          </div>
          <div v-if="!paymentMethod">
            <b>Keine Zahlungsmethode hinterlegt</b>
          </div>
          <md-button
            v-if="paymentMethod"
            style="margin-right: 8px"
            @click="deletePaymentMethod()"
            class="md-danger"
            >Zahlungsmethode löschen</md-button
          >
          <md-button @click="addPaymentMethod()" class="md-success"
            >Zahlungsmethode anpassen</md-button
          >
        </md-card-content>
      </md-card>
    </div>

    <div
      class="md-layout-item md-size-33 md-small-size-100"
      v-for="subscription_item of subscriptions"
      :key="subscription_item.id"
    >
      <md-card
        class=""
        :class="{ active: subscription_item.active }"
        style="padding-left: 0px; padding-right: 0px"
      >
        <div class="card-header">
          <h3>
            {{ subscription_item.name }}
            <badge
              type="info"
              style="vertical-align: middle"
              v-if="
                subscription_item.id == subscription.tier.id &&
                !subscription.canceled
              "
              >Aktuelles Abo</badge
            >
            <badge
              type="warning"
              style="vertical-align: middle"
              v-if="
                subscription_item.id == subscription.tier.id &&
                subscription.canceled
              "
              >Bis am {{ subscription.cancel_at }}</badge
            >
          </h3>
        </div>

        <md-card-content>
          <div
            v-if="subscriptions"
            class="md-layout text-center"
            style="justify-content: center"
          >
            <div>
              <div v-html="subscription_item.description"></div>
              <md-button
                :disabled="subscription_item.id == subscription.tier.id"
                @click="subscribe(price_item)"
                v-for="price_item in subscription_item.price"
                :key="price_item.id"
                class="md-success"
              >
                <div>
                  {{ price_item.unit_amount / 100 }} /
                  {{ subscriptionStore.getPriceInterval(price_item.interval) }}
                </div>
              </md-button>
            </div>
          </div>
        </md-card-content>
      </md-card>
    </div>
    <!-- </div> -->
  </div>
</template>
<script>
import { userStore } from "@/stores/UserStore";
import { useSubscriptionStore } from "@/stores/SubscriptionStore";
import Swal from "sweetalert2";
import { Badge } from "@/components";

export default {
  components: {
    Badge,
  },
  setup() {
    const store = userStore();
    const subscriptionStore = useSubscriptionStore();
    return {
      store,
      subscriptionStore,
    };
  },
  data() {
    return {
      paymentMethod: null,
      subscriptions: null,
      subscription: null,
    };
  },
  beforeMount() {
    this.store.loadUser();
    this.subscriptionStore.loadSubscriptionStatus().then((response) => {
      this.subscription = this.subscriptionStore.subscription;
    });
    this.subscriptionStore.loadSubscription().then((response) => {
      this.subscriptions = this.subscriptionStore.subscriptions;
    });
    this.subscriptionStore.loadPaymentMethod().then((response) => {
      this.paymentMethod = this.subscriptionStore.getPaymentMethod;
    });
  },
  mounted() {},
  methods: {
    subscribe(price) {
      if (this.subscription.subscribed) {
        Swal.fire({
          title: "Neues Abo abschliessen",
          text: "Wenn Sie ein neues Abo abschliessen wird ihr bestehendes per sofort gekündigt. Sie verlieren dann den Zugang zu den Inhalten, zu denen Sie vorher zugang hatten, falls Ihr neues Abo diese Inhalte nicht enthält.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ja, künden und neues Abo abschliessen",
          cancelButtonText: "Nein, abbrechen",
        }).then(
          (result) => {
            if (result.value) {
              this.subscriptionStore.subscribe(price).then((response) => {
                window.location.href = response.data.url;
              });
            }
          },
          (errpr) => {}
        );
      } else {
        this.subscriptionStore.subscribe(price).then((response) => {
          window.location.href = response.data.url;
        });
      }
    },
    reactivate() {
      this.subscriptionStore.reactivate().then((response) => {
        this.subscriptionStore.loadSubscriptionStatus().then((response) => {
          this.subscription = this.subscriptionStore.subscription;
        });
      });
    },
    terminateSubscription() {
      Swal.fire({
        title: "Sind Sie sicher?",
        text: "Möchten Sie Ihr Abo wirklich künden?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ja, künden",
        cancelButtonText: "Nein, abbrechen",
      }).then(
        (result) => {
          if (result.value) {
            this.subscriptionStore
              .terminateSubscription("later")
              .then((response) => {
                this.subscriptionStore
                  .loadSubscriptionStatus()
                  .then((response) => {
                    this.subscription = this.subscriptionStore.subscription;
                  });
              });
          }
        },
        (errpr) => {}
      );
    },
    addPaymentMethod() {
      this.subscriptionStore.addPaymentMethod().then((repsonse) => {
        window.location.href = repsonse.data.url;
      });
    },
    deletePaymentMethod() {
      Swal.fire({
        title: "Möchten Sie die Zahlungsmethode wirklich löschen?",
        text: "Zukünftige Zahlungen werden fehlschlagen.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ja, löschen",
        cancelButtonText: "Nein, abbrechen",
      }).then(
        (result) => {
          if (result.value) {
            this.subscriptionStore
              .deletePaymentMethod(this.paymentMethod.id)
              .then((response) => {
                this.subscriptionStore.loadPaymentMethod().then((response) => {
                  this.paymentMethod = this.subscriptionStore.getPaymentMethod;
                });
              });
          }
        },
        (errpr) => {}
      );
    },
  },
};
</script>
<style scoped>
.card-header {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  background-color: #4caf50;
  color: white;
}

.card-header h3 {
  margin: 10px 0px;
}

.active {
  -webkit-box-shadow: 0px 0px 12px 5px rgba(178, 151, 0, 0.5);
  box-shadow: 0px 0px 12px 5px rgba(178, 151, 0, 0.75);
}
</style>

import { defineStore } from 'pinia'
import request from '@/utils/request'

// useStore could be anything like useUser, useCart
// the first argument is a unique id of the store across your application
export const useRoleStore = defineStore('roleStore', {
    state: () => ({
        roles: [
            {
                id: 0,
                name: 'Benutzer'
            },
            {
                id: 1,
                name: 'Coach'
            },
            {
                id: 2,
                name: 'Administrator'
            }
        ],
    }),
})
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper",class:[
    { 'nav-open': _vm.$sidebar.showSidebar },
    { rtl: _vm.$route.meta.rtlActive } ]},[_c('notifications'),_c('side-bar',{attrs:{"active-color":_vm.sidebarBackground,"background-image":_vm.sidebarBackgroundImage,"data-background-color":_vm.sidebarBackgroundColor}},[_c('user-menu'),(_vm.store.type >= 1)?_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{
          name: 'Zurück zur App',
          icon: 'dashboard',
          path: '/dashboard',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Übungen',
          icon: 'task',
          path: '/coach/exercise',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Kurse',
          icon: 'golf_course',
          path: '/coach/course',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Showcase',
          icon: 'golf_course',
          path: '/coach/showcase',
        }}})],1):_vm._e()],2),_c('div',{staticClass:"main-panel"},[_c('top-navbar'),_c('div',{class:{ content: !_vm.$route.meta.hideContent },on:{"click":_vm.toggleSidebar}},[_c('zoom-center-transition',{attrs:{"duration":200,"mode":"out-in"}},[_c('router-view')],1)],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <form>
        <md-card>
            <md-card-header
                class="md-card-header-icon"
                :class="getClass(headerColor)"
            >
                <div class="card-icon">
                    <md-icon>perm_identity</md-icon>
                </div>
                <h4 class="title">
                    Profil bearbeiten -
                    <small>Vervollständigen Sie Ihr Profil</small>
                </h4>
            </md-card-header>

            <md-card-content>
                <div class="md-layout">
                    <div class="md-layout-item md-small-size-50 md-size-50">
                        <md-field>
                            <label>Profilbild</label>
                            <md-file @change="handleFileInput" />
                        </md-field>
                    </div>
                    <div
                        v-if="newProfilePictureSelected"
                        class="md-layout-item md-small-size-50 md-size-50"
                    >
                        <div class="md-layout">
                            <img
                                class="md-layout-item md-size-100"
                                :src="url"
                                style="width: 50%; height: 50%"
                            />
                            <md-button
                                @click="updateProfilePicture()"
                                class="md-success md-size-100"
                                >Profilbild aktualisieren</md-button
                            >
                        </div>
                    </div>
                    <div class="md-layout-item md-small-size-100 md-size-100">
                        <md-field>
                            <label>Email Address</label>
                            <md-input
                                disabled
                                v-model="email"
                                type="email"
                            ></md-input>
                        </md-field>
                    </div>
                    <div class="md-layout-item md-small-size-100 md-size-50">
                        <md-field>
                            <label>First Name</label>
                            <md-input
                                disabled
                                v-model="name"
                                type="text"
                            ></md-input>
                        </md-field>
                    </div>
                    <div class="md-layout-item md-small-size-100 md-size-50">
                        <md-field>
                            <label>Last Name</label>
                            <md-input
                                disabled
                                v-model="name"
                                type="text"
                            ></md-input>
                        </md-field>
                    </div>
                    <div class="md-layout-item md-size-100">
                        <md-field maxlength="5">
                            <label>Slogan</label>
                            <md-input v-model="slogan" type="text"></md-input>
                        </md-field>
                    </div>
                    <div class="md-layout-item md-size-100">
                        <md-field maxlength="5">
                            <label>Über mich</label>
                            <md-textarea
                                v-model="about"
                                type="text"
                            ></md-textarea>
                        </md-field>
                    </div>
                    <div class="md-layout-item md-size-100 text-right">
                        <md-button
                            class="md-raised md-success mt-4"
                            @click="navigateToSubscription()"
                            >Abo verwalten</md-button
                        >
                        <md-button
                            class="md-raised md-success mt-4"
                            @click="store.updateUser()"
                            >Aktualiseren</md-button
                        >
                    </div>
                </div>
            </md-card-content>
        </md-card>
    </form>
</template>
<script>
import { storeToRefs } from "pinia";
import { userStore } from "@/stores/UserStore";

export default {
    name: "edit-profile-form",
    props: {
        headerColor: {
            type: String,
            default: ""
        }
    },
    setup() {
        const store = userStore();
        const { name, email, slogan, about, image } = storeToRefs(store);
        return {
            store,
            name,
            email,
            slogan,
            about,
            image
        };
    },
    data() {
        return {
            disabled: true,
            newProfilePictureSelected: false,
            newProfilePicutre: null,
            newImage: null
        };
    },
    methods: {
        getClass: function (headerColor) {
            return "md-card-header-" + headerColor + "";
        },
        navigateToSubscription() {
            this.$router.push("/pages/user/subscription");
        },
        handleFileInput(event) {
            this.newProfilePictureSelected = true;
            this.newImage = event.target.files[0];
            this.url = URL.createObjectURL(this.newImage);
            const file = event.target.files[0];
            this.newProfilePicutre = file;
        },
        updateProfilePicture() {
            this.store.updateImage(this.newProfilePicutre);
            this.newProfilePictureSelected = false;
            this.newProfilePicutre = null;
        }
    }
};
</script>
<style></style>

<template>
  <div>
    <md-dialog :md-active.sync="showDialog">
      <md-dialog-title>{{ user.name }}</md-dialog-title>

      <div
        class="md-layout-item md-size-100 md-medium-size-100 md-small-size-100 md-xsmall-size-100"
      >
        <md-field class="md-form-group">
          <!-- <md-icon>mail</md-icon> -->
          <label>E-Mail</label>
          <md-input typeID="content" v-model="email" disabled></md-input>
        </md-field>
        <md-field class="md-form-group">
          <!-- <md-icon>thumb_up</md-icon> -->
          <label>Name</label>
          <md-input typeID="content" v-model="name"></md-input>
        </md-field>
        <md-field class="md-form-group">
          <!-- <md-icon>scroll</md-icon> -->
          <label>Rolle</label>
          <md-select v-model="type">
            <md-option v-for="item in roles" :key="item.id" :value="item.id">{{
              item.name
            }}</md-option>
          </md-select>
        </md-field>
        <md-field class="md-form-group">
          <!-- <md-icon>scroll</md-icon> -->
          <label>Status</label>
          <md-select v-model="status">
            <md-option
              v-for="item in statuses"
              :key="item.id"
              :value="item.id"
              >{{ item.name }}</md-option
            >
          </md-select>
        </md-field>
        <md-field class="md-form-group">
          <!-- <md-icon>scroll</md-icon> -->
          <label>Stufe</label>
          <md-select v-model="tier_level">
            <md-option v-for="item in tiers" :key="item.id" :value="item.id">{{
              item.name
            }}</md-option>
          </md-select>
        </md-field>
        <md-field class="md-form-group">
          <!-- <md-icon>dollar</md-icon> -->
          <label>Affiliate Gruppe</label>
          <md-select v-model="affiliate_group">
            <md-option
              v-for="item in affiliate_groups"
              :key="item.id"
              :value="item.id"
              >{{ item.name }}</md-option
            >
          </md-select>
        </md-field>
      </div>

      <md-dialog-actions>
        <md-button class="md-success" @click="$emit('closeEditDialog', false)"
          >Schliessen</md-button
        >
        <md-button class="md-success" @click="updateUser()"
          >Speichern</md-button
        >
      </md-dialog-actions>
    </md-dialog>
  </div>
</template>
<script>
import { useMemberStore } from "@/stores/MemberStore";
import { useRoleStore } from "@/stores/RoleStore";
import { useStatusStore } from "@/stores/StatusStore";
import { useTierStore } from "@/stores/TierStore";
import { useAffiliateGroupStore } from "@/stores/AffiliateGroupStore";

export default {
  components: {},
  props: ["user"],
  data() {
    return {
      showDialog: true,
      email: this.user.email,
      name: this.user.name,
      type: this.user.type,
      status: this.user.status,
      tier_level: this.user.tier_level,
      affiliate_group: this.user.affiliateGroupID,
      roles: this.roleStore.roles,
      statuses: this.statusStore.statuses,
      tiers: this.tierStore.tiers,
      affiliate_groups: this.affiliateGroupStore.groups,
    };
  },
  setup() {
    const memberStore = useMemberStore();
    const roleStore = useRoleStore();
    const statusStore = useStatusStore();
    const tierStore = useTierStore();
    const affiliateGroupStore = useAffiliateGroupStore();
    return {
      memberStore,
      roleStore,
      statusStore,
      tierStore,
      affiliateGroupStore,
    };
  },
  mounted() {},
  methods: {
    updateUser() {
      this.memberStore.updateMember(this.user.id, {
        name: this.name,
        type: this.type,
        status: this.status,
        tiers_id: this.tier_level,
        affiliateGroupID: this.affiliate_group,
      });
      this.$emit("closeEditDialog", true);
    },
  },
};
</script>
<style scoped>
.md-field.md-form-group:after {
  width: 100% !important;
}
</style>

<template>
  <div>
    <md-dialog @md-clicked-outside="closeDialog" :md-active.sync="showDialog">
      <md-dialog-title>Schwierigkeitsgrad hinzufügen</md-dialog-title>

      <div
        class="md-layout-item md-size-100 md-medium-size-100 md-small-size-100 md-xsmall-size-100"
      >
        <md-field class="md-form-group">
          <label>Kategorie suchen</label>
          <md-input
            typeID="content"
            @input="searchCategory"
            v-model="search"
          ></md-input>
        </md-field>
        <div
          style="overflow: scroll; max-height: 100px"
          v-if="searchResult.length > 0"
          class="md-size-100"
        >
          <div v-for="category in searchResult" :key="category.id">
            <div class="md-layout">
              <div class="md-size-50">
                <p>{{ category.path }}</p>
              </div>
              <div style="text-align: right" class="md-size-50">
                <md-button
                  @click="selectPath(category)"
                  class="md-success md-size-50"
                  >Auswählen</md-button
                >
              </div>
            </div>
          </div>
        </div>
        <md-field class="md-form-group">
          <label>Name</label>
          <md-input typeID="content" v-model="category.name"></md-input>
        </md-field>
        <md-field class="md-form-group">
          <label>Pfad</label>
          <md-input
            typeID="content"
            v-model="category.path"
            disabled
          ></md-input>
        </md-field>
        <md-field class="md-form-group">
          <label>Bild</label>
          <md-file @change="handleImageUpload" />
        </md-field>
        <md-field class="md-form-group">
          <label>Banner</label>
          <md-file @change="handleBannerUpload" />
        </md-field>
      </div>

      <md-dialog-actions>
        <md-button class="md-success" @click="closeDialog">Close</md-button>
        <md-button class="md-success" @click="createCategory"
          >Speichern</md-button
        >
      </md-dialog-actions>
    </md-dialog>
  </div>
</template>

<script>
import { useCategoryStore } from "@/stores/CategoryStore";

export default {
  components: {},
  props: ["item", "showDialog"],
  setup() {
    const categoryStore = useCategoryStore();
    return {
      categoryStore,
    };
  },
  data() {
    return {
      category: {
        name: "",
        path: "",
        image: "",
        banner: "",
      },
      search: "",
      searchResult: [],
    };
  },

  methods: {
    selectPath(category) {
      this.category.path = category.path;
    },
    searchCategory() {
      this.searchResult = this.categoryStore.searchCategories(this.search);
    },
    handleImageUpload(event) {
      const file = event.target.files[0];
      this.category.image = file;
    },
    handleBannerUpload(event) {
      const file = event.target.files[0];
      this.category.banner = file;
    },
    closeDialog() {
      this.$emit("closeDialog", false);
    },
    createCategory() {
      // Create new formdata
      const formData = new FormData();
      // Add image to formdata
      formData.append("image", this.category.image);
      formData.append("banner", this.category.banner);
      // Add name to formdata
      formData.append("name", this.category.name);
      // Add path to formdata
      if (this.category.path.length > 0) {
        formData.append("path", this.category.path + "/" + this.category.name);
      } else {
        formData.append("path", this.category.name);
      }
      this.categoryStore.createCategory(formData).then(response => {
        this.$emit("closeDialog", true);
      });
    },
  },
  watch: {
    showDialog: function (val) {
      if (val) {
        this.category = {
          name: "",
          path: "",
          image: "",
          banner: "",
        };
      }
    },
  },
};
</script>
<style scoped>
.md-field.md-form-group:before,
.md-field.md-form-group:after {
  width: 100% !important;
}
</style>

<template>
  <div>
    <md-dialog @md-clicked-outside="closeDialog" :md-active.sync="showDialog">
      <md-dialog-title>{{ item.name }} bearbeiten</md-dialog-title>

      <div
        class="md-layout-item md-size-100 md-medium-size-100 md-small-size-100 md-xsmall-size-100"
      >
        <md-field class="md-form-group">
          <label>Name</label>
          <md-input typeID="content" v-model="name"></md-input>
        </md-field>
        <md-field class="md-form-group">
          <label>Pfad</label>
          <md-input typeID="content" v-model="path" disabled></md-input>
        </md-field>
        <md-field class="md-form-group">
          <label>Nachricht</label>
          <md-input typeID="message" v-model="message"></md-input>
        </md-field>
        <md-field class="md-form-group">
          <label>Bild</label>
          <md-file @change="handleImageUpload" />
        </md-field>
        <md-field class="md-form-group">
          <label>Banner</label>
          <md-file @change="handleBannerUpload" />
        </md-field>
      </div>

      <md-dialog-actions>
        <md-button class="md-success" @click="closeDialog">Close</md-button>
        <md-button class="md-success" @click="updateCategory"
          >Speichern</md-button
        >
      </md-dialog-actions>
    </md-dialog>
  </div>
</template>

<script>
import { useCategoryStore } from "@/stores/CategoryStore";

export default {
  components: {},
  props: ["item", "showDialog"],
  setup() {
    const categoryStore = useCategoryStore();
    return {
      categoryStore,
    };
  },
  data() {
    return {
      name: this.item.name,
      path: this.item.path,
      message: this.item.message,
      image: null,
      banner: null,
    };
  },
  beforeMount() {
    if(this.path != null) {
      if (this.path.includes("/")) {
        this.name = this.path.split("/")[this.path.split("/").length - 1];
        // get everything before the last slash
        this.path = this.path.substring(0, this.path.lastIndexOf("/"));
        // Last part of string is name of category
      } else {
        this.path = "";
      }
    }
  },
  methods: {
    handleImageUpload(event) {
      const file = event.target.files[0];
      this.image = file;
    },
    handleBannerUpload(event) {
      const file = event.target.files[0];
      this.banner = file;
    },
    closeDialog() {
      this.$emit("closeDialog", false);
    },
    updateCategory() {
      // Create new formdata
      const formData = new FormData();
      // Add image to formdata
      formData.append("id", this.item.id);
      formData.append("image", this.image);
      formData.append("banner", this.banner);
      // Add name to formdata
      formData.append("name", this.name);
      // Add path to formdata
      formData.append("path", this.path);
      // Add non empty message
      if(this.message != null) {
        if(this.message.length > 0) {
          formData.append("message", this.message);
        }
      }

      this.categoryStore.updateCategory(formData);
      this.$emit("closeDialog", true);
    },
  },
};
</script>
<style scoped>
.md-field.md-form-group:before,
.md-field.md-form-group:after {
  width: 100% !important;
}
</style>

<template>
  <div style="margin-top: 30px" class="md-layout">
    <div
      v-for="file in files"
      style="cursor: pointer"
      @click="downloadFile(file.id)"
      class="file-container md-layout-item md-size-30 md-small-size-100 md-medium-size-100"
      :key="file.id"
    >
      <font-awesome-icon
        style="font-size: 1rem; margin-right: 8px"
        icon="file"
      ></font-awesome-icon>
      {{ file.name.replace(/(\.\w+)+$/, "") }}
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";

export default {
  props: {
    files: Array,
  },
  methods: {
    downloadFile(fileID) {
      request
        .get("/api/media/file/download/" + fileID, {
          responseType: "blob",
        })
        .then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");
          var headers = response.headers;
          var fileName = headers["content-disposition"].substr(
            headers["content-disposition"].indexOf("=") + 1
          );

          fileLink.href = fileURL;
          fileLink.setAttribute("download", fileName);
          document.body.appendChild(fileLink);

          fileLink.click();
        });
    },
  },
};
</script>

<style scoped>
.file-container {
  margin: 1rem;
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
}
</style>
